declare var gtag: any;
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../environments/environment';

interface IEventLog {
  Action: string;
  Label?: string;
  UserId?: number | string;
}
function url() {
  return `${environment.baseUrl}/log/galog`;
}
const sendEvent = async (body: IEventLog) => {
  const apiUrl = url();
  var data = new FormData();
  data.append('Action', body.Action);
  if (body.Label) {
    data.append('Label', body.Label);
  }
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'no-cors',
      body: data,
    });
    //console.log('Event request send: ', body.Action);
  } catch (error) {
    console.log('error', error);
  }
};
export const sendAnalyticsEvent = (eventName: string, value: string, isError: boolean = false) => {
  gtag('event', eventName, { label: value });
  if(!isError) {
    sendEvent({
      Action: eventName,
      Label: value,
    });
  }
};
export const sendAnalyticsEventWithoutParameter = (eventName: string) => {
  gtag('event', eventName, {});
  sendEvent({
    Action: eventName,
  });
};
export const setDimensionAndInitAnalytics = (
  router: Router,
  key: any,
  getter: any,
) => {
  const dimensions = new Map<string, Function>();
  dimensions.set(key, getter);
  const custom_map = mapDimensions(dimensions, (value, keyMap) => keyMap);
  gtag('js', new Date());
  gtag('config', environment.gaTrackingId, { custom_map });
  trackRouteChanges(router);
};

export const InitAnalytics = (router: Router) => {
  gtag('js', new Date());
  gtag('config', environment.gaTrackingId);

  trackRouteChanges(router);
};

function mapDimensions(dimensions: Map<string, Function>, callback: Function) {
  const result = {};
  let i = 1;

  dimensions.forEach((value, key) => {
    result[`dimension${i}`] = callback(value, key);
    i++;
  });

  return result;
}

function trackRouteChanges(router: Router) {
  router.events.subscribe(event => {
    try {
      if (event instanceof NavigationEnd) {
        emitPageview(event);
      }
    } catch (error) {
      console.log(`Error registering page in Google Analytics:\n\t ${error}`);
    }
  });
}

function emitPageview(event: NavigationEnd) {
  const title =
    event.urlAfterRedirects === '/'
      ? 'home'
      : event.urlAfterRedirects.slice(1, event.urlAfterRedirects.length);

  gtag('event', 'page_view', {
    page_title: title,
    page_path: event.urlAfterRedirects,
  });
}

import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger, MatSelectChange } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { scrollWindow } from 'src/app/helpers/functions';
import { EnvFactor } from 'src/app/models/api/EnvFactor';
import { ProjectType, ProjectTypeCode } from 'src/app/models/api/ProjectType';
import { Sector } from 'src/app/models/api/Sector';
import { ProjectDetails } from 'src/app/models/ProjectDetails';
import { IRouterData } from 'src/app/models/RouterData';
import { ApiService } from 'src/app/services/api.service';
import { StoreService } from 'src/app/services/store-service.service';

import { Area } from '../../models/Area';
import { Project } from '../../models/Project';
import { AuthService } from 'src/app/services/auth.service';
import { Validators, FormControl } from '@angular/forms';
import { sendAnalyticsEvent, sendAnalyticsEventWithoutParameter } from 'src/app/utils/analytics';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss'],
})
export class ProjectCreateComponent implements OnInit, AfterContentChecked {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  routerData: IRouterData;
  project: Project;
  environmentalFactors: Array<string[]> = [];
  projectTypes: ProjectType[];
  sectors: Sector[];
  envFactors: EnvFactor[];
  areasValid = new Map<Area, boolean>();

  projectNameControl = new FormControl('', Validators.required);

  initialProjectName: string;
  initialProjectType: string;

  constructor(
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly store: StoreService,
    private readonly api: ApiService,
    private readonly auth: AuthService,
  ) {
    this.project = new Project();

    if (!this.project.details) {
      this.project.details = new ProjectDetails({
        info: {
          address1: '',
          city: '',
          country: 'Netherlands',
          county: '',
          postalCode: '',
        },
        companyInfo: {
          address1: '',
          city: '',
          country: 'Netherlands',
          county: '',
          postalCode: '',
          image: null,
          name: '',
          specifierName: '',
        },
        salesPerson: {
          email: auth.email,
          name: auth.name,
          phone: auth.userPhone,
        },
      });
    }
  }

  ngOnInit() {
    if (sessionStorage.getItem('userProjectGuid')) {
      this.project = this.store.project.clone();
    } else {
      const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
          /[xy]/g,
          function (c) {
            var r = (Math.random() * 16) | 0,
              v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          },
        );
      };

      this.store.projectGuid = uuidv4();
      this.api.syncProject(null, this.store.projectGuid);
    }

    this.initialProjectName = this.project.name;
    this.initialProjectType =
      (this.project.type && this.project.type.code) || null;

    this.api.getProjectTypes().then(types => {
      this.projectTypes = types;
    });

    this.api.getLabourRate().then(value => (this.project.labourCost = value));

    if (this.project.type && this.project.type.code) {
      this.api
        .getProjectTypeSectors(this.project.type.code)
        .then(sectors => (this.sectors = sectors));
    }

    if (this.project.areas) {
      this.api
        .getProjectTypeEnvFactors(this.project.type.code)
        .then(envFactors => (this.envFactors = envFactors));
    }
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  get areasStatus() {
    if (!this.project.sector || !this.project.areas.length) {
      return false;
    }

    let valid = true;
    this.areasValid.forEach(x => {
      if (!x) {
        valid = x;
      }
    });

    return valid;
  }

  @HostListener('window:scroll', [])
  scrollHandler() {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
  }

  cancelCreation() {
    sendAnalyticsEvent('Project Creation Canceled', 'Step 2');
    if (this.store.projectGuid) this.api.deleteProject(this.store.projectGuid);
    this.router.navigate(['my-projects']);
  }

  nextStep() {
    const projectType = this.project.type.code;

    this.project.areas.forEach((area, index) => {
      if (!area.name) {
        area.name = Area.getDefaultName(this.translate, projectType, index);
      }
    });

    if (
      this.project.type.code !== this.initialProjectType ||
      this.store.project.name !== this.initialProjectName
    ) {
      this.store.addProjectCount();
      sendAnalyticsEventWithoutParameter(
        'Project Created'
      );
    }

    this.store.project = this.project;
    this.router.navigate(['/project/surfaces']);
  }

  assignProjectType() {
    this.project.sector = null;
    this.project.areas = [];
    this.areasValid.clear();
    sendAnalyticsEvent(
      'Project Type Assigned',
      `Type: ${this.project.type.code}; Project Name: ${
        this.project.name || 'Project not named yet'
      }`,
    );
    this.api.getProjectTypeSectors(this.project.type.code).then(sectors => {
      this.sectors = sectors;
      scrollWindow(150);
    });
  }

  assignSector(event: MatSelectChange) {
    this.project.areas = [];
    this.areasValid.clear();
    sendAnalyticsEvent(
      'Sector Type Assigned',
      `Name: ${this.project.sector.name}; Project Type: ${this.project.type.code}`,
    );
    this.project.areas.push(new Area());
    scrollWindow();
  }

  addNewArea() {
    this.project.areas.push(new Area());
    sendAnalyticsEvent(
      'SubSector added',
      `Project type: ${this.project.type.code}; Sector: ${this.project.sector.name}`,
    );

    scrollWindow();
  }

  removeArea(area: Area) {
    this.project.areas.splice(this.project.areas.indexOf(area), 1);
    this.areasValid.delete(area);
    sendAnalyticsEvent(
      'SubSector Removed',
      `SubSector type: ${area.subsector.name}; Project type: ${this.project.type.code}; Sector: ${this.project.sector.name}`,
    );

    if (!this.project.areas.length) {
      this.project.areas.push(new Area());
    }
  }

  goToProjectDetails() {
    this.store.project = this.project;
    this.router.navigate(['project/details'], {
      state: { shouldGoBack: true },
    });
  }

  checkValidation(value: boolean, area: Area) {
    if (area) {
      this.areasValid.set(area, value);
    }
  }

  compareProjectTypes(o1: ProjectType, o2: ProjectType) {
    if (o1 && o2) {
      return o1.code === o2.code ? true : false;
    }
  }

  compareSectorName(o1: Sector, o2: Sector) {
    if (o1 && o2) {
      return o1.guid === o2.guid;
    }
  }
}

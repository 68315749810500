import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

import {
  CdkVirtualScrollViewport,
  FixedSizeVirtualScrollStrategy,
  VIRTUAL_SCROLL_STRATEGY,
} from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatDialog,
  MatMenuTrigger,
  MatSelect,
  MatSelectChange,
  MatSliderChange,
  MatSlideToggleChange,
  MatTabChangeEvent,
  MatTabGroup,
} from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {
  ISurfaceOptionCalculation,
  TEN_PLUS_YEARS,
} from '../../helpers/CalculationsProcessor';
import { getDashing } from '../../helpers/chart.config';
import {
  getRedecorationMapping,
  TranslationMapping,
} from '../../helpers/translations';
import { Colour } from '../../models/api/Colour';
import { PaintLayer } from '../../models/api/PaintLayer';
import { PaintSystem } from '../../models/api/PaintSystem';
import { Product } from '../../models/api/Product';
import { ProjectTypeCode } from '../../models/api/ProjectType';
import { Area } from '../../models/Area';
import { AreaSurface } from '../../models/AreaSurface';
import { IRemoveSurface } from '../../models/flow/flowInterfaces';
import { Project } from '../../models/Project';
import { SurfaceCustomerDemand } from '../../models/SurfaceCustomerDemand';
import { ApiService } from '../../services/api.service';
import { StoreService } from '../../services/store-service.service';
import { SurfaceCommentComponent } from '../surface-comment/surface-comment.component';
import {
  SurfaceResetComponent,
  SurfaceResetData,
} from '../surface-reset/surface-reset.component';
import { sendAnalyticsEvent } from 'src/app/utils/analytics';

export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(50, 250, 500);
  }
}

@Component({
  selector: 'app-surface-product',
  templateUrl: './surface-product.component.html',
  styleUrls: ['./surface-product.component.scss'],
  providers: [
    { provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy },
  ],
})
export class SurfaceProductComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() project: Project;
  @Input() area: Area;
  @Input() areaIndex: number;
  @Input() surface: AreaSurface;
  @Input() surfaceIndex: number;
  @Input() reCalculate: BehaviorSubject<boolean>;
  @Input() refreshProductScroll: EventEmitter<void>;
  @Output() removeSurfaceTrigger = new EventEmitter<IRemoveSurface>();
  @Output() hasDemandApplied = new EventEmitter(this.isDemandApplied);
  @Output() calculationChanged = new BehaviorSubject<boolean>(true);

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('demandsTabs') demandsTabs: MatTabGroup;
  @ViewChild('colourSelector') colourSelect: MatSelect;
  @ViewChild('matAutocomplete') colourAutocomplete: MatAutocomplete;

  colours: Colour[] = [];
  products: Product[];
  paintSystems: Array<PaintSystem[]> = [];
  selectedDemand: SurfaceCustomerDemand;
  systemDisplayedColumns = ['name', 'numberOfCoats', 'product'];
  @ViewChild(CdkVirtualScrollViewport) cdkViewPort: CdkVirtualScrollViewport;

  filteredColours: ReplaySubject<Colour[]> = new ReplaySubject<Colour[]>(1);
  currentColourPage = 0;
  typingTimer: any;

  searchColoursControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  colourPlaceholder: string;

  get isBannerVisible() {
    return !this.isDemandApplied;
  }

  get projectType(): ProjectTypeCode {
    return this.project.type.code;
  }

  get calculations() {
    return this.project.getSurfaceCalculations(this.surface);
  }

  get selectedDemandIndex() {
    if (this.selectedDemand) {
      return this.surface.customerDemands.findIndex(
        x => x.model === this.selectedDemand.model,
      );
    }
    return null;
  }

  get isDemandApplied() {
    if (!this.surface || !this.surface.customerDemands) {
      return false;
    }

    return Boolean(this.surface.customerDemands.find(x => x.enabled));
  }

  get isManteinanceVisible() {
    return this.hasRedecoration();
  }

  get currentlang() {
    return localStorage.getItem('lang');
  }

  get redecorationMapping(): TranslationMapping {
    return getRedecorationMapping(this.translate);
  }

  get recommendedValues() {
    const demand = this.selectedDemand;
    const recommended = this.surface.products.map(x => x.recommendedValues);
    const isReady = demand && recommended.every(Boolean);
    return isReady ? recommended.map(x => x[demand.model.guid]) : [];
  }

  constructor(
    private readonly api: ApiService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    public readonly store: StoreService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit() {
    this.cdRef.detectChanges();

    Promise.all([
      !this.surface.customerDemands && this.getSurfaceDemands(),
      this.getSurfaceProducts(),
      this.getColours(),
    ]).then(() => {
      if (this.surface.customerDemands && this.surface.customerDemands[0]) {
        this.selectedDemand = this.surface.customerDemands[0];
      }
      if (this.selectedDemand && !this.selectedDemand.enabled) {
        this.setRecommendedValue();
      }

      this.reCalculate.subscribe(() => this.calculate());
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getSelectedDemandValues(productIndex = 0) {
    const calc = this.calculations;
    const index = this.demandsTabs.selectedIndex;

    return (
      calc &&
      calc.options &&
      calc.options[productIndex] &&
      calc.options[productIndex][index]
    );
  }

  surfaceNumber(areaIndex: number, surfaceIndex: number) {
    return `${areaIndex + 1}.${surfaceIndex + 1}`;
  }

  private getSurfaceDemands(): Promise<any> {
    return this.api
      .getSurfaceCustomerDemands(this.surface.model.guid)
      .then(demands => {
        this.surface.customerDemands = [];
        demands.forEach(customerDemand => {
          const newDemand = new SurfaceCustomerDemand();
          newDemand.model = customerDemand;
          this.surface.customerDemands.push(newDemand);
        });

        if (this.surface.customerDemands.length) {
          this.selectedDemand = this.surface.customerDemands[0];
        }
      });
  }
  private getSurfaceProducts(): Promise<any> {
    return this.api
      .getSurfaceProducts(this.surface.model.guid)
      .then(products => {
        this.products = products;

        if (!this.surface.products.length) {
          this.onIsComparingChange(this.project.isComparing);
          sendAnalyticsEvent(
            'Surface Default Product',
            `Product 1: ${this.surface.products[0].name};
           Product 2: ${
             this.surface.products[1]
               ? this.surface.products[1].name
               : 'not set'
           };
           Surface: ${this.surface.model.name}
           `,
          );
        } else {
          this.onIsComparingChange(this.project.isComparing);
        }

        this.project.onIsComparingChange(value =>
          this.onIsComparingChange(value),
        );
      });
  }

  private getColours(page: number = 0, setFilterControl: boolean = true) {
    this.api
      .getFilteredColours(this.searchColoursControl.value, page, 50)
      .then(colours => {
        this.colours = this.colours.concat(colours);
        this.filteredColours.next(this.colours);
        const white = colours.find(x => /^f+$/i.test(x.hex));

        if (!this.surface.colour) {
          if (white == null) {
            console.warn('Color white not found');
          }

          this.surface.colour = white || colours[0];
          this.calculate();
        }

        if (!setFilterControl) {
          return;
        }

        this.searchColoursControl.setValue(this.surface.colour.code);
      });
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  printUnitsFor(product: Product) {
    const demand = this.selectedDemand;

    return (
      this.getUnitsFor(product) ||
      demand.units ||
      demand.model.sliderDefaultValue
    );
  }

  getUnitsFor(product: Product) {
    const demand = this.selectedDemand;

    if (demand.enabled) {
      return demand.units;
    }

    const map = product && product.recommendedValues;
    const value = map && map[demand.model.guid];
    return value || null;
  }

  getYears(lifecycle: number) {
    if (lifecycle == null) {
      return '/';
    }

    if (lifecycle === TEN_PLUS_YEARS) {
      return '10+';
    }

    return lifecycle;
  }

  isUsingRecommendedValue(index = 0) {
    const demand = this.selectedDemand;
    const product = this.surface.products[index];

    if (!demand || !product || !product.recommendedValues) {
      return false;
    }

    const { guid } = demand.model;
    const recommended = product.recommendedValues[guid];
    return recommended && recommended === this.getUnitsFor(product);
  }

  hasRecommendedValue(product: Product) {
    const demand = this.selectedDemand.model;
    const recommended = product.recommendedValues;
    return demand && recommended ? recommended[demand.guid] != null : false;
  }

  getRecommendedValuePosition(product: Product) {
    const demand = this.selectedDemand.model;

    if (!demand || !product.recommendedValues) {
      return 0;
    }

    const value = product.recommendedValues[demand.guid];
    const min = demand.minimumValue;
    const max = demand.maximumValue;
    const unit = (value - min) / (max - min);
    return Math.round(unit * 100);
  }

  searchColourTriggered(value) {
    this.searchColoursControl.setValue(value);
    clearTimeout(this.typingTimer);

    this.typingTimer = setTimeout(() => {
      this.colours = [];
      this.filteredColours.next([]);
      this.filterColours();
    }, 200);
  }

  checkPlaceHolder(value) {
    this.colourPlaceholder = value;
  }

  colourSelected(option: MatAutocompleteSelectedEvent) {
    this.surface.colour = option.option.value as Colour;
    sendAnalyticsEvent(
      'Colour Selection',
      `Colour: ${this.surface.colour.code}; Surface: ${this.surface.model.name}`,
    );
    this.calculate();
    this.store.project = this.project;
  }

  displayFn(colour): string | undefined {
    return colour ? colour.code || colour : undefined;
  }

  @HostListener('window:scroll', [])
  scrollHandler() {
    this.trigger.closeMenu();
  }

  private filterColours() {
    if (!this.colours) {
      return;
    }

    this.currentColourPage = 0;
    this.colours = [];
    this.getColours(this.currentColourPage, false);
  }

  initScroll() {
    const colourIndex = this.colours.findIndex(
      x => x.code === this.surface.colour.code,
    );
    this.cdkViewPort.scrollToIndex(colourIndex || 1);
    this.cdkViewPort.elementScrolled().subscribe(value => {
      const distanceToBottom = this.cdkViewPort.measureScrollOffset('bottom');
      if (distanceToBottom < 5) {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {
          this.currentColourPage++;
          this.getColours(this.currentColourPage, false);
        }, 500);
      }
    });
  }

  private onIsComparingChange(value: boolean) {
    if (!this.products || !this.products.length) {
      return;
    }
    var first = this.products[0];
    var second;    
    if (this.products.length == 1)
      second = this.products[0];          
    else
      second = this.products[1];
    
    const options = value ? [first, second] : [first];

    this.surface.setOptions(options, this.products.length == 1);

    Promise.all(options.map((_, i) => this.getPaintSystems(i))).then(() =>
      this.calculate(),
    );
  }

  changeSelectedDemand(event: MatTabChangeEvent) {
    this.selectedDemand = this.surface.customerDemands.find(
      x => x.model.guid === event.tab.ariaLabel,
    );
    sendAnalyticsEvent(
      'Customer Demand Changed (Tab View)',
      `Demand: ${this.selectedDemand.model.label}; Surface: ${this.surface.model.name}`,
    );
    this.calculate();
  }

  setRecommendedValue(demand = this.selectedDemand) {
    if (!this.recommendedValues.length) {
      return setTimeout(() => this.setRecommendedValue(), 100);
    }

    demand.units = this.recommendedValues[0];
    this.calculate();
  }

  toggleCustomerDemand(event: MatSlideToggleChange) {
    const demand = this.selectedDemand;
    demand.enabled = event.checked;
    this.hasDemandApplied.emit(this.isDemandApplied);

    if (event.checked) {
      const [recommended] = this.recommendedValues;
      demand.units = recommended || demand.model.sliderDefaultValue;
    } else {
      demand.units = null;
    }

    sendAnalyticsEvent(
      `Customer Demand ${demand.enabled ? 'Enabled' : 'Disabled'}`,
      `Demand: ${demand.model.label}; Surface: ${this.surface.model.name}`,
    );

    this.calculate();
  }

  applyCustomerDemandUnits(event: MatSliderChange) {
    this.selectedDemand.units = event.value;
    this.calculate();
  }

  getPaintSystems(
    productIndex: number = 0,
    refreshSurfaceSystem: boolean = false,
  ) {
    return this.api
      .getSurfacePaintSystems(
        this.surface.model.guid,
        this.surface.products[productIndex].guid,
      )
      .then(systems => {
        this.paintSystems[productIndex] = systems;

        if (!this.surface.paintSystems[productIndex] || refreshSurfaceSystem) {
          this.surface.paintSystems[productIndex] = systems[0];
        }
      });
  }

  selectProduct(product: Product, index: number) {
    if (!this.isProductSelected(product, index)) {
      this.surface.products[index] = product;
    }
    sendAnalyticsEvent(
      'Product Selected',
      `Product: ${product.name}; Option: ${index + 1}; Surface: ${
        this.surface.model.name
      }`,
    );
    this.getPaintSystems(index, true).then(() => this.calculate());
  }

  isProductSelected(product: Product, index: number) {
    const selected = this.surface.products[index];
    return selected && (selected === product || selected.guid === product.guid);
  }

  calculate() {
    if (!this.surface.colour) {
      return;
    }

    this.project.resetCalculationsCache();
    this.calculationChanged.next(true);
    this.saveProject();
  }

  private saveProject() {
    this.store.project = this.project;
  }

  calculateSurfaceChart(calculation: ISurfaceOptionCalculation) {
    if (!calculation) {
      return null;
    }

    return [
      {
        data: calculation.minimum,
        label: this.translate.instant('Minimum'),
        lineTension: 0,
        borderDash: getDashing(),
      },
      {
        data: calculation.expected,
        label: this.translate.instant('Expected'),
        lineTension: 0,
      },
    ];
  }

  getSystemLayers(optionIndex: number) {
    return this.surface.paintSystems[optionIndex].layers;
  }

  getCustomerDemandData(productIndex: number = 0) {
    const { calculations: calc, selectedDemand: selected } = this;
    const demands = calc && calc.options && calc.options[productIndex];
    const data =
      demands &&
      demands.find(
        x => x && x.customerDemand.model.guid === selected.model.guid,
      );

    return data;
  }

  generateChartData(product: Product) {
    const index = this.surface.products.indexOf(product);
    const value = this.getUnitsFor(product);
    const data = this.getCustomerDemandData(index);

    if (!data) {
      return;
    }

    const result = [
      {
        data: data.minimum,
        label: this.translate.instant('Minimum'),
        borderDash: getDashing(),
      },
      {
        data: data.expected,
        label: this.translate.instant('Expected'),
      },
    ];

    if (value !== null) {
      result.push({
        data: Array(data.minimum.length).fill(value),
        label: this.getCustomerDemandUnits(),
      });
    }

    return result;
  }

  getBannerProps() {
    const calc = this.calculations;

    if (!calc || !calc.maintainance) {
      return;
    }

    const [first] = this.calculations.maintainance;
    const { redecoration, recommended, customerDemand } = first.basedOn;
    const years = redecoration === TEN_PLUS_YEARS ? '10+' : redecoration;
    const span = x => `<span class="reco-deco-banner__pill">${x}</span>`;

    return {
      requirement: span(this.translate.instant(customerDemand.model.label)),
      duration: span(this.translate.instant('# years').replace('#', years)),
      units: span(recommended),
    };
  }

  getCustomerDemandUnits() {
    const demand = this.translate.instant(this.selectedDemand.model.label);
    const units = this.translate.instant('units');
    return `${demand} ${units}`;
  }

  editSurface(surface: AreaSurface, index: number) {
    this.store.area = this.area;
    this.router.navigate(['project/edit/surface'], {
      state: {
        isNew: false,
        surfaceIndex: index,
      },
    });
  }

  deleteSurface(index: number) {
    this.removeSurfaceTrigger.emit({ area: this.area, index });
  }

  addComment(surface: AreaSurface, areaIndex: number, surfaceIndex: number) {
    this.dialog.open(SurfaceCommentComponent, {
      data: { surface, surfaceIndex: `${areaIndex + 1}.${surfaceIndex + 1}` },
      panelClass: 'cdk-overlay-pane--large',
      autoFocus: false,
    });
  }

  compareColours(first: Colour, second: Colour) {
    if (first && second) {
      return first.code === second.code;
    }
  }

  compareLayers(first: PaintLayer, second: PaintLayer) {
    if (first && second) {
      return first.guid === second.guid;
    }
  }

  hasRedecoration() {
    const calc = this.calculations;
    return (
      calc &&
      calc.options &&
      calc.options.some(x => x.some(y => y.redecoration !== -1))
    );
  }

  getRedecoration(productIndex: number) {
    const durability = this.getDurability(productIndex);
    return durability === -1 ? -1 : this.getYears(durability);
  }

  hasLongestDurability(productIndex: number) {
    if (!this.project.isComparing) {
      return false;
    }

    const durabilities = this.surface.products.map((_, index) =>
      this.getDurability(index),
    );

    const [productDurability] = durabilities.splice(productIndex, 1);
    return durabilities.every(x => productDurability > x);
  }

  private getDurability(productIndex: number) {
    const calc = this.calculations;
    const product = calc && calc.options && calc.options[productIndex];
    const demand = product && product[this.selectedDemandIndex];
    return demand ? demand.redecoration : -1;
  }

  paintSystemChanged(event: MatSelectChange, optionIndex: number) {
    sendAnalyticsEvent(
      'Paint system changed',
      `Paint System: ${event.value.name};
       Option: ${optionIndex + 1};
       Surface: ${this.surface.model.name}`,
    );
    this.calculate();
  }

  resetSurface() {
    this.dialog
      .open<SurfaceResetComponent, SurfaceResetData>(SurfaceResetComponent, {
        panelClass: 'cdk-overlay-pane--large',
        autoFocus: false,
        data: { surfaceName: this.surface.name },
      })
      .afterClosed()
      .toPromise()
      .then(proceed => {
        if (proceed) {
          this.surface.customerDemands.forEach(demand => {
            demand.enabled = false;
            this.setRecommendedValue(demand);
          });
        }
      });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdminService {
  reloadSidebar = new BehaviorSubject(false);

  constructor(private httpClient: HttpClient) {}

  triggerReload() {
    this.reloadSidebar.next(true);
  }
}

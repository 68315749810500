import { BehaviorSubject, Subscription } from 'rxjs';

import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange, MatSelectChange } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { round } from '../../helpers/locale-number';
import { NLnumberPattern, UKnumberPattern } from '../../helpers/patterns';
import { EnvFactor, IEnvFactorOption } from '../../models/api/EnvFactor';
import { ProjectTypeCode } from '../../models/api/ProjectType';
import { Surface } from '../../models/api/Surface';
import { Area } from '../../models/Area';
import { AreaSurface } from '../../models/AreaSurface';
import { ApiService } from '../../services/api.service';
import { sendAnalyticsEvent } from 'src/app/utils/analytics';

@Component({
  selector: 'app-surface-details',
  templateUrl: './surface-details.component.html',
  styleUrls: ['./surface-details.component.scss'],
})
export class SurfaceDetailsComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  @Input() model: AreaSurface;
  @Input() area: Area;
  @Input() surfaceIndex: number;
  @Input() projectType: ProjectTypeCode;
  @Input() canAddNew: boolean;
  @Input() isInEditMode: boolean;
  @Output() addNewTrigger: EventEmitter<Area> = new EventEmitter();

  envForm = new FormGroup({});
  projectSurfaces: Surface[];
  validSubscription: Subscription;
  envFactorsSubscription: Subscription;
  environmentalFactors: EnvFactor[];
  userClickedName = false;

  validationForm = new FormGroup({
    surfaceType: new FormControl(
      { value: '', disabled: true },
      Validators.required,
    ),
    standardSize: new FormControl('', [
      Validators.required,
      Validators.pattern(UKnumberPattern || NLnumberPattern),
    ]),
    totalSize: new FormControl('', [
      Validators.required,
      Validators.pattern(UKnumberPattern || NLnumberPattern),
    ]),
  });

  @Output() isValid = new BehaviorSubject<boolean>(this.formStatus);

  get defaultSurfaceName() {
    return AreaSurface.getDefaultName(this.translate, this.surfaceIndex);
  }

  get formStatus() {
    const areaForm = this.validationForm.valid;
    const envForm = this.envForm.valid;

    return !areaForm || !envForm ? false : true;
  }

  get checkIfValid() {
    return !this.validationForm.get('surfaceType').valid;
  }

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly api: ApiService,
  ) {}

  ngOnInit() {
    this.api.getProjectTypeSurfaces(this.projectType).then(surfaces => {
      this.projectSurfaces = surfaces;
    });

    this.api.getSurfaceEnvFactors(this.projectType).then(envFactors => {
      this.environmentalFactors = envFactors;

      envFactors.forEach(factor =>
        this.envForm.addControl(
          factor.label,
          new FormControl('', Validators.required),
        ),
      );
    });

    if (this.model.standardSize) {
      this.surfaceSizeChange(this.model.standardSize);
    }

    this.validSubscription = this.validationForm.statusChanges.subscribe(
      change => this.isValid.next(this.formStatus),
    );
    this.subscribeToFactors();

    if (!this.isInEditMode) {
      this.validationForm.get('surfaceType').enable();
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  subscribeToFactors() {
    this.envFactorsSubscription = this.envForm.statusChanges.subscribe(change =>
      this.isValid.next(this.formStatus),
    );
  }

  ngOnDestroy() {
    if (this.validSubscription) {
      this.validSubscription.unsubscribe();
    }
    if (this.envFactorsSubscription) {
      this.envFactorsSubscription.unsubscribe();
    }
  }

  calculateTotalSize(value: number, units: number) {
    return value * units;
  }

  selectSurfaceType(event: MatSelectChange) {
    this.model.envFactors = [];
    const selectedSurface = event.value as Surface;

    this.subscribeToFactors();

    this.model.standardSize = selectedSurface.getDefaultSizeFor(
      this.area.subsector,
    );
    this.surfaceSizeChange(this.model.standardSize);
    sendAnalyticsEvent(
      'Surface Type Assigned',
      `Surface Type: ${selectedSurface.name}; SubSector: ${this.area.subsector.name}`,
    );
  }

  addSurface() {
    this.addNewTrigger.emit(this.area);
  }

  compareSurfaceTypes(o1: Surface, o2: Surface) {
    if (o1 && o2) {
      return o1.guid === o2.guid;
    }
  }

  compareEnvFactor(o1: IEnvFactorOption, o2: IEnvFactorOption) {
    if (o1 && o2) {
      return o1.guid === o2.guid;
    }
  }

  logEnvFactors(event: MatSelectChange) {
    sendAnalyticsEvent(
      'Surface - Env Factor Assigned',
      `Env Factor: ${event.value.label};
        Surface: ${this.model.model.name};
        SubSector: ${this.area.subsector.name};
        Project Type: ${this.projectType}`,
    );
  }

  logSurfaceRMI(event: MatCheckboxChange) {
    sendAnalyticsEvent(
      'Surface RMI changed',
      `IsRMI: ${event.checked}; Surface: ${
        this.model.model ? this.model.model.name : this.defaultSurfaceName
      }`,
    );
  }

  surfaceSizeChange(event: any) {
    const value = Number(event) || event.target.value;

    if (event instanceof KeyboardEvent) {
      if (event.key === '.' || event.key === ',') {
        return;
      }
    }

    this.model.standardSize = value;

    if (value == null) {
      return;
    }

    const numOfUnits = this.area.unitValue;
    this.model.totalSizeArea = round(value * numOfUnits);

    // Removed becuase it gets triggered everytime when user accesses surface details - SPAM
    // sendAnalyticsEvent(
    //   'Standard Unit Size Changed',
    //   `Surface: ${this.model.model.name}; Value: ${this.model.standardSize}; Project Type: ${this.projectType}`,
    // );
  }

  totalAreaChange(event: any) {
    const value = Number(event) || event.target.value;

    if (event instanceof KeyboardEvent) {
      if (event.key === '.' || event.key === ',') {
        return;
      }
    }

    this.model.totalSizeArea = value;

    if (value == null) {
      return;
    }

    const num = value;
    const numOfUnits = this.area.unitValue;
    this.model.standardSize = round(num / numOfUnits);
  }

  cleanSurfaceSize() {
    this.model.standardSize = round(this.model.standardSize);
    this.model.totalSizeArea = round(this.model.totalSizeArea);
  }

  countDecimals(value: number) {
    if (Math.floor(value) === value) {
      return 0;
    }
    return value.toString().split('.')[1].length || 0;
  }

  log(eve) {
    console.log(eve);
  }
}

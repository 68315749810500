import { ErrorHandler, Injector, Inject } from '@angular/core';
import { ErrorService } from './error.service';

export class LifeCycleErrorHandler implements ErrorHandler {
  private service: ErrorService;

  constructor(@Inject(Injector) private readonly injector: Injector) {
    this.service = this.injector.get(ErrorService);
  }

  handleError(error: any): void {
    if (!this.service) {
      this.service = this.injector.get(ErrorService);
    }
    this.service.handleGenericError(error);
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { CachedValue } from './CachedValue';

const SCROLL_DURATION_MS = 200;
const DEFAULT_SCROLL_UNIT = 0.09;

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[app-horizontal-scroll]',
  templateUrl: './horizontal-scroll.component.html',
  styleUrls: ['./horizontal-scroll.component.scss'],
})
export class HorizontalScrollComponent implements AfterViewInit {
  isAtStart = true;
  isAtEnd = true;

  @Input() scrollUnitWidth: number;
  @Input() threshold: number;
  @Input() forceScroll: EventEmitter<number>;
  @Input() refresh: EventEmitter<void>;
  @Output() readonly isAtStartChange = new EventEmitter<boolean>();
  @Output() readonly isAtEndChange = new EventEmitter<boolean>();
  @Output() readonly thresholdReached = new EventEmitter<void>();
  @ViewChild('scrollArea') area: ElementRef<HTMLElement>;

  private isAnimating = false;

  private readonly observer = new MutationObserver(mutations => {
    this.areaBoundaries.clear();
    this.update();
  });

  private readonly areaBoundaries = new CachedValue(
    (el: HTMLElement) => el.clientWidth,
    1000,
  );

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.update();

    this.observer.observe(this.area.nativeElement, {
      childList: true,
    });

    if (this.forceScroll) {
      this.forceScroll.subscribe(value => {
        this.area.nativeElement.scrollLeft = value;
      });
    }

    if (this.refresh) {
      this.refresh.subscribe(() => {
        this.areaBoundaries.clear();
        this.update();
      });
    }

    this.cdRef.detectChanges();
  }

  onScroll() {
    this.update();
  }

  @HostListener('window:resize')
  updateOnResize() {
    this.isAtEnd = true;
    setTimeout(() => {
      this.update();
    }, 600);
  }

  update() {
    if (this.isAnimating) {
      return;
    }

    const el = this.area.nativeElement;
    const { scrollLeft, scrollWidth } = el;
    const isAtStart = !scrollLeft;

    if (this.isAtStart !== isAtStart) {
      this.isAtStart = isAtStart;
      this.isAtStartChange.emit(isAtStart);
    }

    const clientWidth = this.areaBoundaries.get(el);
    const distanceToEnd = scrollWidth - (scrollLeft + clientWidth);
    const isAtEnd = distanceToEnd === 0;

    if (this.isAtEnd !== isAtEnd) {
      this.isAtEnd = isAtEnd;
      this.isAtEndChange.emit(isAtEnd);
    }

    if (this.threshold != null && distanceToEnd < this.threshold) {
      this.thresholdReached.emit();
    }
  }

  onPrevClick() {
    this.manualScroll(-1);
  }

  onNextClick() {
    this.manualScroll(1);
  }

  private manualScroll(direction: 1 | -1) {
    const el = this.area.nativeElement;
    const { scrollLeft, clientWidth } = el;
    const scrollStep =
      this.scrollUnitWidth || clientWidth * DEFAULT_SCROLL_UNIT;
    const scrollDistance = clientWidth - (clientWidth % scrollStep);
    const start = Date.now();

    const animate = () => {
      const delta = Math.min(Date.now() - start, SCROLL_DURATION_MS);
      const isDone = delta === SCROLL_DURATION_MS;
      const newPosition = (scrollDistance / SCROLL_DURATION_MS) * delta;

      el.scrollLeft = scrollLeft + newPosition * direction;

      if (isDone) {
        this.isAnimating = false;
        this.update();
      } else {
        requestAnimationFrame(animate);
      }
    };

    this.isAnimating = true;
    requestAnimationFrame(animate);
  }
}

import { CurrencyPipe, formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

export class LocaleFormatter {
  constructor(private readonly lang: TranslateService) {}

  public gbpFormatter(num, dInf, k, m) {
    return (
      new CurrencyPipe(this.lang.currentLang).transform(
        num,
        'EUR',
        'symbol-narrow',
        dInf
      ) + (m ? 'M' : k ? 'K' : '')
    );
  }

  public kgFormatter(num, dInf, k, m) {
    return (
      formatNumber(num, this.lang.currentLang, dInf) +
      ' ' +
      (m ? 'kt' : k ? 't' : 'Kg')
    );
  }

  public formatter(lbl: number, weight?: boolean) {
    const k = lbl >= 1000;
    const m = lbl >= 1000000;
    const num = m ? lbl / 1000000 : k ? lbl / 1000 : lbl;
    const dInf = '1.' + (k ? '1-1' : '0-0');
    if (weight) {
      return this.kgFormatter(num, dInf, k, m);
    }

    return this.gbpFormatter(num, dInf, k, m);
  }
}

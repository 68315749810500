import { TranslateService } from '@ngx-translate/core';

import { ApiService } from '../services/api.service';
import { Colour } from './api/Colour';
import { IEnvFactorOption } from './api/EnvFactor';
import { PaintSystem } from './api/PaintSystem';
import { IProductJson, Product } from './api/Product';
import { ISurfaceJson, Surface } from './api/Surface';
import {
  ISurfaceCustomerDemandJson,
  SurfaceCustomerDemand,
} from './SurfaceCustomerDemand';

let nextId = 1;

export class AreaSurface implements IAreaSurface {
  static fromJson(api: ApiService, json: IAreaSurfaceJson) {
    if (json.id >= nextId) {
      nextId = json.id + 1;
    }

    return new AreaSurface({
      ...json,
      model: Surface.fromJson(json.model),
      products: wrapModels(json.products, x => Product.fromJson(api, x)),
      paintSystems: wrapModels(
        json.paintSystems,
        x => x && PaintSystem.fromJson(api, x)
      ),
      customerDemands: wrapModels(
        json.customerDemands,
        SurfaceCustomerDemand.fromJson
      ),
    });

    function wrapModels<T, U>(list: T[], wrapper: (value: T) => U) {
      return list && list.map(wrapper);
    }
  }

  static getDefaultName(translate: TranslateService, index: number) {
    return `${translate.instant('Surface')} ${index + 1 || 1}`;
  }

  readonly id: number;
  model: Surface;
  name: string;
  isRmi = false;
  standardSize: number;
  totalSizeArea: number;
  comment: string;
  colour: Colour;
  products: Product[] = [];
  paintSystems: PaintSystem[] = [];
  envFactors: IEnvFactorOption[] = [];
  customerDemands: SurfaceCustomerDemand[];

  get areaSize() {
    return this.totalSizeArea;
  }

  constructor(json?: IAreaSurface) {
    this.id = nextId++;

    if (json) {
      Object.assign(this, json);
    }
  }

  getLabourCost(base: number) {
    return base * this.areaSize;
  }

  getPaintSystemFor(product: Product) {
    const index = this.products.findIndex(x => x.guid === product.guid);

    if (index === -1) {
      throw new Error('Searching for product not in surface');
    }

    return this.paintSystems[index];
  }

  setOptions(defaults: Product[], isSingleProduct: boolean) {
    var copy;
    if(!isSingleProduct) {
      copy = defaults.filter(x =>
        this.products.every(y => !y || y.guid !== x.guid)
      );
    }
    else {
      copy = defaults.filter(x =>
        this.products.every(y => !y || y.guid === x.guid)
      );
    }

    const len = defaults.length;
    let isModified = false;

    if (!isSingleProduct)
    {
      for (let i = 0; i < len; i++) {
        if (!this.products[i]) {
          this.products[i] = copy.shift();
          isModified = true;
        }
      }
    }
    else {
      for (let i = 0; i < len; i++) {
          this.products[i] = copy[i];
          isModified = true;
        }
    }

    this.products.length = len;
    this.paintSystems.length = len;
    return isModified;
  }

  clone() {
    return new AreaSurface({
      ...this,
      envFactors: this.envFactors.slice(),
      products: this.products.slice(),
      paintSystems: this.paintSystems.slice(),
      customerDemands: this.customerDemands.slice(),
    });
  }
}

interface IAreaSurface {
  model: Surface;
  name: string;
  isRmi: boolean;
  standardSize: number;
  totalSizeArea: number;
  comment: string;
  envFactors: IEnvFactorOption[];
  paintSystems: PaintSystem[];
  colour: Colour;
  products: Product[];
  customerDemands: SurfaceCustomerDemand[];
}

export interface IAreaSurfaceJson {
  id: number;
  model: ISurfaceJson;
  name: string;
  isRmi: boolean;
  standardSize: number;
  totalSizeArea: number;
  comment: string;
  colour: Colour;
  products: IProductJson[];
  paintSystems: PaintSystem[];
  envFactors: IEnvFactorOption[];
  customerDemands: ISurfaceCustomerDemandJson[];
}

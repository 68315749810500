import {
  Component,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
})
export class CookiePolicy implements OnInit, AfterViewInit {
  private fragment: string;
  constructor(
    private readonly translate: TranslateService,
  ) { }

  ngOnInit() {
    const currentLanguage = this.translate.currentLang;
    const temp: any = window;
    temp.OneTrust.NoticeApi.Initialized.then(async function () {
      const loaded = await temp.OneTrust.NoticeApi.LoadNotices(
        [
            'https://privacyportalde-cdn.onetrust.com/24527c35-1de1-4aaa-833d-de50b42446bd/privacy-notices/8f61895d-5abb-45b4-a72d-c2c42df7974e.json',
        ],
        true,
        currentLanguage,
      );
      const unorderedList: any = document.querySelector(
        '#otnotice-8f61895d-5abb-45b4-a72d-c2c42df7974e > div.otnotice-content > ul',
      );
      unorderedList.style = 'list-style-type: none; padding-left: 0;';
      if (loaded) {
        unorderedList.querySelectorAll('li').forEach(item => {
          const a: any = item.children.item(0);
          let tempUrl:string = a.href;
          const [baseUrl,anchor] = tempUrl.split("#");
          a.href = baseUrl+ "cookie-policy#"+anchor
        })
        document.querySelectorAll('p').forEach(item => {
          const element: any = item;
          element.style = 'font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; font-size: 15.8px; text-align: left !important;';
        })
        document.querySelectorAll('a').forEach(item => {
          const element: any = item;
          element.style = 'color: rgba(0, 0, 0, 0.6); font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; font-size: 15.8px; font-weight: 400; line-height: 1.45;';
        })
        document.querySelectorAll('body > app-root > app-cookie-policy > app-footer > div > div > div > mat-toolbar > mat-toolbar-row.footer__row-1.mat-toolbar-row > nav > ul > li').forEach(item => {
          const a: any = item.children.item(0);
          a.style='color: rgba(0, 0, 0, 0.6); font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; font-size: 13.8px; font-weight: 400; line-height: 1.45;'
        });
        document.querySelectorAll('p > strong > span').forEach(item => {
          const element: any = item;
          element.style = 'color: #002248; font-size: 19.6px;';
        })
        document.querySelectorAll('h1').forEach(item => {
          const element: any = item;
          element.style = 'font-family: "colour_sansbold", Arial, sans-serif !important; font-size: 37.1px; font-weight: normal; line-height: normal; letter-spacing: 0.25px; text-transform: none; color: #002248; text-align: left;';
        })
        document.querySelectorAll('#otnotice-section-e3a17317-8bb4-a54d-88cf-f51cbc1da16d > div > p:nth-child(1) > strong > span').forEach(item => {
          const element: any = item;
          element.style = 'font-family: "colour_sansbold", Arial, sans-serif !important; font-size: 37.1px; font-weight: normal; line-height: normal; letter-spacing: 0.25px; text-transform: none; color: #002248;';
        })
        
        document.querySelectorAll('h2').forEach(item => {
          const element: any = item;
          element.style = 'font-family: "colour_sansbold", Arial, sans-serif; color: #002248; font-size: 26.2px;';
        })
      }
    }); 
  }


  ngAfterViewInit() {

  }
}


/**
 * Ensures calculate is invoked no more than once every `expiration` milliseconds.
 * If called more frequently than that it will return the value cached from the previous call.
 */
export class CachedValue<T, U extends any[]> {
  private cache: T;
  private isCached = false;

  constructor(
    private readonly calculate: (...args: U) => T,
    private readonly expiration: number,
  ) {
    this.expired = this.expired.bind(this);
  }

  get(...args: U) {
    return this.isCached ? this.cache : this.recalculate(...args);
  }

  clear() {
    this.isCached = false;
  }

  private recalculate(...args: U) {
    const value = this.calculate(...args);

    this.cache = value;
    this.isCached = true;

    setTimeout(this.expired, this.expiration);

    return value;
  }

  private expired() {
    this.cache = null;
    this.isCached = false;
  }
}

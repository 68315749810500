import { Location } from '@angular/common';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomerDemand } from '../../../../models/api/CustomerDemand';
import {
  IProductDataPointsJson,
  Product
} from '../../../../models/api/Product';
import { ApiService } from '../../../../services/api.service';
import { AdminService } from '../../services/admin.service';
import { ProductCheckRequest, ProductEditRequest } from 'src/app/models/requests/ProductRequests';

const DATA_POINTS_COUNT = 21;
const lastIndex = DATA_POINTS_COUNT - 1;

enum UploadState {
  tooLarge = 'tooLarge',
  default = 'default',
}

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent {
  @ViewChild('editProductDialog') editProductDialog: TemplateRef<MatDialog>;
  @ViewChild('deleteDialog') deleteDialog: TemplateRef<MatDialog>;
  @ViewChild('editData') editData: TemplateRef<MatDialog>;

  demands: CustomerDemand[];
  dataPoints: IProductDataPointsJson;
  product: Product;
  uploadStatus = UploadState.default;
  isDataPointsTableVisible = true;
  productTimer: any;

  years = Array(DATA_POINTS_COUNT)
    .fill(0)
    .map((_, i) => i);

  dataPointsSource = new MatTableDataSource(this.years);

  columns: {
    demands: string[];
    recommended: string[];
    minExp: string[];
  };

  editProductForm = new FormGroup({
    image: new FormControl(''),
    name: new FormControl('', Validators.required),
    paintCost: new FormControl('', Validators.required),
    paintSpeed: new FormControl('', Validators.required),
    spreadingRate: new FormControl('', Validators.required),
    co2e: new FormControl('', Validators.required),
  });

  get imageControl() {
    return this.editProductForm.get('image').value;
  }
  get productStatus() {
    const issues = [];

    if (!this.product.isComplete) {
      issues.push('Required Data Points are missing.');
    }

    if (!this.product.isInLayers) {
      issues.push('Product not added into a paint system yet.');
    }

    return issues.join(' ');
  }

  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly api: ApiService,
    private readonly adminService: AdminService,
    // builder: FormBuilder,
    route: ActivatedRoute,
  ) {
    const guid = route.snapshot.paramMap.get('id');

    if (!guid) {
      this.router.navigate(['/admin/products']);
      return;
    }

    this.initProduct(guid);
  }

  initProduct(guid: string) {
    this.api.clearCache();

    return Promise.all([
      this.api.getProduct(guid),
      this.api.getCustomerDemands(),
    ])
      .then(([product, demands]) => {
        this.product = product;
        this.demands = demands;
        this.buildColumns();
        return product.fetchDataPoints();
      })
      .then(dataPoints => (this.dataPoints = dataPoints));
  }

  private buildColumns() {
    const guids = this.demands.map(x => x.guid);

    this.columns = {
      demands: ['year', ...guids],
      recommended: ['year-recommended', ...guids.map(x => `${x}-recommended`)],
      minExp: ['year', ...flatten(guids.map(x => [`${x}-min`, `${x}-exp`]))],
    };
  }

  printYear(index: number) {
    return `Year ${(index / 2).toFixed(1)}`;
  }

  printValue(value: number) {
    return value == null || isNaN(value) ? '-' : value;
  }

  getRecommendedValue(demand: CustomerDemand) {
    const recommended = this.product.recommendedValues;
    return recommended && recommended[demand.guid];
  }

  getMinimumValue(demand: CustomerDemand, year: number) {
    return this.getDataPoint(demand, year, 'minimum');
  }

  getExpectedValue(demand: CustomerDemand, year: number) {
    return this.getDataPoint(demand, year, 'expected');
  }

  private getDataPoint(
    demand: CustomerDemand,
    year: number,
    type: 'minimum' | 'expected',
  ) {
    const data = this.dataPoints;
    const points = data && data[demand.guid];
    const list = points && points[type];
    return list && round(list[year]);
  }

  back() {
    this.location.back();
  }

  isRecommendedCycleValid(value: number) {
    return !isNaN(value) && value !== 0;
  }

  dataIsValid({ minimum, expected }: EditDataPoints) {
    const m0 = minimum[0];
    const m1 = minimum[lastIndex];
    const e0 = expected[0];
    const e1 = expected[lastIndex];

    const someIsNull = m0 == null || m1 == null || e0 == null || e1 == null;
    const someIsNaN = isNaN(m0) || isNaN(m1) || isNaN(e0) || isNaN(e1);

    return !someIsNull && !someIsNaN;
  }

  isRequired(index: number) {
    return index === 0 || index === lastIndex;
  }

  isInvalid(list: number[], index: number) {
    const hasContent = list.some(x => x != null);
    return hasContent && this.isRequired(index) && list[index] == null;
  }

  trackByFn(index: number) {
    return index;
  }

  editProduct() {
    this.editProductForm.reset();

    return this.dialog
      .open(this.editProductDialog, {
        data: this.product.toJSON(),
      })
      .afterClosed()
      .toPromise()
      .then(value => {
        if (value) {
          const model: ProductEditRequest = {
            guid: this.product.guid,
            name: this.editProductForm.get('name').value,
            image: this.editProductForm.get('image').value,
            pricePerLitre: this.editProductForm.get('paintCost').value,
            co2PerLitre: this.editProductForm.get('co2e').value,
            spreadingRatePerLitre: this.editProductForm.get('spreadingRate').value,
            paintSpeed: this.editProductForm.get('paintSpeed').value,
            standardRedecorationCycle: this.product.standardRedecorationCycle
          };
          return this.api.editProduct(model).then(res => {
            this.adminService.reloadSidebar.next(true);
            this.initProduct(this.product.guid);
            return true;
          });
        }
      });
  }

  editDataPoints(demand: CustomerDemand) {
    return this.dialog
      .open(this.editData, {
        data: {
          productName: this.product.name,
          demandName: demand.label,
          recommended: this.getRecommendedValue(demand),
          minimum: this.years.map(x => this.getMinimumValue(demand, x)),
          expected: this.years.map(x => this.getExpectedValue(demand, x)),
        } as EditDataPoints,
      })
      .afterClosed()
      .toPromise()
      .then((value: EditDataPoints) => {
        if (!value) {
          return false;
        }

        this.isDataPointsTableVisible = false;

        return this.api
          .editProductDataPoints(this.product.guid, demand.guid, {
            recommended: value.recommended,
            expected: value.expected,
            minimum: value.minimum,
          })
          .then(() => {
            this.product.resetDataPoints();
            this.adminService.reloadSidebar.next(true);
            return this.initProduct(this.product.guid);
          })
          .then(() => {
            this.isDataPointsTableVisible = true;
            return true;
          });
      });
  }

  deleteProduct() {
    this.dialog
      .open(this.deleteDialog)
      .afterClosed()
      .toPromise()
      .then(value => {
        if (!value) {
          return false;
        }

        return this.api.deleteProduct(this.product.guid).then(res => {
          this.api.clearCache();
          this.router.navigate(['/admin/products']);
          return true;
        });
      });
  }

  checkProduct() {
    clearTimeout(this.productTimer);        
    this.editProductForm.get('name').updateValueAndValidity();        
    this.productTimer = setTimeout(() => {
      const model: ProductCheckRequest = {        
        name: this.editProductForm.get('name').value,       
      };
      this.api
        .checkProduct(model, this.product.guid)
        .then()
        .catch(err => {
          if (err.status == 409) {
            this.editProductForm.get('name').setErrors({ conflict: true });            
          }
        });
    }, 300);
  }

  uploadImage(event) {
    this.uploadStatus = UploadState.default;
    const [file] = (event.target.files || []) as File[];

    if (!file) {
      return;
    }

    readImage(file).then(image => {
      if (image.width > 1024 || image.height > 768) {
        this.uploadStatus = UploadState.tooLarge;
      } else {
        this.editProductForm.get('image').setValue(image.src);
      }
    });
  }
}

interface EditDataPoints {
  productName: string;
  demandName: string;
  recommended: number;
  minimum: number[];
  expected: number[];
}

function flatten<T>(list: T[][]): T[] {
  return [].concat(...list);
}

function round(value: number) {
  const operator = 10 ** 3;
  return Math.round(value * operator) / operator;
}

function readImage(file: File) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = reject;

    reader.onload = () => {
      const image = new Image();
      image.src = reader.result as string;
      resolve(image);
    };
  });
}

import { ApiService } from '../services/api.service';

export class CachedAsyncProperty<T> {
  private api: ApiService;
  private iteration = 0;
  private executing: Promise<T> = null;
  private isCached = false;
  private cache: T = null;

  constructor(private execute: (api: ApiService) => Promise<T>) {}

  setApi(api: ApiService) {
    this.api = api;
  }

  get() {
    if (this.isCached) {
      return this.cache;
    }

    this.forceFetch();
    return null;
  }

  fetch() {
    if (this.isCached) {
      return Promise.resolve(this.cache);
    }

    return this.forceFetch();
  }

  forceFetch() {
    this.assertApi();

    if (!this.executing) {
      const iteration = ++this.iteration;
      this.executing = this.execute(this.api);

      this.executing.then(x => {
        if (iteration !== this.iteration) {
          return;
        }

        this.executing = null;
        this.isCached = true;
        this.cache = x;
      });
    }

    return this.executing;
  }

  reset() {
    this.executing = null;
    this.isCached = false;
  }

  private assertApi() {
    if (!this.api) {
      throw new Error(
        'new Product().dataPoints requires setApi() to be called',
      );
    }
  }
}

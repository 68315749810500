export class MaintainanceCycle {
  system = 0;
  labour = 0;
  co2e = 0;

  get total() {
    return this.system + this.labour;
  }

  constructor(data?: IMaintainanceCycle) {
    if (data) {
      Object.assign(this, data);
    }
  }

  add(other: MaintainanceCycle) {
    return new MaintainanceCycle({
      system: this.system + other.system,
      labour: this.labour + other.labour,
      co2e: this.co2e + other.co2e,
    });
  }

  multiply(value: number) {
    return new MaintainanceCycle({
      system: this.system * value,
      labour: this.labour * value,
      co2e: this.co2e * value,
    });
  }
}

export interface IMaintainanceCycle {
  system: number;
  labour: number;
  co2e: number;
}

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { AuthService } from './services/auth.service';
import { InitAnalytics, setDimensionAndInitAnalytics } from './utils/analytics';
import { Router } from '@angular/router';

const LANG_KEY = 'lang';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'LifeCycleNLWeb';
  languageChange: Subscription;

  constructor(
    private readonly translate: TranslateService,
    private router: Router,
    private readonly auth: AuthService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    InitAnalytics(this.router)

    this.translate.addLangs(['nl', 'en']);
    this.setLanguage();
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe(value => {
      this.document.documentElement.lang = this.translate.currentLang;
      this.translate.use(value.lang);
      this.translate.reloadLang(value.lang);
    });
  }

  ngOnDestroy() {}

  setLanguage() {
    if (!localStorage.getItem(LANG_KEY)) {
      this.translate.use('nl');
      return localStorage.setItem(LANG_KEY, 'nl');
    }
    return this.translate.use(localStorage.getItem(LANG_KEY));
  }
}

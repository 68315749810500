import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { ProjectTypeCode } from '../../models/api/ProjectType';
import { Project } from '../../models/Project';

@Component({
  selector: 'app-fab-button',
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FabButtonComponent {
  @Input() project: Project;
  @Input() projectType: ProjectTypeCode;
  @Output() comparisonTrigger: EventEmitter<boolean> = new EventEmitter();
  @Output() addRoomTrigger: EventEmitter<boolean> = new EventEmitter();

  toggleComparison() {
    this.comparisonTrigger.emit();
  }

  addRoom() {
    this.addRoomTrigger.emit();
  }
}

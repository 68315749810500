import { Product } from '../models/api/Product';
import { Area } from '../models/Area';
import { AreaSurface } from '../models/AreaSurface';
import { Project } from '../models/Project';
import { SurfaceCustomerDemand } from '../models/SurfaceCustomerDemand';
import { expandDataIndex, expandDataPoints } from './extendDataPoints';
import { round } from './locale-number';
import { MaintainanceCycle } from './MaintainanceCycle';

// Any value that is not between 0 and 10 is valid
// We use 11 because that makes it so TEN_PLUS_YEARS > 10 years
export const TEN_PLUS_YEARS = 11;

export class CalculationsProcessor {
  constructor(private readonly project: Project) { }

  costGraph(result: ISurfaceCalculation[][]): SummaryChart {
    return summaryGraph(result, x => x.cost);
  }

  co2eGraph(result: ISurfaceCalculation[][]): SummaryChart {
    return summaryGraph(result, x => x.co2e);
  }

  calculate(): Promise<ISurfaceCalculation[][]> {
    return this.calculateOptions().then(areas => {
      return areas.map(surfaces => {
        return surfaces.map(options => {
          return {
            options,
            maintainance: this.calculateSurfaceLifecycle(options),
          };
        });
      });
    });
  }

  /**
   * Returns an multi-dimensional array such as
   * - surfaces[]
   *   - products[]
   *     - customer demands[]
   *       - <result of this.calculateCustomerDemand()>
   */
  private calculateOptions() {
    return this.fetchProducts().then(() => {
      return this.forEachSurface((surface, area) => {
        const factor = this.getFactor(area, surface);

        return surface.products.map(product => {
          return surface.customerDemands.map(customerDemand => {
            return this.calculateCustomerDemand(
              surface,
              product,
              customerDemand,
              factor,
            );
          });
        });
      });
    });
  }

  /**
   * Returns **THE DATA** required for a given combination of
   * Surface, Product and CustomerDemand
   */
  private calculateCustomerDemand(
    areaSurface: AreaSurface,
    product: Product,
    demand: SurfaceCustomerDemand,
    baseFactor: number,
  ): ICustomerDemandCalculation {
    const model = demand.model;
    const data = product.dataPoints[model.guid];

    if (!data) {
      console.warn(`No data found for product ${product.name}`);
      return null;
    }

    // Hardcoded GUID from Database 😱
    // For any future developers trying to figure out why colour rating isn't being applied, check this next line first.
    // Label might be translated so that is a no go. Also GUID may change between databases, or may not. So be mindful of 
    // that too.
    const isDiscolourationCustomerDemand = (model.guid === '5f48d717-c3f4-44ad-b345-138eceb6f728');

    const colourFactor = isDiscolourationCustomerDemand ? areaSurface.colour.multiplier : 1;

    const recommended = product.recommendedValues[model.guid];
    const factor = baseFactor * colourFactor;
    const minimum = factorize(data.minimum, factor);
    const expected = factorize(data.expected, factor);
    const units = !demand.enabled && recommended ? recommended : demand.units;

    return {
      areaSurface,
      product,
      customerDemand: demand,
      factor,
      recommended,
      minimum: expandDataPoints(minimum),
      expected: expandDataPoints(expected),
      minimumRedecoration: calculateRedecorationCycle(minimum, units),
      redecoration: calculateRedecorationCycle(expected, units),
    };
  }

  /**
   * `options` is a two-dimensional array of the objects returned by
   * `this.calculateCustomerDemand()` (should be previous function)
   */
  private calculateSurfaceLifecycle(
    options: ICustomerDemandCalculation[][],
  ): ISurfaceOptionCalculation[] {
    if (!options.length) {
      return null;
    }

    if (!this.project.usesRecommendedValue) {
      const enabled = options[0].filter(x => x && x.customerDemand.enabled);

      if (!enabled.length) {
        return null;
      }
    }

    return options.map(option => this.calculateOptionLifecycle(option));
  }

  /**
   * `options` is am array of the objects returned by
   * `this.calculateCustomerDemand()`
   */
  private calculateOptionLifecycle(
    option: ICustomerDemandCalculation[],
  ): ISurfaceOptionCalculation {
    const { lifeCycle, labourCost } = this.project;

    const enabled = option
      .filter(x => x.customerDemand.enabled)
      .filter(x => x.redecoration !== -1)
      .sort((a, b) => a.redecoration - b.redecoration);

    const base =
      // If none is enabled we use recommended value
      !enabled.length
        ? option.find(x => Boolean(x.recommended))
        : enabled[0];

    if (!base) {
      return null;
    }

    const { areaSurface, product, minimumRedecoration, redecoration } = base;
    const { areaSize, isRmi } = areaSurface;
    const paintSystem = areaSurface.getPaintSystemFor(product);
    const expand = expandDataIndex;

    const minimumDuration = minimumRedecoration === TEN_PLUS_YEARS ? 10 : minimumRedecoration;
    const duration = redecoration === TEN_PLUS_YEARS ? 10 : redecoration;

    const minimumCycle = base.minimum.slice(0, expand(minimumDuration));
    const expectedCycle = base.expected.slice(0, expand(duration));
    const cycles = Math.floor(lifeCycle / duration);

    const initial = new MaintainanceCycle({
      labour: paintSystem.getLabourCostFor(areaSize, labourCost, isRmi),
      system: paintSystem.getCostFor(areaSize, isRmi),
      co2e: paintSystem.getCo2For(areaSize, isRmi),
    });

    const recurrent = new MaintainanceCycle({
      labour: paintSystem.getLabourCostFor(areaSize, labourCost, true),
      system: paintSystem.getCostFor(areaSize, true),
      co2e: paintSystem.getCo2For(areaSize, true),
    });

    if (duration === 0 || cycles === 0) {
      return null;
    }

    return {
      basedOn: base,
      cost: accumulate(lifeCycle, duration, initial.total, recurrent.total),
      co2e: accumulate(lifeCycle, duration, initial.co2e, recurrent.co2e),
      initial,
      lifecycle: initial.add(recurrent.multiply(cycles)),
      minimum: repeat(minimumCycle, expandDataIndex(lifeCycle) + 1),
      expected: repeat(expectedCycle, expandDataIndex(lifeCycle) + 1),
    };
  }

  private fetchProducts() {
    const products: Product[] = [];
    this.forEachSurface(x => products.push(...x.products));
    return Promise.all(products.map(x => x.fetchDataPoints()));
  }

  private forEachSurface<T>(
    iterator: (surface: AreaSurface, area: Area) => T,
  ): T[][] {
    return this.project.areas.map(area => {
      return area.surfaces.map(surface => {
        return iterator(surface, area);
      });
    });
  }

  private getFactor(area: Area, areaSurface: AreaSurface) {
    return area.envFactors
      .concat(areaSurface.envFactors)
      .filter(Boolean)
      .map(x => x.multiplier)
      .reduce((result, factor) => result * factor, 1);
  }
}

export interface ICustomerDemandCalculation {
  areaSurface: AreaSurface;
  product: Product;
  customerDemand: SurfaceCustomerDemand;
  factor: number;
  recommended: number;
  minimum: number[];
  expected: number[];
  redecoration: number;
  minimumRedecoration: number;
}

export interface ISurfaceCalculation {
  options: ICustomerDemandCalculation[][];
  maintainance: ISurfaceOptionCalculation[];
}

export interface ISurfaceOptionCalculation {
  basedOn: ICustomerDemandCalculation;
  cost: number[];
  co2e: number[];
  initial: MaintainanceCycle;
  lifecycle: MaintainanceCycle;
  minimum: number[];
  expected: number[];
}

export interface SummaryChart {
  linear: number[][];
  stepped: number[][];
}

function factorize(list: number[], factor: number): number[] {
  if (!list.length) {
    return [];
  }

  let prev = list[0];
  const result = [prev];
  const last = list[list.length - 1];

  for (let i = 1; i < list.length; i++) {
    const sourceYear = i * factor;
    const decimals = sourceYear % 1;

    if (decimals === 0) {
      const data = list[sourceYear];
      prev = data == null ? last : data;
    } else {
      const data1 = list[Math.floor(sourceYear)];
      const data2 = list[Math.ceil(sourceYear)];

      prev =
        data1 == null || data2 == null
          ? last
          : data1 + (data2 - data1) * decimals;
    }

    result.push(prev);
  }

  return result;
}

function repeat(list: number[], duration: number) {
  return Array(duration)
    .fill(0)
    .map((_, i) => list[i % list.length]);
}

function accumulate(
  duration: number,
  cycle: number,
  initial: number,
  recurrent: number,
) {
  const result: number[] = [];
  let accumulated = initial;

  for (let i = 0; i < duration; i++) {
    if (i && i % cycle === 0) {
      accumulated += recurrent;
    }

    result.push(accumulated);
  }

  return result;
}

function summaryGraph(
  result: ISurfaceCalculation[][],
  getter: (option: ISurfaceOptionCalculation) => number[],
): SummaryChart {
  const stepped = sumCharts(result, getter);

  return {
    linear: linearChart(stepped),
    stepped,
  };
}

function sumCharts(
  data: ISurfaceCalculation[][],
  getter: (option: ISurfaceOptionCalculation) => number[],
) {
  const result: number[][] = [];

  data.forEach(area => {
    area.forEach(surface => {
      surface.maintainance.forEach((option, index) => {
        const entry = getter(option);
        const list = result[index];
        result[index] = list ? list.map((x, i) => x + entry[i]) : entry;
      });
    });
  });

  return result;
}

function linearChart(data: number[][]) {
  return data.map(x => {
    const first = x[0];
    const last = x[x.length - 1];
    const step = (last - first) / x.length;
    return x.map((_, i) => first + step * i);
  });
}

function calculateRedecorationCycle(base: number[], units: number) {
  if (units == null) {
    return null;
  }

  const first = base[0];
  const limit = round(units);
  const rounded = base.map(round);
  const match = rounded.findIndex(x => x === limit);
  const scaleDown = (x: number) => Math.floor(x / ((base.length - 1) / 10));
  const ensureNotZero = (x: number) => (x === 0 ? 1 : x);

  if (match === 0) {
    return TEN_PLUS_YEARS;
  }

  if (match !== -1) {
    return ensureNotZero(scaleDown(match));
  }

  const comparer =
    first < limit
      ? (x: number) => round(x) > limit
      : (x: number) => round(x) < limit;

  const redecoration = base.findIndex(comparer);

  if (redecoration === -1) {
    return TEN_PLUS_YEARS;
  }

  if (redecoration === 1) {
    return 1;
  }

  return ensureNotZero(scaleDown(redecoration - 1));
}

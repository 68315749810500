import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { LogoutComponent } from '../components/logout/logout.component';
import { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import { ISession } from '../models/Session';
import { User } from '../models/api/User';
import { CreateUserRequest } from '../models/requests/CreateUserRequest';
import { UpdateUserRequest } from '../models/requests/UpdateUserRequest';
import { PasswordResetConfirmationComponent } from '../components/password-reset-confirmation/password-reset-confirmation.component';
import { sendAnalyticsEvent, sendAnalyticsEventWithoutParameter } from '../utils/analytics';

const TOKEN_STORE_KEY = 'auth';
const HTTP_UNAUTHORIZED = 401;
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private session: ISession;
  private hasTokenBeenLoaded = false;
  private baseUrl = environment.baseUrl;

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly dialog: MatDialog,
  ) {
    this.loadToken();
  }

  get isAuthenticated() {
    return Boolean(this.session);
  }

  get isAdmin() {
    return this.session.isAdmin;
  }

  get userInfo() {
    return this.session;
  }

  get email() {
    return this.session && this.session.email;
  }

  get name() {
    return this.session && this.session.name;
  }

  get userPhone() {
    return this.session && this.session.phone;
  }

  loadToken() {
    if (this.hasTokenBeenLoaded) {
      return Promise.resolve();
    }

    const json = localStorage.getItem(TOKEN_STORE_KEY);
    if (!json) {
      return Promise.resolve(null);
    }

    const session = JSON.parse(json);
    this.session = session;
    this.hasTokenBeenLoaded = true;
    return Promise.resolve();
  }

  private saveToken(session: ISession) {
    localStorage.setItem(TOKEN_STORE_KEY, JSON.stringify(session));
    this.session = session;
    this.hasTokenBeenLoaded = true;
  }

  private deleteToken() {
    localStorage.removeItem(TOKEN_STORE_KEY);
    this.session = null;
  }

  clearSession() {
    this.deleteToken();
  }

  login({ email, password }: { email: string; password: string }) {
    return this.http
      .post<ISession>(`${this.baseUrl}/auth/login`, {
        email,
        password,
      })
      .toPromise()
      .then((session: ISession) => {
        this.saveToken(session);
        return session;
      });
  }

  get validateIsAdmin() {
    return this.http
      .get<ISession>(`${this.baseUrl}/auth/user`)
      .toPromise()
      .then((session: ISession) => {
        this.saveToken(session);
        return session.isAdmin;
      })
      .catch(response => {
        if (response.status === HTTP_UNAUTHORIZED) {
          this.deleteToken();
        }

        return false;
      });
  }

  sendPasswordResetMail(language: string) {
    const dialog = this.dialog.open(PasswordResetConfirmationComponent, {
      panelClass: 'cdk-overlay-pane--medium',
      autoFocus: false,
    });

    dialog.afterClosed().subscribe(value => {
      if (value) {
        this.requestRecoverPassword(this.email, language);
      }
    });
  }

  logoutUser() {
    const dialog = this.dialog.open(LogoutComponent, {
      panelClass: 'cdk-overlay-pane--small',
      autoFocus: false,
    });

    dialog.afterClosed().subscribe(value => {
      if (value) {
        this.logout();
      }
    });
  }

  private logout() {
    return this.http
      .post(`${this.baseUrl}/auth/logout`, null)
      .toPromise()
      .then(() => this.afterLogout());
  }

  private afterLogout() {
    this.deleteToken();
    this.router.navigateByUrl('/login');
  }

  resetPassword(password: string) {
    return this.http
      .post(
        `${this.baseUrl}/auth/reset-password`,
        { password },
        { observe: 'response' },
      )
      .toPromise();
  }

  requestRecoverPassword(email: string, language: string) {
    sendAnalyticsEventWithoutParameter(
      'Requested password recover'
    );
    return this.http
      .post(`${this.baseUrl}/auth/recover/email`, { email, language })
      .toPromise();
  }

  createUser(model: CreateUserRequest) {
    return this.http
      .post<Response>(`${this.baseUrl}/auth/create`, model)
      .toPromise();
  }

  updateUser(model: UpdateUserRequest) {
    return this.http
      .put<Response>(`${this.baseUrl}/auth/update`, model)
      .toPromise();
  }

  getUsers() {
    return this.http
      .get<User[]>(`${this.baseUrl}/auth/users`)
      .toPromise()
      .then(x => x.map(User.fromJson));
  }

  validateResetToken(model: ResetPasswordRequest): Promise<Response> {
    const request = { token: model.token };
    return this.http
      .post<Response>(`${this.baseUrl}/auth/recover/validate`, request)
      .toPromise();
  }

  recoverPassword(model: ResetPasswordRequest): Promise<Response> {
    return this.http
      .post<Response>(`${this.baseUrl}/auth/recover/reset`, model)
      .toPromise();
  }

  checkSession() {
    return this.http
      .get<ISession>(`${this.baseUrl}/auth/user`)
      .toPromise()
      .then((session: ISession) => {
        this.saveToken(session);
      })
      .catch(response => {
        if (response.status === HTTP_UNAUTHORIZED) {
          this.deleteToken();
        }
      });
  }
}

interface Paginated<T> {
  items: T[];
  total: number;
}

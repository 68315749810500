import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { getState } from 'src/app/helpers/functions';
import { Project } from 'src/app/models/Project';
import { ProjectDetails } from 'src/app/models/ProjectDetails';
import { StoreService } from 'src/app/services/store-service.service';
import { sendAnalyticsEvent } from 'src/app/utils/analytics';

enum UploadState {
  tooLarge = 'tooLarge',
  default = 'default',
}

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('uploadInput') uploadInput: ElementRef;

  project: Project;
  shouldGoBack: boolean;

  uploadStatus: UploadState = UploadState.default;

  constructor(
    private readonly router: Router,
    private readonly store: StoreService,
    private readonly location: Location,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    const state = getState(router);
    this.shouldGoBack = Boolean((state && state.shouldGoBack) || false);
  }

  projectForm = new FormGroup({
    address1: new FormControl(''),
    city: new FormControl(''),
    county: new FormControl(''),
    postalCode: new FormControl(''),
    country: new FormControl(''),
    companyName: new FormControl(''),
    specifierName: new FormControl(''),
    salesPersonName: new FormControl(''),
  });

  ngOnInit() {
    this.project = this.store.project.clone();
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  cancelEdit() {
    this.location.back();
  }

  continue() {
    this.store.project = this.project;
    sendAnalyticsEvent('Project Details Saved','');
    if (this.shouldGoBack) {
      return this.location.back();
    }
    return this.router.navigate(['project/share']);
  }

  skip() {
    sendAnalyticsEvent('Project Details Skipped','');
    return this.router.navigate(['project/share']);
  }

  uploadImage(event) {
    this.uploadStatus = UploadState.default;
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      const imageSize = (event.target.files[0].size as number) / 1024 / 1024; // MiB conversion

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (url: any) => {
        // called once readAsDataURL is completed
        const image = new Image();
        image.src = url.target.result;

        image.onload = () => {
          if (imageSize > 1) {
            this.uploadStatus = UploadState.tooLarge;
            return;
          }
          this.project.details.companyInfo.image = url.target.result;
          this.uploadInput.nativeElement.value = null;
        };
      };
    }
  }

  removeImage(ev) {
    this.project.details.companyInfo.image = null;
    this.uploadStatus = UploadState.default;
    ev.preventDefault();
  }
}

import { CachedAsyncProperty } from '../../helpers/CachedAsyncProperty';
import { ApiService } from '../../services/api.service';

export class Product implements IProductJson {
  static fromJson(api: ApiService, json: IProductJson) {
    const product = new Product(json);
    product.internalData.setApi(api);
    return product;
  }

  readonly guid: string;
  readonly name: string;
  image: string;
  readonly pricePerLitre: number;
  readonly co2PerLitre: number;
  readonly spreadingRatePerLitre: number;
  readonly paintSpeed: number;
  readonly standardRedecorationCycle: number;
  readonly isComplete: boolean;
  readonly isInLayers: boolean;

  private internalData = new CachedAsyncProperty(api =>
    api.getProductInternalData(this.guid),
  );

  get dataPoints() {
    const data = this.internalData.get();
    return data && data.dataPoints;
  }

  get recommendedValues() {
    const data = this.internalData.get();
    return data && data.recommended;
  }

  get costPerSquareMeter() {
    return this.pricePerLitre / this.spreadingRatePerLitre;
  }

  get co2PerSquareMeter() {
    return this.co2PerLitre / this.spreadingRatePerLitre;
  }

  private constructor(json: IProductJson) {
    Object.assign(this, json);
  }

  getCostFor(area: number) {
    return this.costPerSquareMeter * area;
  }

  getCo2For(area: number) {
    return this.co2PerSquareMeter * area;
  }

  getLitresFor(area: number) {
    return area / this.spreadingRatePerLitre;
  }

  fetchDataPoints() {
    return this.internalData.fetch().then(x => x.dataPoints);
  }

  resetDataPoints() {
    this.internalData.reset();
  }

  toJSON() {
    const {
      guid,
      name,
      image,
      pricePerLitre,
      co2PerLitre,
      spreadingRatePerLitre,
      paintSpeed,
      standardRedecorationCycle,
    } = this;

    return {
      guid,
      name,
      image,
      pricePerLitre,
      co2PerLitre,
      spreadingRatePerLitre,
      paintSpeed,
      standardRedecorationCycle,
    };
  }
}

export interface IProductJson {
  guid: string;
  name: string;
  image: string;
  pricePerLitre: number;
  co2PerLitre: number;
  spreadingRatePerLitre: number;
  paintSpeed: number;
}

export interface IProductInternalData {
  dataPoints: IProductDataPointsJson;
  recommended: IProductRecommendedValuesJson;
}

export interface IProductDataPointsJson {
  [customerDemandGuid: string]: IDataPointsJson;
}

export interface IProductRecommendedValuesJson {
  [customerDemandGuid: string]: number;
}

export interface IDataPointsJson {
  minimum: number[];
  expected: number[];
}

export class ProjectDetails {
  static fromJson(json: IProjectDetailsJson) {
    return new ProjectDetails({
      ...json,
      info: (json && json.info) || {
        address1: '',
        city: '',
        country: 'Netherlands',
        county: '',
        postalCode: '',
      },
      companyInfo: (json && json.companyInfo) || {
        address1: '',
        city: '',
        country: 'Netherlands',
        county: '',
        postalCode: '',
        image: null,
        name: '',
        specifierName: '',
      },
      salesPerson: (json && json.salesPerson) || {
        email: '',
        name: '',
        phone: '',
      },
    });
  }

  info: IProjectInfo;
  companyInfo: ICompanyInfo;
  salesPerson: ISalesPersonInfo;

  constructor(json?: IProjectDetailsJson) {
    if (json) {
      Object.assign(this, json);
    }
  }
}

export interface IProjectDetailsJson {
  info: IProjectInfo;
  companyInfo?: ICompanyInfo;
  salesPerson: ISalesPersonInfo;
}

export interface IProjectInfo {
  address1: string;
  city: string;
  county: string;
  postalCode: string;
  country: string;
}

export interface ICompanyInfo {
  image: string | ArrayBuffer;
  address1: string;
  name: string;
  city: string;
  county: string;
  postalCode: string;
  country: string;
  specifierName: string;
}

export interface ISalesPersonInfo {
  name: string;
  phone: string;
  email: string;
}

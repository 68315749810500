import { CustomerDemand, ICustomerDemandJson } from './api/CustomerDemand';

export class SurfaceCustomerDemand implements ISurfaceCustomerDemand {
  static fromJson(json: ISurfaceCustomerDemandJson) {
    return new SurfaceCustomerDemand({
      ...json,
      model: CustomerDemand.fromJson(json.model),
    });
  }

  model: CustomerDemand;
  enabled: boolean;
  units: number;

  constructor(json?: ISurfaceCustomerDemand) {
    if (json) {
      Object.assign(this, json);
    }
  }
}

interface ISurfaceCustomerDemand extends ISurfaceCustomerDemandJson {
  model: CustomerDemand;
  enabled: boolean;
  units: number;
}

export interface ISurfaceCustomerDemandJson {
  model: ICustomerDemandJson;
  enabled: boolean;
  units: number;
}

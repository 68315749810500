import { Color as ColorHelper } from '../../helpers/Color';

export class Colour implements IColourJson {
  static fromJson(json: IColourJson) {
    return new Colour(json);
  }

  readonly guid: string;
  readonly code: string;
  readonly hex: string;
  readonly value: string;
  readonly starRating: number;
  readonly multiplier: number;
  readonly isLight: boolean;
  readonly isDark: boolean;

  private constructor(json: IColourJson) {
    Object.assign(this, json);

    this.value = `#${this.hex}`;
    const helper = ColorHelper.fromHex(this.value);
    this.isLight = helper.isLight;
    this.isDark = helper.isDark;
  }
}

export interface IColourJson {
  guid: string;
  code: string;
  hex: string;
  starRating: number;
  multiplier: number;
}

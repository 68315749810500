import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StoreService } from 'src/app/services/store-service.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, AfterViewInit {
  @ViewChild('overview') overview: ElementRef;
  @ViewChild('features') features: ElementRef;
  @ViewChild('aboutus') aboutus: ElementRef;

  inView: string;

  constructor(
    private readonly router: Router,
    private readonly store: StoreService,
    private readonly route: ActivatedRoute,
    private readonly cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    document.body.classList.add('landing-page');
  }

  ngAfterViewInit() {
    this.route.fragment.subscribe(fragment => {
      if (!fragment) {
        this.inView = '';
      }
    });
    this.cdRef.detectChanges();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (this.features) {
      const overviewEl = this.overview.nativeElement.getBoundingClientRect();
      const featuresEl = this.features.nativeElement.getBoundingClientRect();
      const aboutUsEl = this.aboutus.nativeElement.getBoundingClientRect();

      if (overviewEl.top < 200 && overviewEl.top > -300) {
        this.inView = 'overview';
        return;
      }

      if (featuresEl.top < 200 && featuresEl.top > -1000) {
        this.inView = 'features';
        return;
      }
      if (aboutUsEl.top < 350) {
        this.inView = 'aboutus';
        return;
      }

      if (aboutUsEl.top < 350) {
        this.inView = 'aboutus';
        return;
      }

      this.inView = '';
    }
  }

  getStarted() {
    this.store.clearStorage();
    this.router.navigate(['my-projects']);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getUpperFirstLetter',
})
export class GetUpperFirstLetter implements PipeTransform {
  // tslint:disable-next-line: ban-types
  transform(value: string) {
    if (!value) {
      return '';
    }
    return Array.from(value)[0].toUpperCase();
  }
}

import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { getState } from 'src/app/helpers/functions';
import { PaintSystem } from 'src/app/models/api/PaintSystem';
import { Location } from '@angular/common';
import { INameGuidModel } from 'src/app/models/flow/flowInterfaces';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IPaintLayerJson } from 'src/app/models/api/PaintLayer';
import { Product } from 'src/app/models/api/Product';
import {
  PaintSystemCheckRequest,
  PaintSystemEditRequest,
  PaintSystemLayerEditRequest,
} from 'src/app/models/requests/PaintSystemRequests';
import { AdminService } from '../../services/admin.service';
import { Surface } from 'src/app/models/api/Surface';

@Component({
  selector: 'app-paint-system-details',
  templateUrl: './paint-system-details.component.html',
  styleUrls: ['./paint-system-details.component.scss'],
})
export class PaintSystemDetailsComponent implements OnInit {
  @ViewChild('editSystemDialog') editSystemDialog: TemplateRef<MatDialog>;
  @ViewChild('deleteSystemDialog') deleteSystemDialog: TemplateRef<MatDialog>;
  @ViewChild('deleteSystemLayerDialog') deleteSystemLayerDialog: TemplateRef<
    MatDialog
  >;
  @ViewChild('addLayerDialog') addLayerDialog: TemplateRef<MatDialog>;
  @ViewChild('editLayerDialog') editLayerDialog: TemplateRef<MatDialog>;

  paintSystem: PaintSystem;
  surfacesList: INameGuidModel[];
  productList: INameGuidModel[];

  layersData: MatTableDataSource<IPaintLayerJson>;
  paintSystemTimer: any;

  editPaintSystemForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    reference: new FormControl('', Validators.required),
    surfaceTypes: new FormControl(''),
  });

  addLayerForm: FormGroup = new FormGroup({
    product: new FormControl('', Validators.required),
    layerType: new FormControl('', Validators.required),
    coats: new FormControl(''),
  });

  displayedColumns = ['name', 'type', 'coats', 'actions'];

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly api: ApiService,
    private readonly dialog: MatDialog,
    private readonly cdRef: ChangeDetectorRef,
    private readonly adminService: AdminService,
  ) {
    const state = getState(router) as PaintSystem;
    if (!state) {
      this.router.navigate(['/admin/paint-systems']);
      return;
    }
    this.initSystem(state);
  }

  ngOnInit() {
    this.cdRef.detectChanges();
  }

  back() {
    this.location.back();
  }

  initSystem(system: PaintSystem) {
    this.api.clearCache();
    this.api.getPaintSystem(system.guid).then(value => {
      this.paintSystem = value;
      this.layersData = new MatTableDataSource(value.layers);
      this.api
        .getAllSurfaces()
        .then(surfaces => (this.surfacesList = surfaces));
    });
  }

  getProductList(): Promise<INameGuidModel[]> {
    if (this.productList) {
      return Promise.resolve(this.productList);
    }
    return this.api.getLiteProducts().then(res => (this.productList = res));
  }

  editSystem() {
    const ref = this.dialog.open(this.editSystemDialog, {
      data: { ...this.paintSystem },
    });

    ref.afterClosed().subscribe((value: PaintSystem) => {
      if (value) {
        const model: PaintSystemEditRequest = {
          name: value.name,
          reference: value.reference,
          surfaceGuid: value.surface.guid,
          systemGuid: this.paintSystem.guid,
        };
        this.api.editPaintSystem(model).then(res => {
          this.adminService.reloadSidebar.next(true);
          this.initSystem(this.paintSystem);
        });
      }
    });
  }

  deletePaintSystem() {
    const ref = this.dialog.open(this.deleteSystemDialog);

    ref.afterClosed().subscribe(value => {
      if (value) {
        this.api.deletePaintSystem(this.paintSystem.guid).then(res => {
          this.api.clearCache();
          this.adminService.reloadSidebar.next(true);
          this.router.navigate(['/admin/paint-systems']);
        });
      }
    });
  }

  checkPaintSystem() {
    clearTimeout(this.paintSystemTimer);        
    this.editPaintSystemForm.get('name').updateValueAndValidity();
    this.editPaintSystemForm.get('reference').updateValueAndValidity();
    this.paintSystemTimer = setTimeout(() => {
      const model: PaintSystemCheckRequest = {
          name: this.editPaintSystemForm.get('name').value,
          reference: this.editPaintSystemForm.get('reference').value,
      };
      this.api
        .checkPaintSystem(model, this.paintSystem.guid)
        .then()
        .catch(err => {
          if (err.status == 409) {
            this.editPaintSystemForm.get('name').setErrors({ conflict: true });            
            this.editPaintSystemForm.get('reference').setErrors({ conflict: true });
          }
        });
    }, 300);
  }

  addLayer() {
    this.addLayerForm.reset();
    this.getProductList().then(value => {
      this.productList = value;
      const ref = this.dialog.open(this.addLayerDialog);

      ref.afterClosed().subscribe(model => {
        if (model) {
          const newLayer: PaintSystemLayerEditRequest = {
            name: this.addLayerForm.get('layerType').value,
            numberOfCoats: this.addLayerForm.get('coats').value,
            productGuid: (this.addLayerForm.get('product')
              .value as INameGuidModel).guid,
          };

          this.api.addSystemLayer(this.paintSystem.guid, newLayer).then(res => {
            this.adminService.reloadSidebar.next(true);
            this.initSystem(this.paintSystem);
          });
        }
      });
    });
  }

  editLayer(layer: IPaintLayerJson) {
    this.getProductList().then(_ => {
      const ref = this.dialog.open(this.editLayerDialog, {
        data: { ...layer },
      });

      ref.afterClosed().subscribe((value: IPaintLayerJson) => {
        if (value) {
          const model: PaintSystemLayerEditRequest = {
            name: value.name,
            numberOfCoats: value.numberOfCoats,
            productGuid: value.product.guid,
          };
          this.api
            .editSystemLayer(this.paintSystem.guid, value.guid, model)
            .then(res => {
              this.adminService.reloadSidebar.next(true);
              this.initSystem(this.paintSystem);
            });
        }
      });
    });
  }

  deleteLayer(layer: IPaintLayerJson) {
    const ref = this.dialog.open(this.deleteSystemLayerDialog);

    ref.afterClosed().subscribe(value => {
      if (value) {
        this.api
          .deleteSystemLayer(this.paintSystem.guid, layer.guid)
          .then(res => {
            this.adminService.reloadSidebar.next(true);
            this.initSystem(this.paintSystem);
          });
      }
    });
  }

  compareModels(o1, o2) {
    if (o1 && o2) {
      return o1.name === o2.name;
    }
  }

  compareLayers(o1: string, o2: string) {
    if (o1 && o2) {
      return o1 === o2;
    }
  }
}

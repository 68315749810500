import { EnvFactor, IEnvFactorJson } from './EnvFactor';
import { ISubsectorJson, Subsector } from './Subsector';

export class Surface implements ISurface {
  static fromJson(json: ISurfaceJson) {
    return new Surface({
      ...json,
      defaultSizes:
        json &&
        json.defaultSizes.map(x => ({
          ...x,
          subsector: Subsector.fromJson(x.subsector),
        })),
    });
  }

  readonly guid: string;
  readonly name: string;
  readonly image: string;
  readonly envFactors: EnvFactor[];

  readonly defaultSizes: SurfaceDefaultSize[];

  private constructor(json: ISurface) {
    Object.assign(this, json);
  }

  getDefaultSizeFor(subsector: Subsector) {
    const value = this.defaultSizes.find(
      x => x.subsector.guid === subsector.guid,
    );
    return value && value.size;
  }
}

interface ISurface extends ISurfaceJson {
  readonly envFactors: EnvFactor[];
}

export interface ISurfaceJson {
  guid: string;
  name: string;
  image: string;
  defaultSizes: SurfaceDefaultSize[];
  envFactors: IEnvFactorJson[];
}

export interface SurfaceDefaultSize {
  subsector: ISubsectorJson;
  size: number;
}

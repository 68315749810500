import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getArrayFromEnum',
})
export class GetArrayFromEnumPipe implements PipeTransform {
  // tslint:disable-next-line: ban-types
  transform(data: Object) {
    return Object.values(data);
  }
}

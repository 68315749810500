import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
import { ForgotPasswordComponent } from 'src/app/components/forgot-password/forgot-password.component';
import { MatDialog } from '@angular/material';
import { emailPattern } from 'src/app/helpers/patterns';
import { sendAnalyticsEvent, sendAnalyticsEventWithoutParameter } from 'src/app/utils/analytics';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  userEmail: FormControl;
  userPassword: FormControl;
  loginFailed: boolean;
  hide = true;
  redirect: { redirect: string };

  constructor(
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.createLoginForm();
  }

  createLoginForm() {
    this.userEmail = new FormControl('', [
      Validators.required,
      Validators.pattern(emailPattern),
    ]);
    this.userPassword = new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]);
  }

  login() {
    const { redirect } = this.activatedRoute.snapshot.queryParams as {
      redirect: string;
    };

    this.auth
      .login({
        email: this.userEmail.value,
        password: this.userPassword.value,
      })
      .then(user => {
        sendAnalyticsEventWithoutParameter(
          'User Login'
        );
        this.loginFailed = false;
        this.router.navigate([redirect || '/my-projects']);
      })
      .catch(error => {
        sendAnalyticsEventWithoutParameter('Login Failed');
        this.loginFailed = true;
      });
  }

  forgotPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      panelClass: 'cdk-overlay-pane--large',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe();
  }

  exitLogIn() {
    this.router.navigate(['']);
  }
}

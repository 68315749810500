import { Location } from '@angular/common';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { getState } from 'src/app/helpers/functions';
import { ProjectTypeCode } from 'src/app/models/api/ProjectType';
import { Sector } from 'src/app/models/api/Sector';
import { Subsector } from 'src/app/models/api/Subsector';
import { AddSectorRequest, AddSubSectorRequest } from 'src/app/models/requests/SectorRequests';
import { ApiService } from 'src/app/services/api.service';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-sector-details',
  templateUrl: './sector-details.component.html',
  styleUrls: ['./sector-details.component.scss'],
})
export class SectorDetailsComponent {
  @ViewChild('deleteDialog') deleteDialog: TemplateRef<MatDialog>;
  @ViewChild('editDialog') editDialog: TemplateRef<MatDialog>;
  @ViewChild('addSubsectorDialog') addSubsectorDialog: TemplateRef<MatDialog>;
  @ViewChild('editSubsectorDialog') editSubsectorDialog: TemplateRef<MatDialog>;

  sector: Sector;
  type: ProjectTypeCode;
  editSectorControl: FormControl = new FormControl('', Validators.required);

  addSubsectorForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    unit: new FormControl('', Validators.required),
  });

  subsectorsData: MatTableDataSource<Subsector>;
  displayedColumns: string[] = ['image', 'name', 'unit', 'actions'];

  subsectorNameTimer: any;

  editSectorForm: FormGroup = new FormGroup({    
    name: new FormControl('', Validators.required),
  });

  updatedSubsectorId: string;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly dialog: MatDialog,
    private readonly api: ApiService,
    private readonly adminService: AdminService,
  ) {
    const state = getState(router);
    this.type = state && state.type;

    if (!(state && state.sector)) {
      this.router.navigate(['/admin/sectors']);
      return;
    }

    this.initSector(state.sector);
  }

  back() {
    this.location.back();
  }

  checkSector() {
    clearTimeout(this.subsectorNameTimer);        
    this.editSectorForm.get('name').updateValueAndValidity();        
    this.subsectorNameTimer = setTimeout(() => {
      const sectorModel: AddSectorRequest = {
        name: this.editSectorForm.get('name').value,
        projectTypeCode: this.type
      };
      this.api
        .checkSector(sectorModel, this.sector.guid)
        .then()
        .catch(err => {
          if (err.status == 409) {
            this.editSectorForm.get('name').setErrors({ conflict: true });
            
          }
        });
    }, 300);
  }

  editSector() {
    this.editSectorControl.reset();
    const ref = this.dialog.open(this.editDialog, {
      data: { ...this.sector },
    });

    ref.afterClosed().subscribe(model => {
      if (model) {
        const updateModel: AddSectorRequest = {
          projectTypeCode: this.type,
          name: model.name,
        };
        this.api.editSector(model.guid, updateModel).then(value => {
          this.api.getSector(model.guid).then(res => {
            this.adminService.reloadSidebar.next(true);
            this.initSector(res);
          });
        });
      }
    });
  }

  deleteSector() {
    const ref = this.dialog.open(this.deleteDialog);

    ref.afterClosed().subscribe(value => {
      if (value) {
        this.api.deleteSector(this.sector.guid).then(res => {
          this.api.clearCache();
          this.adminService.reloadSidebar.next(true);
          this.router.navigate(['/admin/sectors']);
        });
      }
    });
  }

  initSector(sector: Sector) {
    if (sector) {
      this.sector = sector;
    }
    this.subsectorsData = new MatTableDataSource(this.sector.subsectors);
  }

  checkSubsector() {
    clearTimeout(this.subsectorNameTimer);    
    this.addSubsectorForm.get('name').updateValueAndValidity();    
    this.addSubsectorForm.get('unit').updateValueAndValidity();

    this.subsectorNameTimer = setTimeout(() => {
      const subSectorModel: AddSubSectorRequest = {
        name: this.addSubsectorForm.get('name').value,
        unit: this.addSubsectorForm.get('unit').value || 'check',
      };
      this.api
        .checkSubsector(this.sector.guid, subSectorModel, this.updatedSubsectorId)
        .then()
        .catch(err => {
          if (err.status == 409) {
            this.addSubsectorForm.get('name').setErrors({ conflict: true });
            this.addSubsectorForm.get('unit').setErrors({ conflict: true });
          }
        });
    }, 300);
  }

  addSubsector() {
    this.addSubsectorForm.reset();
    const ref = this.dialog.open(this.addSubsectorDialog, {
      width: '600px'
    });
    this.updatedSubsectorId = null;
    ref.afterClosed().subscribe(value => {
      if (value) {
        const subSectorModel: AddSubSectorRequest = {
          name: this.addSubsectorForm.get('name').value,
          unit: this.addSubsectorForm.get('unit').value,
        };
        this.api.addSubsector(this.sector.guid, subSectorModel).then(res => {
          this.api.getSector(this.sector.guid).then(sector => {
            this.adminService.reloadSidebar.next(true);
            this.initSector(sector);
          });
        });
      }
    });
  }

  editSubsector(subsector: Subsector) {
    const ref = this.dialog.open(this.editSubsectorDialog, {
      data: { ...subsector },
    });
    this.updatedSubsectorId = subsector.guid;

    ref.afterClosed().subscribe((value: Subsector) => {
      if (value) {
        this.api.editSubsector(this.sector.guid, value).then(res => {
          this.api.getSector(this.sector.guid).then(sector => {
            this.adminService.reloadSidebar.next(true);
            this.initSector(sector);
          });
        });
      }
    });
  }

  deleteSubsector(subsector: Subsector) {
    const ref = this.dialog.open(this.deleteDialog, { data: subsector });

    ref.afterClosed().subscribe(value => {
      if (value) {
        this.api.deleteSubsector(this.sector.guid, subsector.guid).then(res => {
          this.api.getSector(this.sector.guid).then(sector => {
            this.adminService.reloadSidebar.next(true);
            this.initSector.bind(sector);
          });
        });
      }
    });
  }
}

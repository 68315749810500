export class Subsector implements ISubsectorJson {
  static fromJson(json: ISubsectorJson) {
    return new Subsector(json);
  }

  readonly guid: string;
  readonly name: string;
  readonly image: string;
  readonly unit: string;

  private constructor(json: ISubsectorJson) {
    Object.assign(this, json);
  }
}

export interface ISubsectorJson {
  guid: string;
  name: string;
  image: string;
  unit: string;
}

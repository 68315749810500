import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  HostListener,
  Input,
  OnInit,
  ChangeDetectorRef,
  AfterViewChecked,
} from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { MatSelectChange } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { sendAnalyticsEvent } from 'src/app/utils/analytics';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  @Input() inView: string;
  @Input() isLanding: boolean;
  @Input() hideButtons: boolean;
  step: number;
  isFixed = false;
  public href = '';
  dontShowButtons = false;

  constructor(
    private readonly translate: TranslateService,
    private readonly route: ActivatedRoute,
    readonly auth: AuthService,
    private router: Router,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    route.data.subscribe(data => {
      this.step = data.step;
    });
    

    
  }

  setLanguage(lang: MatSelectChange) {
    this.translate.use(lang.value);
    sendAnalyticsEvent(
      'Language Change',
      `Language: ${lang.value}`,
    );
    localStorage.setItem('lang', lang.value);
  }

  get currentLanguage() {
    return localStorage.getItem('lang') || this.translate.currentLang;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 96) {
      this.isFixed = true;
    } else {
      this.isFixed = false;
    }
  }

  ngOnInit() {
    if (this.router.url === '/') {
      this.isLanding = true;
    }
    this.dontShowButtons = this.hideButtons !== undefined ? this.hideButtons : false;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  resetPassword() {
    this.auth.sendPasswordResetMail(this.translate.currentLang);
  }

  logout() {
    this.auth.logoutUser();
  }
}

import { TranslateParser, TranslateService } from '@ngx-translate/core';

export function getRedecorationMapping(
  translate: TranslateService,
): TranslationMapping {
  return {
    '=-1': translate.instant('Insufficient data'),
    '=1': translate.instant('1 year'),
    '=11': translate.instant('# years').replace('#', '10+'),
    '=10+': translate.instant('# years').replace('#', '10+'),
    '=/': '/',
    other: translate.instant('# years'),
  };
}

export function singleBracketParamsHack(interpolation: TranslateParser) {
  const original = interpolation.interpolate;

  (interpolation as any).interpolate = function (
    // tslint:disable-next-line: ban-types
    expr: string | Function,
    params?: any,
  ) {
    const modifiedExpr =
      typeof expr === 'string'
        ? expr.replace(/\{/g, '{{').replace(/\}/g, '}}')
        : expr;

    return original.call(this, modifiedExpr, params);
  };
}

export interface TranslationMapping {
  [key: string]: string;
}

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthAdminGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.auth.isAuthenticated) {
      return this.auth.validateIsAdmin.then(isAdmin => {
        if (isAdmin) {
          return isAdmin;
        }
        this.auth.clearSession();
        this.router.navigate(['/']);
        return false;
      });
    }

    return this.router.navigate(['login'], {
      queryParams: { redirect: state.url },
    });
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.auth.isAuthenticated) {
      return this.auth.validateIsAdmin.then(isAdmin => {
        if (isAdmin) {
          return isAdmin;
        }
        this.auth.clearSession();
        this.router.navigate(['/']);
        return false;
      });
    }

    return this.router.navigate(['login'], {
      queryParams: { redirect: state.url },
    });
  }
}

import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { formatDate } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  CalculationsProcessor,
  ICustomerDemandCalculation,
} from '../../helpers/CalculationsProcessor';
import { getDashing } from '../../helpers/chart.config';
import { LocaleFormatter } from '../../helpers/Formatter';
import { round } from '../../helpers/locale-number';
import {
  getRedecorationMapping,
  TranslationMapping,
} from '../../helpers/translations';
import { CustomerDemand } from '../../models/api/CustomerDemand';
import { Product } from '../../models/api/Product';
import { AreaSurface } from '../../models/AreaSurface';
import { Project } from '../../models/Project';
import { ShareReportRequest } from '../../models/requests/ShareReport';
import { ApiService } from '../../services/api.service';
import { StoreService } from '../../services/store-service.service';
import { sendAnalyticsEvent } from 'src/app/utils/analytics';

const DEFAULT_PRODUCT_IMAGE = 'assets/icons/admin/generic-product.png';

@Component({
  selector: 'app-project-preview',
  templateUrl: './project-preview.component.html',
  styleUrls: ['./project-preview.component.scss'],
})
export class ProjectPreviewComponent implements OnInit, AfterContentChecked {
  @Input() downloadTrigger: BehaviorSubject<boolean>;
  @Input() sendReportTrigger: ReplaySubject<ShareReportRequest>;
  @Output() isGenerating: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild('pdfContainer') pdfContainer: ElementRef;
  calculationChanged: BehaviorSubject<boolean> = new BehaviorSubject(true);
  project: Project;
  src = '';
  doc;
  options = [];
  isLoading = false;
  generatingPage: number;
  formatter: LocaleFormatter;
  round = round;

  get dateNow() {
    return formatDate(new Date(), 'EEEE, MMM dd, yyyy', this.lang.currentLang);
  }

  get currentLang() {
    return localStorage.getItem('lang');
  }

  get total() {
    return this.project.getTotal;
  }

  get costOverLifeCycle() {
    const graphs = this.project.getCostChart();
    const productTr = this.translate.instant('Product');
    const costTr = this.translate.instant('cost');

    return (
      graphs &&
      graphs.stepped.map((cost, i) => ({
        data: cost,
        label: `${productTr} ${i + 1} ${costTr}`,
        steppedLine: true,
        lineTension: 0,
      }))
    );
  }

  get co2eOverLifecycle() {
    const graphs = this.project.getCo2Chart();
    const productTr = this.translate.instant('Product');
    const costTr = this.translate.instant('cost');

    return (
      graphs &&
      graphs.stepped.map((cost, i) => ({
        data: cost,
        label: `${productTr} ${i + 1} CO2e ${costTr}`,
        steppedLine: true,
        lineTension: 0,
      }))
    );
  }

  get redecorationMapping(): TranslationMapping {
    return getRedecorationMapping(this.translate);
  }

  constructor(
    private readonly lang: TranslateService,
    store: StoreService,
    private readonly api: ApiService,
    private readonly translate: TranslateService,
    private readonly elem: ElementRef,
    private readonly cdRef: ChangeDetectorRef,
    private readonly storeService: StoreService
  ) {
    this.project = store.project;

    this.project.setCalculationsProcessor(
      new CalculationsProcessor(this.project),
    );
  }

  ngOnInit() {
    this.formatter = new LocaleFormatter(this.translate);

    if (this.downloadTrigger) {
      this.downloadTrigger.subscribe(value => {
        if (value) {
          this.downloadPDF();
        }
      });

      this.project.resetCalculationsCache();
    }

    if (this.sendReportTrigger) {
      this.sendReportTrigger.subscribe((model: ShareReportRequest) => {
        this.isPDFGenerating(true);

        if (!this.doc) {
          this.getPDF().then(() => {
            const file = this.generatePdfBlob();
            sendAnalyticsEvent('PDF Report Shared', `Pages: ${this.totalPages}`);
            this.api.addProjectPdf(this.storeService.projectGuid, this.doc.output('blob'))
              .finally(() => {
                this.api
                  .sendReport(model, file)
                  .then(() => {
                    this.isPDFGenerating(false);
                  })
                  .catch(err => {
                    this.isLoading = false;
                  });
              });
          });
        } else {
          const file = this.generatePdfBlob();
          sendAnalyticsEvent('PDF Report Shared', `Pages: ${this.totalPages}`);
          this.api.addProjectPdf(this.storeService.projectGuid, this.doc.output('blob'))
            .finally(() => {
              this.api
                .sendReport(model, file)
                .then(() => {
                  this.isPDFGenerating(false);
                })
                .catch(err => {
                  this.isLoading = false;
                });
            });
        }
      });
    }

    this.options.length = this.project.isComparing ? 2 : 1;
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  get totalPages() {
    return (
      this.elem.nativeElement.querySelectorAll('div.pdf-report-page').length - 1
    );
  }

  isPDFGenerating(val: boolean) {
    this.isLoading = val;
    this.isGenerating.next(val);
  }

  calculations(surface: AreaSurface) {
    const value = this.surfaceCalculate(surface);
    return value && value.options;
  }

  surfaceMaintenance(surface: AreaSurface) {
    const calc = this.surfaceCalculate(surface);
    return calc && calc.maintainance;
  }

  surfaceCalculate(surface: AreaSurface) {
    return this.project.getSurfaceCalculations(surface);
  }

  isBasedOn(
    surface: AreaSurface,
    demand: ICustomerDemandCalculation,
    index: number,
  ) {
    const maintenance = this.surfaceMaintenance(surface)[index];

    return maintenance.basedOn.customerDemand.model.guid ===
      demand.customerDemand.model.guid
      ? true
      : false;
  }

  refreshPdf() {
    this.getPDF().then(() => (this.src = this.doc.output('datauristring')));
  }

  downloadPDF() {
    this.isPDFGenerating(true);
    if (!this.doc) {
      this.getPDF().then(() => {
        this.doc.save(`${encodeURIComponent(this.project.name)}.pdf`);
        this.api.addProjectPdf(this.storeService.projectGuid, this.doc.output('blob'))
          .finally(() => {
            this.isPDFGenerating(false);
            sendAnalyticsEvent('PDF Report Downloaded', `Pages: ${this.totalPages}`);
          });
      });
    } else {
      this.doc.save(`${encodeURIComponent(this.project.name)}.pdf`);
      this.api.addProjectPdf(this.storeService.projectGuid, this.doc.output('blob'))
        .finally(() => {
          this.isPDFGenerating(false);
          sendAnalyticsEvent('PDF Report Downloaded', `Pages: ${this.totalPages}`);
        });
    }
  }

  generatePdfBlob(): Blob {
    if (!this.doc) {
      this.getPDF().then(() => {
        this.doc.output('blob');
      });
    } else {
      return this.doc.output('blob');
    }
  }

  generateChartData(calculation: ICustomerDemandCalculation) {
    const demand = calculation.customerDemand;

    const units =
      demand.units || calculation.recommended || demand.model.minimumValue;

    const chartData = [
      {
        data: calculation.minimum,
        label: this.translate.instant('Minimum'),
        borderDash: getDashing(),
      },
      {
        data: calculation.expected,
        label: this.translate.instant('Expected'),
      },
    ];

    if (demand.enabled || calculation.recommended) {
      chartData.push({
        data: Array(calculation.minimum.length).fill(units),
        label: this.getCustomerDemandUnits(demand.model),
      });
    }

    return chartData;
  }

  private getCustomerDemandUnits(demand: CustomerDemand) {
    const label = this.translate.instant(demand.label);
    const units = this.translate.instant('units');
    return `${label} ${units}`;
  }

  calculateSurfaceChartComparison(surface: AreaSurface, option: number) {
    const calculations = this.surfaceCalculate(surface);

    if (!calculations || !calculations.maintainance[option]) {
      return null;
    }

    return [
      {
        data: calculations.maintainance[option].minimum,
        label: this.translate.instant('Minimum'),
        borderDash: getDashing(),
      },
      {
        data: calculations.maintainance[option].expected,
        label: this.translate.instant('Expected'),
      },
    ];
  }

  async getPDF() {
    this.generatingPage = 0;
    const childrenArray = this.pdfContainer.nativeElement.children;
    this.doc = new jsPDF('landscape', 'mm', 'a4', 10);

    for (let i = 0; i < childrenArray.length; i++) {
      this.generatingPage = i + 1;
      await this.addPageToPdf(childrenArray[i], i < childrenArray.length - 1);
    }
    sendAnalyticsEvent('PDF Report generated', `Pages: ${this.totalPages}`);
  }

  addPageToPdf(element, addPage: boolean) {
    return html2canvas(element).then(canvas => {
      const imgWidth = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const position = 0;

      this.doc.addImage(
        canvas,
        'JPG',
        0,
        position,
        imgWidth,
        imgHeight,
        '',
        'FAST',
      );

      if (addPage) {
        this.doc.addPage('a4', 'landscape');
      }

      canvas.width = 0;
      canvas.height = 0;
      canvas = null;
    });
  }

  getProductImage(product: Product) {
    return product.image || DEFAULT_PRODUCT_IMAGE;
  }

  isRecommendedValue(calculation: ICustomerDemandCalculation) {
    return calculation.customerDemand.units === calculation.recommended;
  }

  isLongestDurability(surface: AreaSurface, productIndex: number) {
    if (!this.project.isComparing) {
      return false;
    }

    const durabilities = surface.products.map((_, index) => {
      const calc = this.getProductCalculations(surface, index);
      const demand = calc && calc[0];
      return demand ? demand.redecoration : -1;
    });

    const [productDurability] = durabilities.splice(productIndex, 1);
    return durabilities.every(x => productDurability > x);
  }

  private getProductCalculations(surface: AreaSurface, productIndex: number) {
    const calc = this.calculations(surface);
    return calc && calc[productIndex];
  }

  usesRecommendedValue(surface: AreaSurface, productIndex: number) {
    if (!this.project.isExterior) {
      return false;
    }

    const calc = this.getProductCalculations(surface, productIndex);

    if (!calc) {
      return false;
    }

    const enabled = calc.some(x => x.customerDemand.enabled);
    const recommmended = calc.some(x => Boolean(x.recommended));
    return !enabled && recommmended;
  }

  getSurfaceCalculations(surface: AreaSurface, productIndex: number) {
    const calc = this.getProductCalculations(surface, productIndex);

    if (!calc) {
      return [];
    }

    return this.usesRecommendedValue(surface, productIndex)
      ? calc.filter(x => x.recommended).slice(0, 1)
      : calc.filter(x => x.customerDemand.enabled);
  }
}

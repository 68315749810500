export class ProjectType implements IProjectTypeJson {
  static fromJson(json: IProjectTypeJson) {
    return new ProjectType(json);
  }

  readonly code: ProjectTypeCode;
  readonly label: string;
  readonly image: string;

  private constructor(json: IProjectTypeJson) {
    Object.assign(this, json);
  }
}

export interface IProjectTypeJson {
  code: ProjectTypeCode;
  label: string;
  image: string;
}

export type ProjectTypeCode = 'interior' | 'exterior';

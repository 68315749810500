export class CustomerDemand implements ICustomerDemandJson {
  static fromJson(json: ICustomerDemandJson) {
    return new CustomerDemand(json);
  }

  readonly guid: string;
  readonly label: string;
  // readonly minimumValue: number;
  readonly maximumValue: number;
  readonly sliderStep: number;

  get minimumValue() {
    return this.sliderStep;
  }
  set minimumValue(value) {
    // noop
  }

  get sliderDefaultValue() {
    return this.sliderStep;
    // const range = this.maximumValue - this.minimumValue;
    // const steps = Math.floor(range / this.sliderStep / 2);
    // const offset = steps * this.sliderStep;
    // return this.minimumValue + offset;
  }

  private constructor(json: ICustomerDemandJson) {
    Object.assign(this, json);
  }
}

export interface ICustomerDemandJson {
  guid: string;
  label: string;
  minimumValue: number;
  maximumValue: number;
  sliderStep: number;
}

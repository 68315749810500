import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {
  MatTabChangeEvent,
  MatTableDataSource,
  MatDialog,
} from '@angular/material';
import { ProjectTypeCode } from 'src/app/models/api/ProjectType';
import { ApiService } from 'src/app/services/api.service';
import { EnvFactor, IEnvFactorOption } from 'src/app/models/api/EnvFactor';

@Component({
  selector: 'app-env-factors',
  templateUrl: './env-factors.component.html',
  styleUrls: ['./env-factors.component.scss'],
})
export class EnvFactorsComponent implements OnInit {
  @ViewChild('editDialog') editDialog: TemplateRef<MatDialog>;

  envFactorsData: MatTableDataSource<{
    factor: EnvFactor;
    value: IEnvFactorOption;
  }>;

  selectedFactor: {
    factor: EnvFactor;
    value: IEnvFactorOption;
  };

  currentTab: ProjectTypeCode = 'interior';

  displayedColumns: string[] = [
    'image',
    'factorType',
    'name',
    'appliesTo',
    'multiplier',
    'actions',
  ];

  constructor(
    private readonly api: ApiService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getEnvFactors();
  }

  changeProjectType(tab: MatTabChangeEvent) {
    this.currentTab = tab.tab.ariaLabel as ProjectTypeCode;
    this.getEnvFactors();
  }

  getEnvFactors() {
    this.api.clearCache();
    this.api.getAllEnvironemntalFactors(this.currentTab).then(factors => {
      const data: Array<{ factor: EnvFactor; value: IEnvFactorOption }> = [];
      factors.forEach(factor => {
        factor.values.forEach(value => {
          data.push({ factor, value });
        });
      });
      this.envFactorsData = new MatTableDataSource(data);
    });
  }

  editFactor(envFactor: { factor: EnvFactor; value: IEnvFactorOption }) {
    this.selectedFactor = envFactor;
    const dialogRef = this.dialog.open(this.editDialog, {
      data: { factor: { ...envFactor.factor }, value: { ...envFactor.value } },
    });

    dialogRef
      .afterClosed()
      .subscribe((value: { factor: EnvFactor; value: IEnvFactorOption }) => {
        if (value) {
          this.api
            .editEnvironmentalFactor(
              this.selectedFactor.factor.guid,
              value.value
            )
            .then(res => {
              this.getEnvFactors();
            });
        }
      });
  }
}

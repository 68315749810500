import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatTabChangeEvent,
  MatTableDataSource,
} from '@angular/material';
import { Router } from '@angular/router';
import { ProjectTypeCode } from 'src/app/models/api/ProjectType';
import { Sector } from 'src/app/models/api/Sector';
import { AddSectorRequest } from 'src/app/models/requests/SectorRequests';
import { ApiService } from 'src/app/services/api.service';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.scss'],
})
export class SectorsComponent implements OnInit {
  @ViewChild('addDialog') addDialog: TemplateRef<MatDialog>;

  sectorsData: MatTableDataSource<Sector>;
  displayedColumns: string[] = ['image', 'name', 'subsectors', 'actions'];
  currentTab: ProjectTypeCode = 'interior';

  addSectorForm: FormGroup = new FormGroup({
    type: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
  });

  sectorNameTimer: any;

  constructor(
    private readonly api: ApiService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly adminService: AdminService,
  ) {}

  ngOnInit() {
    this.getSectors();
  }

  get sectorName() {
    return this.addSectorForm.get('name').value;
  }

  changeProjectType(tab: MatTabChangeEvent) {
    this.currentTab = tab.tab.ariaLabel as ProjectTypeCode;
    this.getSectors();
  }

  getSectors() {
    this.api.getProjectTypeSectors(this.currentTab).then(sectors => {
      this.sectorsData = new MatTableDataSource(sectors);
    });
  }

  checkSector() {
    clearTimeout(this.sectorNameTimer);        
    this.addSectorForm.get('name').updateValueAndValidity();    
    this.addSectorForm.get('type').updateValueAndValidity();    
    this.sectorNameTimer = setTimeout(() => {
      const sectorModel: AddSectorRequest = {
        name: this.addSectorForm.get('name').value,
        projectTypeCode: this.addSectorForm.get('type').value
      };
      this.api
        .checkSector(sectorModel)
        .then()
        .catch(err => {
          if (err.status == 409) {
            this.addSectorForm.get('name').setErrors({ conflict: true });
            this.addSectorForm.get('type').setErrors({ conflict: true });
          }
        });
    }, 300);
  }

  addSector() {
    this.addSectorForm.reset();
    const dialogRef = this.dialog.open(this.addDialog, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(value => {
      if (value) {
        const model: AddSectorRequest = {
          projectTypeCode: this.addSectorForm.get('type')
            .value as ProjectTypeCode,
          name: this.addSectorForm.get('name').value as string,
        };

        this.api.addSector(model).then(addedSector => {
          this.api.clearCache();
          this.adminService.reloadSidebar.next(true);
          this.router.navigate(['/admin/sector-edit'], {
            state: { sector: addedSector, type: model.projectTypeCode },
          });
        });
      }
    });
  }

  editSector(sector: Sector) {
    this.router.navigate(['/admin/sector-edit'], {
      state: { sector, type: this.currentTab },
    });
  }
}

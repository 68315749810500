export class User implements IUserJson {
  static fromJson(json: IUserJson) {
    return new User(json);
  }

  readonly id: number;
  readonly name: string;
  readonly phone: string;
  readonly email: string;
  readonly dateCreated: Date;
  readonly isAdmin: boolean;
  readonly isActive: boolean;

  private constructor(json: IUserJson) {
    Object.assign(this, json);
  }
}

export interface IUserJson {
  id: number;
  name: string;
  phone: string;
  email: string;
  dateCreated: Date;
  isAdmin: boolean;
  isActive: boolean;
}

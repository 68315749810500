import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-window-project',
  templateUrl: './new-window-project.component.html',
  styleUrls: ['./new-window-project.component.scss'],
})
export class NewWindowProjectComponent implements OnInit {
  constructor(private readonly router: Router) {
    sessionStorage.removeItem('userProject');
    sessionStorage.removeItem('userProjectGuid');
    this.router.navigate(['project/create']);
  }

  ngOnInit() { }
}

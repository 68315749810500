export const isNumber = /^\d+((,|\.)\d+)?$/;
export const isNumberInProgress = /^\d+(,|\.)?\d*$/;

export function format(value: number) {
  return removeThousandSeparator(round(value).toLocaleString());
}

export function toFloat(text: string) {
  return parseFloat(text.replace(/,/, '.'));
}

export function round(value: number) {
  return Math.round(value * 100) / 100;
}

function removeThousandSeparator(value: string) {
  const { thousand } = getNumberSeparators();

  if (!thousand) {
    return value;
  }

  const regex = new RegExp(thousand.replace('.', '\\.'), 'g');
  return value.replace(regex, '');
}

function getNumberSeparators() {
  const str = (1000.1).toLocaleString();
  // We extract the thousand and decimal separator from the locale string
  // Thousand separator is optional
  const [_, thousand, decimal] = str.match(/1(\.|,)?000(\.|,)1/);
  return { thousand, decimal };
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-surface-reset',
  templateUrl: './surface-reset.component.html',
  styleUrls: ['./surface-reset.component.scss'],
})
export class SurfaceResetComponent {
  get surfaceName() {
    return this.data.surfaceName;
  }

  constructor(
    private dialogRef: MatDialogRef<SurfaceResetComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: SurfaceResetData,
  ) {}

  accept() {
    return this.dialogRef.close(true);
  }
}

export interface SurfaceResetData {
  surfaceName: string;
}

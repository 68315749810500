import { TranslateService } from '@ngx-translate/core';

import { ApiService } from '../services/api.service';
import { IEnvFactorOption } from './api/EnvFactor';
import { ProjectTypeCode } from './api/ProjectType';
import { ISubsectorJson, Subsector } from './api/Subsector';
import { AreaSurface, IAreaSurfaceJson } from './AreaSurface';

export class Area implements IArea {
  static fromJson(api: ApiService, json: IAreaJson) {
    return new Area({
      ...json,
      subsector: Subsector.fromJson(json.subsector),
      surfaces:
        json.surfaces && json.surfaces.map(x => AreaSurface.fromJson(api, x)),
    });
  }

  static getDefaultName(
    translate: TranslateService,
    projectType: ProjectTypeCode,
    index: number,
  ) {
    return projectType === 'interior'
      ? `${translate.instant('Room')} ${index + 1}`
      : translate.instant('Subsector');
  }

  subsector: Subsector;
  envFactors: IEnvFactorOption[] = [];
  surfaces: AreaSurface[] = [];
  name: string;

  private _unitValue: number;
  get unitValue() {
    return this._unitValue;
  }
  set unitValue(value) {
    this._unitValue =
      typeof value === 'number' || !value ? value : parseFloat(value);
  }

  constructor(json?: IArea) {
    if (json) {
      Object.assign(this, json);
    }
  }

  clone(): Area {
    return new Area({
      ...this,
      envFactors: this.envFactors.slice(),
      surfaces: this.surfaces.slice(),
    });
  }
}

interface IArea {
  subsector: Subsector;
  unitValue: number;
  name: string;
  envFactors: IEnvFactorOption[];
  surfaces: AreaSurface[];
}

export interface IAreaJson {
  subsector: ISubsectorJson;
  unitValue: number;
  name: string;
  envFactors: IEnvFactorOption[];
  surfaces: IAreaSurfaceJson[];
}

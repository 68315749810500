import { BehaviorSubject } from 'rxjs';

import {
  CalculationsProcessor,
  ISurfaceCalculation,
  ISurfaceOptionCalculation,
  SummaryChart,
} from '../helpers/CalculationsProcessor';
import { MaintainanceCycle } from '../helpers/MaintainanceCycle';
import { ApiService } from '../services/api.service';
import { IProjectTypeJson, ProjectType } from './api/ProjectType';
import { ISectorJson, Sector } from './api/Sector';
import { Area, IAreaJson } from './Area';
import { AreaSurface } from './AreaSurface';
import { IProjectParameters } from './IProjectParameters';
import { IProjectDetailsJson, ProjectDetails } from './ProjectDetails';

export class Project implements IProject {
  static fromJson(api: ApiService, json: IProjectJson) {
    return new Project({
      ...json,
      type: ProjectType.fromJson(json.type),
      sector: json.sector && Sector.fromJson(json.sector),
      details: ProjectDetails.fromJson(json.details),
      areas: json.areas && json.areas.map(x => Area.fromJson(api, x)),
    });
  }

  name: string;
  type: ProjectType;
  sector: Sector;
  areas: Area[];
  parameter: IProjectParameters;
  lifeCycle = 30;
  isCO2Enabled: boolean;
  details: ProjectDetails;
  labourCost: number;

  private processor: CalculationsProcessor;
  private calcCache = new Map<AreaSurface, ISurfaceCalculation>();
  private costCache: SummaryChart;
  private co2eCache: SummaryChart;
  private isComparingTracker = new BehaviorSubject<boolean>(false);
  private options = [0];
  private total: IProjectTotal[];

  get isComparing() {
    return this.isComparingTracker.value;
  }

  set isComparing(value) {
    this.options = value ? [0, 1] : [0];
    this.isComparingTracker.next(value);
  }

  get isInterior() {
    return this.type.code === 'interior';
  }

  get isExterior() {
    return this.type.code === 'exterior';
  }

  get usesRecommendedValue() {
    return !this.hasCustomerDemandEnabled();
  }

  get isValid() {
    return this.isExterior || this.hasCustomerDemandEnabled();
  }

  constructor(json?: IProject) {
    if (json) {
      Object.assign(this, json);
    }
  }

  onIsComparingChange(listener: (value: boolean) => void) {
    return this.isComparingTracker.subscribe(listener);
  }

  getOptions() {
    return this.options;
  }

  clone(): Project {
    return new Project({
      ...this,
      areas: this.areas && this.areas.slice(),
      details: {
        companyInfo: { ...this.details.companyInfo },
        info: { ...this.details.info },
        salesPerson: { ...this.details.salesPerson },
      },
      sector: this.sector,
      type: this.type,
    });
  }

  toJSON(): IProjectJson {
    const {
      name,
      type,
      sector,
      areas,
      parameter,
      lifeCycle,
      isCO2Enabled,
      details,
      labourCost,
      isComparing,
    } = this;

    return {
      name,
      type,
      sector,
      areas,
      parameter,
      lifeCycle,
      isCO2Enabled,
      details,
      labourCost,
      isComparing,
    };
  }

  setCalculationsProcessor(processor: CalculationsProcessor) {
    this.processor = processor;
    this.resetCalculationsCache();
  }

  resetCalculationsCache() {
    if (!this.processor) {
      console.warn('Reset calculations with no processor');
      return Promise.resolve();
    }

    return this.processor.calculate().then(result => {
      this.areas.forEach((area, areaIndex) => {
        area.surfaces.forEach((surface, surfaceIndex) => {
          this.calcCache.set(surface, result[areaIndex][surfaceIndex]);
        });
      });

      const surfaces = flatten(result.map(x => x.map(y => y.maintainance)));

      if (surfaces.every(x => x && x.every(Boolean))) {
        this.costCache = this.processor.costGraph(result);
        this.co2eCache = this.processor.co2eGraph(result);
        this.total = sumSurfaces(surfaces);
      } else {
        this.costCache = null;
        this.co2eCache = null;
        this.total = null;
      }
    });
  }

  // TODO: Remove property, use `this.total` instead
  get getTotal() {
    return this.total;
  }

  getCostChart() {
    return this.costCache;
  }

  getCo2Chart() {
    return this.co2eCache;
  }

  getSurfaceCalculations(areaSurface: AreaSurface) {
    return this.calcCache.get(areaSurface);
  }

  hasCustomerDemandEnabled() {
    return this.areas.every(area =>
      area.surfaces.every(
        surface =>
          surface.customerDemands &&
          surface.customerDemands.some(demand => demand.enabled),
      ),
    );
  }
}

interface IProject {
  type: ProjectType;
  sector: Sector;
  details: ProjectDetails;
  areas: Area[];
}

export interface IProjectJson {
  name: string;
  type: IProjectTypeJson;
  sector: ISectorJson;
  areas: IAreaJson[];
  parameter: IProjectParameters;
  lifeCycle: number;
  isCO2Enabled: boolean;
  details: IProjectDetailsJson;
  labourCost: number;
  isComparing: boolean;
}

export interface IProjectTotal {
  initial: MaintainanceCycle;
  lifecycle: MaintainanceCycle;
}

function sumSurfaces(surfaces: ISurfaceOptionCalculation[][]) {
  const totalOptions = [];

  surfaces.forEach(options => {
    options.forEach((option, index) => {
      if (!totalOptions[index]) {
        totalOptions[index] = {
          initial: new MaintainanceCycle(),
          lifecycle: new MaintainanceCycle(),
        };
      }

      const total = totalOptions[index];
      total.initial = total.initial.add(option.initial);
      total.lifecycle = total.lifecycle.add(option.lifecycle);
    });
  });

  return totalOptions;
}

function flatten<T>(list: T[][]): T[] {
  return [].concat(...list);
}

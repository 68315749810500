import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { emailPattern } from 'src/app/helpers/patterns';
import { TranslateService } from '@ngx-translate/core';

enum ResetStates {
  default = 'default',
  success = 'success',
  incorrect = 'incorrect',
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  emailControl: FormControl;

  status: ResetStates;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    public readonly auth: AuthService,
    public readonly lang: TranslateService,
  ) {}

  ngOnInit() {
    this.status = ResetStates.default;
    this.emailControl = new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(emailPattern),
    ]);
  }

  submit() {
    this.auth
      .requestRecoverPassword(this.emailControl.value, this.lang.currentLang)
      .then(value => {
        this.status = ResetStates.success;
      })
      .catch(err => (this.status = ResetStates.incorrect));
  }
}

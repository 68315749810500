import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { scrollWindow } from 'src/app/helpers/functions';

import { Area } from '../../models/Area';
import { AreaSurface } from '../../models/AreaSurface';
import { Project } from '../../models/Project';
import { StoreService } from '../../services/store-service.service';
import { ApiService } from 'src/app/services/api.service';
import { sendAnalyticsEvent } from 'src/app/utils/analytics';

@Component({
  selector: 'app-project-surfaces',
  templateUrl: './project-surfaces.component.html',
  styleUrls: ['./project-surfaces.component.scss'],
})
export class ProjectSurfacesComponent implements OnInit, AfterContentChecked {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  project: Project;
  surfacesValid = new Map<AreaSurface, boolean>();

  constructor(
    private readonly router: Router,
    private readonly store: StoreService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly api: ApiService
  ) {
    if (!store.project) {
      return router.navigate['/'];
    }
    this.project = store.project;
  }

  get surfacesStatus() {
    let valid = true;
    this.surfacesValid.forEach(x => {
      if (!x) {
        valid = x;
      }
    });
    return valid;
  }

  ngOnInit() {
    this.project.areas.forEach(area => {
      if (!area.surfaces.length) {
        area.surfaces = [new AreaSurface()];
      }
    });
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  @HostListener('window:scroll', [])
  scrollHandler() {
    this.trigger.closeMenu();
  }

  cancelCreation() {
    sendAnalyticsEvent('Project Creation Canceled', 'Step 2');
    if (this.store.projectGuid) this.api.deleteProject(this.store.projectGuid);
    this.router.navigate(['my-projects']);
  }

  backStep() {
    this.store.project = this.project;
    this.router.navigate(['project/create']);
  }

  nextStep() {
    this.project.areas.forEach(area =>
      area.surfaces.forEach((surface, index) => {
        if (!surface.name) {
          surface.name = AreaSurface.getDefaultName(this.translate, index);
        }
      }),
    );

    this.store.project = this.project;
    this.router.navigate(['project/overview']);
  }

  addNewSurface(area: Area) {
    this.project.areas
      .find(x => x.name === area.name)
      .surfaces.push(new AreaSurface());
    scrollWindow(400);
    sendAnalyticsEvent(
      'New Surface Added',
      `Subsector: ${area.subsector.name}; Project Type: ${this.project.type.code}`,
    );
  }

  removeSurface(surface: AreaSurface, index: number = null) {
    this.surfacesValid.delete(surface);
    if (!index) {
      const surfaces = this.project.areas[0].surfaces;
      surfaces.splice(surfaces.indexOf(surface), 1);
      if (!surfaces.length) {
        surfaces.push(new AreaSurface());
      }
      return;
    }

    const area = this.project.areas[index];
    area.surfaces.splice(area.surfaces.indexOf(surface), 1);

    if (!area.surfaces.length) {
      area.surfaces.push(new AreaSurface());
    }

    sendAnalyticsEvent(
      'Surface Removed',
      `Surface Type: ${surface.model.name}; SubSector: ${area.subsector.name}`,
    );
  }

  checkValidation(value: boolean, surface: AreaSurface) {
    if (surface) {
      this.surfacesValid.set(surface, value);
    }
  }
}

import { Router } from '@angular/router';

export function getState(router: Router): any {
  const extras = router.getCurrentNavigation().extras.state;
  return (extras as any) || null;
}

export function scrollWindow(
  distanceFromTop: number = 300,
  timeout: number = 150
) {
  setTimeout(() => {
    window.scrollBy({ top: distanceFromTop, behavior: 'smooth' });
  }, timeout);
}

import { ChartDataSets, ChartOptions } from 'chart.js';
import { Colors, Label } from 'ng2-charts';
import { BehaviorSubject, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  getChartColour,
  getChartConfig,
  Theme,
} from '../../helpers/chart.config';
import { LocaleFormatter } from '../../helpers/Formatter';

const PRIMARY_EXPECTED = 'rgba(0, 174, 234, 1)';
const PRIMARY_MINIMUM = 'rgba(144, 134, 252, 1)';
const SECONDARY_EXPECTED = 'rgba(0, 180, 149, 1)';
const SECONDARY_MINIMUM = 'rgba(180, 200, 40, 1)';
const AUXILIAR_COLOUR = 'rgba(244, 121, 32, 1)';
const OPACITY = 0.38;

const colourSet = {
  primary: [PRIMARY_MINIMUM, PRIMARY_EXPECTED, AUXILIAR_COLOUR],
  secondary: [SECONDARY_MINIMUM, SECONDARY_EXPECTED, AUXILIAR_COLOUR],
  comparison: [
    PRIMARY_MINIMUM.replace('1)', `${OPACITY})`),
    PRIMARY_EXPECTED,
    SECONDARY_MINIMUM.replace('1)', `${OPACITY})`),
    SECONDARY_EXPECTED,
  ],
  cost: [PRIMARY_EXPECTED, SECONDARY_EXPECTED],
};

export type ChartColourSchema = 'primary' | 'secondary' | 'comparison' | 'cost';

@Component({
  selector: 'app-surface-chart',
  templateUrl: './surface-chart.component.html',
  styleUrls: ['./surface-chart.component.scss'],
})
export class SurfaceChartComponent implements OnInit, OnDestroy {
  @Input() theme: Theme;
  @Input() colourSchema: ChartColourSchema;
  @Input() yMaxPosition: number;
  @Input() data: ChartDataSets[];
  @Input() update: BehaviorSubject<boolean>;
  @Input() customClass: string;
  @Input() fontSize: number;
  @Input() isTotalCost: boolean;
  @Input() isCo2TotalCost: boolean;
  @Input() config: Partial<ChartOptions>;

  chartType = 'line';
  chartData: ChartDataSets[];
  options: ChartOptions;
  colours: Colors[];
  labels: Label[] = [];
  formatter: LocaleFormatter;
  private subscription: Subscription;

  constructor(private readonly lang: TranslateService) {}

  ngOnInit() {
    this.formatter = new LocaleFormatter(this.lang);
    this.render();

    if (!this.update) {
      return;
    }

    this.subscription = this.update
      .asObservable()
      .pipe(delay(150))
      .subscribe(() => this.render());
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private render() {
    if (!this.data || !this.data.length) {
      return;
    }

    this.colours = colourSet[this.colourSchema].map(x => getChartColour(x));
    this.options = getChartConfig(
      this.theme,
      this.yMaxPosition,
      this.fontSize,
      this.isTotalCost && this.formatter,
      this.isCo2TotalCost,
      this.config || {},
    );

    this.chartData = this.data;

    const first = this.chartData[0].data as number[];

    this.labels = first.map((_, i) => {
      const value = i / 10;
      return value % 1 ? null : value.toString();
    });
  }
}

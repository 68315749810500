import { Location } from '@angular/common';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { getState } from 'src/app/helpers/functions';
import { CustomerDemand } from 'src/app/models/api/CustomerDemand';
import { Surface, SurfaceDefaultSize } from 'src/app/models/api/Surface';
import {
  SurfaceRequest,
  UpdateSurfaceRequest,
} from 'src/app/models/requests/SurfaceRequests';
import { ApiService } from 'src/app/services/api.service';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-admin-surface-details',
  templateUrl: './surface-details.component.html',
  styleUrls: ['./surface-details.component.scss'],
})
export class AdminSurfaceDetailsComponent {
  @ViewChild('editDialog') editDialog: TemplateRef<MatDialog>;
  @ViewChild('editSize') editSize: TemplateRef<MatDialog>;
  surface: Surface;

  surfaceData: MatTableDataSource<SurfaceDefaultSize>;
  displayedColumns: string[] = ['image', 'subsectorName', 'size', 'actions'];

  availableCustomerDemands: CustomerDemand[];
  surfaceCustomerDemands: CustomerDemand[];

  surfaceForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    customerDemands: new FormControl('', Validators.required),
  });

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly api: ApiService,
    private readonly dialog: MatDialog,
    private readonly adminService: AdminService,
  ) {
    const state = getState(router) as Surface;
    if (!state) {
      this.router.navigate(['/admin/surfaces']);
      return;
    }
    this.initSurface(state);
  }

  back() {
    this.location.back();
  }

  initSurface(surface: Surface) {
    this.api.clearCache();
    this.api
      .getCustomerDemands()
      .then(demands => (this.availableCustomerDemands = demands));
    this.api.getSurface(surface.guid).then(res => {
      this.surface = res;
      this.surfaceData = new MatTableDataSource(this.surface.defaultSizes);
      this.api
        .getSurfaceCustomerDemands(this.surface.guid)
        .then(demands => (this.surfaceCustomerDemands = demands));
    });
  }

  editSurface() {
    const ref = this.dialog.open(this.editDialog, {
      data: { ...this.surface, demands: [...this.surfaceCustomerDemands] },
    });
    ref.afterClosed().subscribe((value: SurfaceRequest) => {
      if (value) {
        const model: UpdateSurfaceRequest = {
          name: value.name,
          customerDemandGuids: value.demands.map(x => x.guid),
        };
        this.api.editSurface(this.surface.guid, model).then(res => {
          this.adminService.reloadSidebar.next(true);
          this.initSurface(this.surface);
        });
      }
    });
  }

  editDefaultSize(size: SurfaceDefaultSize) {
    const ref = this.dialog.open(this.editSize, { data: { ...size } });

    ref.afterClosed().subscribe((value: SurfaceDefaultSize) => {
      if (value) {
        this.api.editSurfaceDefaultSize(this.surface.guid, value).then(res => {
          this.adminService.reloadSidebar.next(true);
          this.initSurface(this.surface);
        });
      }
    });
  }

  compareCustomerDemands(o1: CustomerDemand, o2: CustomerDemand) {
    if (o1 && o2) {
      return o1.guid === o2.guid;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store-service.service';
import { FormControl, Validators } from '@angular/forms';
import { Location, formatNumber } from '@angular/common';
import { UKnumberPattern, NLnumberPattern } from 'src/app/helpers/patterns';
import { sendAnalyticsEvent } from 'src/app/utils/analytics';

@Component({
  selector: 'app-labour-rates',
  templateUrl: './labour-rates.component.html',
  styleUrls: ['./labour-rates.component.scss'],
})
export class LabourRatesComponent implements OnInit {
  labourRates: FormControl = new FormControl('', [
    Validators.required,
    Validators.min(1),
  ]);

  constructor(
    private readonly store: StoreService,
    private readonly location: Location,
  ) {}

  get currentLang() {
    return localStorage.getItem('lang');
  }

  ngOnInit() {
    this.labourRates.setValue(this.store.project.labourCost);
  }

  applyRates() {
    const project = this.store.project;
    project.labourCost = this.labourRates.value;
    this.store.project = project;
    sendAnalyticsEvent(
      'Labour Rates Changed',
      `New Value: ${this.labourRates.value}`,
    );
    this.location.back();
  }

  checkIfNumber(key: KeyboardEvent) {
    // const pattern = /[0-9\+\-\ ]/;
    // if (!pattern.test(String.fromCharCode(key.keyCode))) {
    //   key.preventDefault();
    // }
    console.log(key);
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Colour, IColourJson } from '../models/api/Colour';
import {
  CustomerDemand,
  ICustomerDemandJson
} from '../models/api/CustomerDemand';
import {
  EnvFactor,
  IEnvFactorJson,
  IEnvFactorOption
} from '../models/api/EnvFactor';
import { IPaintSystemJson, PaintSystem } from '../models/api/PaintSystem';
import {
  IProductInternalData,
  IProductJson,
  Product
} from '../models/api/Product';
import {
  IProjectTypeJson,
  ProjectType,
  ProjectTypeCode
} from '../models/api/ProjectType';
import { ISectorJson, Sector } from '../models/api/Sector';
import { Subsector } from '../models/api/Subsector';
import {
  ISurfaceJson,
  Surface,
  SurfaceDefaultSize
} from '../models/api/Surface';
import { ValidationResponse } from '../models/api/ValidationResponse';
import { INameGuidModel } from '../models/flow/flowInterfaces';
import { ColourEditRequest } from '../models/requests/ColourRequests';
import {
  PaintSystemCheckRequest,
  PaintSystemEditRequest,
  PaintSystemLayerEditRequest
} from '../models/requests/PaintSystemRequests';
import {
  ProductCheckRequest,
  ProductEditDataPointRequest,
  ProductEditRequest
} from '../models/requests/ProductRequests';
import {
  AddSectorRequest,
  AddSubSectorRequest
} from '../models/requests/SectorRequests';
import { ShareReportRequest } from '../models/requests/ShareReport';
import { UpdateSurfaceRequest } from '../models/requests/SurfaceRequests';
import { IProjectJson } from '../models/Project';
import { ProjectSyncRequest, SyncProjectResponse, GetProjectsResponse, GetProjectResponse } from '../models/api/Project';

function server(endpoint: string) {
  return `${environment.baseUrl}${endpoint}`;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly cache = new Map<string, Promise<any>>();

  constructor(private readonly http: HttpClient) { }

  /**
   * Wrapper for `http.get()` with cache support
   * @param url Url of the endpoint to get the data from
   */
  private get<T>(url: string): Promise<T> {
    if (this.cache.has(url)) {
      // tslint:disable-next-line: no-console
      console.debug(`[HTTP][GET ${url}] From cache`);
      return this.cache.get(url);
    }

    // tslint:disable-next-line: no-console
    console.debug(`[HTTP][GET ${url}] Not in cache`);
    const promise = this.http.get<T>(server(url)).toPromise();
    this.cache.set(url, promise);
    return promise;
  }

  clearCache() {
    this.cache.clear();
  }

  getLabourRate() {
    return this.get<ILabourRate>('/config/labour-rates').then(x => x.value);
  }

  setLabourRate(value: number) {
    return this.http
      .post<ILabourRate>(server('/config/labour-rates'), { value })
      .toPromise()
      .then(x => x.value);
  }

  getProjectTypes() {
    return this.get<IProjectTypeJson[]>('/project').then(x =>
      x.map(ProjectType.fromJson),
    );
  }

  getAllEnvironemntalFactors(code: ProjectTypeCode) {
    return this.get<IEnvFactorJson[]>(
      `/project/${code}/environmental-factor`,
    ).then(x => x.map(EnvFactor.fromJson));
  }

  getProjectTypeEnvFactors(code: ProjectTypeCode) {
    return this.get<IEnvFactorJson[]>(`/project/${code}/environmental-factor`)
      .then(x => x.map(EnvFactor.fromJson))
      .then(x => x.filter(y => !y.isSurface));
  }

  getSurfaceEnvFactors(code: ProjectTypeCode) {
    return this.get<IEnvFactorJson[]>(`/project/${code}/environmental-factor`)
      .then(x => x.map(EnvFactor.fromJson))
      .then(x => x.filter(y => y.isSurface));
  }

  getProjectTypeSectors(code: ProjectTypeCode) {
    return this.get<ISectorJson[]>(`/project/${code}/sector`).then(x =>
      x.map(Sector.fromJson),
    );
  }

  getProjectTypeSurfaces(code: ProjectTypeCode) {
    return this.get<ISurfaceJson[]>(`/project/${code}/surface`).then(x =>
      x.map(y =>
        Surface.fromJson({
          ...y,
        }),
      ),
    );
  }

  getSurfaceProducts(surfaceGuid: string) {
    return this.get<IProductJson[]>(
      `/surface/${surfaceGuid}/product`,
    ).then(response => response.map(x => Product.fromJson(this, x)));
  }

  getSurfacePaintSystems(surfaceGuid: string, productGuid: string) {
    return this.get<IPaintSystemJson[]>(
      `/surface/${surfaceGuid}/product/${productGuid}/paint-system`,
    ).then(x => x.map(y => PaintSystem.fromJson(this, y)));
  }

  /////////////////////////////////////////////////////////////////////////
  // Start of My Projects

  syncProject(project: IProjectJson | null, projectGuid: string): Promise<SyncProjectResponse> {
    const projectItem: ProjectSyncRequest = {
      projectGuid,
      projectName: (project && 'name' in project) ? project.name : null,
      projectData: (project) ? JSON.stringify(project) : null,
      syncTimestamp: new Date().valueOf()
    };
    return this.http.post<SyncProjectResponse>(server('/project/sync'), projectItem).toPromise();
  }

  duplicateProject(projectGuid: string): Promise<unknown> {
    return this.http.post<unknown>(server(`/project/duplicate/${projectGuid}`), {}).toPromise();
  }

  deleteProject(projectGuid: string): Promise<unknown> {
    return this.http.delete<unknown>(server(`/project/${projectGuid}`)).toPromise();
  }

  getProject(projectGuid: string): Promise<GetProjectResponse> {
    return this.http.get<GetProjectResponse>(server(`/project/${projectGuid}`)).toPromise();
  }

  getProjects(
    query: string = null,
    sort: "projectName" | "dateCreated" | "dateUpdated" = "projectName",
    sortDirection: "ASC" | "DESC" = "ASC",
    page: number = 0,
    pageSize: number = null,
  ): Promise<GetProjectsResponse> {
    const params: GetProjectParams = {
      sortBy: sort,
      sortDirection: sortDirection,
      page: page && page.toString(),
      pageSize: pageSize && pageSize.toString(),
    };

    if (query) params.searchTerm = query;

    return this.http.get<GetProjectsResponse>(server('/project/all'), { params }).toPromise();
  }

  addProjectPdf(projectGuid: string, pdf: Blob): Promise<unknown> {
    const formData = new FormData();
    formData.append("files", pdf);
    return this.http.post<unknown>(server(`/project/${projectGuid}/pdf`), formData).toPromise();
  }

  // End of My Projects
  /////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////
  // Start of Sectors
  getSector(guid: string): Promise<Sector> {
    return this.http.get<Sector>(server(`/sector/${guid}`)).toPromise();
  }

  checkSector(model: AddSectorRequest, sectorGuid?: string): Promise<Sector> {
    return sectorGuid != null 
      ? this.http.post<Sector>(server(`/sector/check/${sectorGuid}`), model).toPromise()
      : this.http.post<Sector>(server(`/sector/check`), model).toPromise()
  }

  addSector(model: AddSectorRequest): Promise<Sector> {
    return this.http.post<Sector>(server(`/sector`), model).toPromise();
  }

  editSector(guid: string, model: AddSectorRequest): Promise<Sector> {
    return this.http
      .put<Sector>(server(`/sector/${guid}`), model)
      .toPromise();
  }

  deleteSector(guid: string): Promise<Sector> {
    return this.http.delete<Sector>(server(`/sector/${guid}`)).toPromise();
  }

  checkSubsector(
    sectorGuid: string,
    model: AddSubSectorRequest,
    subsectorGuid?: string
  ): Promise<Sector> {
    return subsectorGuid != null 
      ? this.http.post<Sector>(server(`/sector/${sectorGuid}/check/subsector/${subsectorGuid}`), model).toPromise()
      : this.http.post<Sector>(server(`/sector/${sectorGuid}/check/subsector`), model).toPromise()
  }

  addSubsector(
    sectorGuid: string,
    model: AddSubSectorRequest,
  ): Promise<Sector> {
    return this.http
      .post<Sector>(server(`/sector/${sectorGuid}/subsector`), model)
      .toPromise();
  }

  editSubsector(sectorGuid: string, subsector: Subsector): Promise<Sector> {
    return this.http
      .put<Sector>(
        server(`/sector/${sectorGuid}/subsector/${subsector.guid}`),
        { name: subsector.name, unit: subsector.unit },
      )
      .toPromise();
  }

  deleteSubsector(sectorGuid: string, subsectorGuid: string): Promise<Sector> {
    return this.http
      .delete<Sector>(
        server(`/sector/${sectorGuid}/subsector/${subsectorGuid}`),
      )
      .toPromise();
  }

  /// End of Sectors
  /////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////
  // Start of Surfaces

  getAllSurfaces(): Promise<INameGuidModel[]> {
    return this.http.get<INameGuidModel[]>(server(`/surface/lite`)).toPromise();
  }

  getSurface(guid: string): Promise<Surface> {
    return this.http.get<Surface>(server(`/surface/${guid}`)).toPromise();
  }

  getSurfaceCustomerDemands(surfaceGuid: string) {
    return this.get<ICustomerDemandJson[]>(
      `/surface/${surfaceGuid}/customer-demand`,
    ).then(x => x.map(CustomerDemand.fromJson));
  }

  editSurface(guid: string, model: UpdateSurfaceRequest): Promise<Response> {
    return this.http
      .put<Response>(server(`/surface/${guid}`), model)
      .toPromise();
  }

  editSurfaceDefaultSize(
    guid: string,
    model: SurfaceDefaultSize,
  ): Promise<Response> {
    return this.http
      .put<Response>(
        server(`/surface/${guid}/default-size/${model.subsector.guid}`),
        { value: model.size },
      )
      .toPromise();
  }

  // End of Surfaces
  /////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////
  // Start of Customer Demands

  getCustomerDemands(): Promise<CustomerDemand[]> {
    return this.http
      .get<CustomerDemand[]>(server(`/customer-demand`))
      .toPromise();
  }

  editCustomerDemands(demand: CustomerDemand): Promise<Response> {
    return this.http
      .put<Response>(server(`/customer-demand/${demand.guid}`), {
        minimumValue: demand.minimumValue,
        maximumValue: demand.maximumValue,
        sliderStep: demand.sliderStep,
      })
      .toPromise();
  }

  // End of Customer Demands
  /////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////
  // Start of Environmental Factors

  editEnvironmentalFactor(
    guid: string,
    valueModel: IEnvFactorOption,
  ): Promise<Response> {
    return this.http
      .put<Response>(
        server(`/environmental-factor/${guid}/value/${valueModel.guid}`),
        { multiplier: valueModel.multiplier },
      )
      .toPromise();
  }

  // End of Environmental Factors
  /////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////
  // Start of Products

  getProducts(): Promise<Product[]> {
    return this.http
      .get<Product[]>(server(`/product`))
      .toPromise()
      .then(response => response.map(x => Product.fromJson(this, x)));
  }

  getLiteProducts(): Promise<INameGuidModel[]> {
    return this.http.get<Product[]>(server(`/product/lite`)).toPromise();
  }

  getProduct(guid: string): Promise<Product> {
    return this.http
      .get<Product>(server(`/product/${guid}`))
      .toPromise()
      .then(x => Product.fromJson(this, x));
  }

  getProductInternalData(productGuid: string) {
    return this.get<IProductInternalData>(
      `/product/${productGuid}/internal-data`,
    );
  }

  editProductDataPoints(
    productGuid: string,
    customerDemandGuid: string,
    model: ProductEditDataPointRequest,
  ): Promise<Response> {
    return this.http
      .put<Response>(
        server(`/product/${productGuid}/data-point/${customerDemandGuid}`),
        {
          recommended: model.recommended,
          expected: model.expected,
          minimum: model.minimum,
        },
      )
      .toPromise();
  }

  addProduct(model: ProductEditRequest): Promise<Product> {
    return this.http
      .post<Product>(server(`/product`), model)
      .toPromise()
      .then(x => Product.fromJson(this, x));
  }

  editProduct(model: ProductEditRequest): Promise<Response> {
    return this.http
      .put<Response>(server(`/product/${model.guid}`), model)
      .toPromise();
  }

  deleteProduct(guid: string): Promise<Response> {
    return this.http.delete<Response>(server(`/product/${guid}`)).toPromise();
  }

  checkProduct(model: ProductCheckRequest, productGuid?: string): Promise<Product> {
    return productGuid != null 
      ? this.http.post<Product>(server(`/product/check/${productGuid}`), model).toPromise()
      : this.http.post<Product>(server(`/product/check`), model).toPromise()
  }

  // End of Products
  /////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////
  // Start of Paint Systems

  getPaintSystems(): Promise<PaintSystem[]> {
    return this.http.get<PaintSystem[]>(server(`/paint-system`)).toPromise();
  }

  getPaintSystem(guid: string): Promise<PaintSystem> {
    return this.http
      .get<PaintSystem>(server(`/paint-system/${guid}`))
      .toPromise();
  }

  addPaintSystem(model: PaintSystemEditRequest): Promise<PaintSystem> {
    return this.http
      .post<PaintSystem>(server(`/paint-system/`), model)
      .toPromise();
  }

  editPaintSystem(model: PaintSystemEditRequest): Promise<Response> {
    return this.http
      .put<Response>(server(`/paint-system/${model.systemGuid}`), {
        name: model.name,
        reference: model.reference,
        surfaceGuid: model.surfaceGuid,
      })
      .toPromise();
  }

  deletePaintSystem(guid: string): Promise<Response> {
    return this.http
      .delete<Response>(server(`/paint-system/${guid}`))
      .toPromise();
  }

  checkPaintSystem(model: PaintSystemCheckRequest, paintSystemGuid?: string): Promise<PaintSystem> {
    return paintSystemGuid != null 
      ? this.http.post<PaintSystem>(server(`/paint-system/check/${paintSystemGuid}`), model).toPromise()
      : this.http.post<PaintSystem>(server(`/paint-system/check`), model).toPromise()
  }

  addSystemLayer(
    systemGuid: string,
    model: PaintSystemLayerEditRequest,
  ): Promise<Response> {
    return this.http
      .post<Response>(server(`/paint-system/${systemGuid}/layer`), model)
      .toPromise();
  }

  editSystemLayer(
    systemGuid: string,
    layerGuid: string,
    model: PaintSystemLayerEditRequest,
  ): Promise<Response> {
    return this.http
      .put<Response>(
        server(`/paint-system/${systemGuid}/layer/${layerGuid}`),
        model,
      )
      .toPromise();
  }

  deleteSystemLayer(systemGuid: string, layerGuid: string): Promise<Response> {
    return this.http
      .delete<Response>(
        server(`/paint-system/${systemGuid}/layer/${layerGuid}`),
      )
      .toPromise();
  }

  // End of Paint Systems
  /////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////
  // Start of Colours

  getColours() {
    return this.get<Paginated<IColourJson>>('/colour').then(x =>
      x.items.map(Colour.fromJson),
    );
  }

  checkColor(model: ColourEditRequest, colourGuid?: string): Promise<Colour> {
    return colourGuid != null 
      ? this.http.post<Colour>(server(`/Colour/check/${colourGuid}`), model).toPromise()
      : this.http.post<Colour>(server(`/Colour/check`), model).toPromise();
  }

  getFilteredColours(
    query: string = null,
    page: number = 0,
    pageSize: number = null,
  ) {
    const params: GetColoursParams = {
      page: page && page.toString(),
      pageSize: pageSize && pageSize.toString(),
    };

    if (query) {
      params.query = query;
    }

    return this.http
      .get<Paginated<IColourJson>>(server('/colour'), {
        params,
      })
      .toPromise()
      .then(x => x.items.map(Colour.fromJson));
  }

  getFilteredColoursForAdmin(
    query: string = null,
    page: number = 0,
    pageSize: number = null,
  ) {
    const params: GetColoursParams = {
      page: page && page.toString(),
      pageSize: pageSize && pageSize.toString(),
    };

    if (query) {
      params.query = query;
    }

    return this.http
      .get<Paginated<IColourJson>>(server('/colour'), {
        params,
      })
      .toPromise()
      .then(x => ({
        items: x.items.map(Colour.fromJson),
        total: x.total,
      }));
  }

  addColour(model: ColourEditRequest): Promise<Colour> {
    return this.http.post<Colour>(server(`/colour`), model).toPromise();
  }

  editColour(colourGuid: string, model: ColourEditRequest) {
    return this.http
      .put<Colour>(server(`/colour/${colourGuid}`), model)
      .toPromise();
  }

  deleteColour(guid: string): Promise<Colour> {
    return this.http.delete<Colour>(server(`/colour/${guid}`)).toPromise();
  }

  // End of Colours
  /////////////////////////////////////////////////////////////////////////

  adminValidate(): Promise<ValidationResponse> {
    return this.http
      .get<ValidationResponse>(server('/admin/validate'))
      .toPromise();
  }

  sendReport(model: ShareReportRequest, file: Blob): Promise<Response> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append(
      'data',
      new Blob([JSON.stringify(model)], { type: 'application/json' }),
    );

    return this.http
      .post<Response>(server(`/project/sendreport`), formData)
      .toPromise();
  }

  logEvent(body: IEventLog) {
    return this.http
      .post<Response>(server('/log/galog'), { body })
      .toPromise()
  }
}

interface IEventLog{
    Action:string,
    Label:string|undefined,
    UserId: number | string | undefined, 
  
}

interface ILabourRate {
  value: number;
}

interface Paginated<T> {
  items: T[];
  total: number;
}

type GetColoursParams = {
  page: string;
  pageSize: string;
  query?: string;
};

function dictionaryToMap<T>(values: Array<object>, [key, value]) {
  const result: { [id: string]: T; } = {};
  values.forEach(x => (result[x[key]] = x[value]));
  return result;
}

type GetProjectParams = {
  sortBy: string;
  page: string;
  sortDirection: string;
  pageSize: string;
  searchTerm?: string;
};

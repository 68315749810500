import { Component, OnInit } from '@angular/core';
import { AdminService } from './services/admin.service';
import { ApiService } from 'src/app/services/api.service';
import { ValidationResponse } from 'src/app/models/api/ValidationResponse';

@Component({
  selector: 'app-admin-root',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  validation: ValidationResponse;

  constructor(
    private readonly adminService: AdminService,
    private readonly api: ApiService,
  ) {}

  ngOnInit() {
    console.log('admin accessed');
    this.validate();

    this.adminService.reloadSidebar.subscribe(value => {
      if (value) {
        this.validate();
      }
    });
  }

  validate() {
    this.api.adminValidate().then(res => {
      console.log('refreshed validation');
      this.validation = res;
    });
  }
}

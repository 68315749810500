import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/api/User';
import { CreateUserRequest } from 'src/app/models/requests/CreateUserRequest';
import { UpdateUserRequest } from 'src/app/models/requests/UpdateUserRequest';
import { TranslateService } from '@ngx-translate/core';

const emailPattern =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([_a-zA-Z\-0-9]+\.)+[_a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  @ViewChild('addDialog') addDialog: TemplateRef<MatDialog>;
  @ViewChild('editDialog') editDialog: TemplateRef<MatDialog>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  usersData: MatTableDataSource<User>;
  users: User[];
  displayedColumns: string[] = [
    'name',
    'phone',
    'email',
    'dateCreated',
    'isAdmin',
    'isActive',
    'actions',
  ];

  addUserForm: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(/(.|\s)*\S(.|\s)*/),
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(16),
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(emailPattern),
    ]),
    isAdmin: new FormControl(''),
  });

  editUserForm: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(/(.|\s)*\S(.|\s)*/),
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(16),
    ]),
    isAdmin: new FormControl(''),
    isActive: new FormControl(''),
  });

  constructor(
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    return this.authService.getUsers().then(users => {
      this.users = users;
      this.usersData = new MatTableDataSource(this.users);
      this.usersData.paginator = this.paginator;
    });
  }

  addUser() {
    const form = this.addUserForm;
    form.reset();
    form.get('isAdmin').setValue(false);
    const dialogRef = this.dialog.open(this.addDialog);

    dialogRef.afterClosed().subscribe(value => {
      if (value) {
        const model: CreateUserRequest = {
          name: form.get('name').value.trim(),
          phone: form.get('phone').value.trim(),
          email: form.get('email').value.trim(),
          isAdmin: form.get('isAdmin').value,
          language: localStorage.getItem('lang'),
        };

        this.authService.createUser(model).then(() => this.getUsers());
      }
    });
  }

  editUser(user: User) {
    const form = this.editUserForm;
    form.reset();
    form.get('name').setValue(user.name);
    form.get('phone').setValue(user.phone);
    form.get('isAdmin').setValue(user.isAdmin);
    form.get('isActive').setValue(user.isActive);

    const dialogRef = this.dialog.open(this.editDialog);

    dialogRef.afterClosed().subscribe(value => {
      if (value) {
        const model: UpdateUserRequest = {
          id: user.id,
          name: form.get('name').value.trim(),
          phone: form.get('phone').value.trim(),
          isAdmin: form.get('isAdmin').value,
          isActive: form.get('isActive').value,
        };

        this.authService.updateUser(model).then(() => this.getUsers());
      }
    });
  }
}

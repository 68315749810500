import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatTableDataSource, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { PaintSystem } from 'src/app/models/api/PaintSystem';
import { ApiService } from 'src/app/services/api.service';
import { Surface } from 'src/app/models/api/Surface';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { INameGuidModel } from 'src/app/models/flow/flowInterfaces';
import { PaintSystemCheckRequest, PaintSystemEditRequest } from 'src/app/models/requests/PaintSystemRequests';

@Component({
  selector: 'app-paint-systems',
  templateUrl: './paint-systems.component.html',
  styleUrls: ['./paint-systems.component.scss'],
})
export class PaintSystemsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('addDialog') addSystemDialog: TemplateRef<MatDialog>;

  paintSystemsData: MatTableDataSource<PaintSystem>;
  displayedColumns: string[] = [
    'name',
    'systemReference',
    'surfaceType',
    'actions',
  ];

  surfacesList: INameGuidModel[];
  addPaintSystemForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    reference: new FormControl('', Validators.required),
    surfaceType: new FormControl(''),
  });
  paintSystemTimer: any;

  constructor(
    private readonly api: ApiService,
    private readonly dialog: MatDialog,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.getPaintSystems();
    this.api.getAllSurfaces().then(surfaces => (this.surfacesList = surfaces));
  }

  getPaintSystems() {
    this.api.getPaintSystems().then(paintSystems => {
      this.paintSystemsData = new MatTableDataSource(paintSystems);
      this.paintSystemsData.paginator = this.paginator;
    });
  }

  addPaintSystem() {
    this.addPaintSystemForm.reset();
    const ref = this.dialog.open(this.addSystemDialog);

    ref.afterClosed().subscribe(value => {
      if (value) {
        const model: PaintSystemEditRequest = {
          name: this.addPaintSystemForm.get('name').value,
          reference: this.addPaintSystemForm.get('reference').value,
          surfaceGuid: (this.addPaintSystemForm.get('surfaceType')
            .value as Surface).guid,
        };
        this.api.addPaintSystem(model).then(res => this.editPaintSystem(res));
      }
    });
  }

  editPaintSystem(system: PaintSystem) {
    this.router.navigate(['/admin/paint-system-edit'], { state: system });
  }

  checkPaintSystem() {
    clearTimeout(this.paintSystemTimer);        
    this.addPaintSystemForm.get('name').updateValueAndValidity();
    this.addPaintSystemForm.get('reference').updateValueAndValidity();
    this.paintSystemTimer = setTimeout(() => {
      const model: PaintSystemCheckRequest = {
          name: this.addPaintSystemForm.get('name').value,
          reference: this.addPaintSystemForm.get('reference').value,
      };
      this.api
        .checkPaintSystem(model)
        .then()
        .catch(err => {
          if (err.status == 409) {
            this.addPaintSystemForm.get('name').setErrors({ conflict: true });            
            this.addPaintSystemForm.get('reference').setErrors({ conflict: true });
          }
        });
    }, 300);
  }
}

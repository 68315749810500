import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { scrollWindow } from 'src/app/helpers/functions';
import { EnvFactor, IEnvFactorOption } from 'src/app/models/api/EnvFactor';
import { ProjectTypeCode } from 'src/app/models/api/ProjectType';
import { Subsector } from 'src/app/models/api/Subsector';
import { ApiService } from 'src/app/services/api.service';

import { Area } from '../../models/Area';
import { sendAnalyticsEvent } from 'src/app/utils/analytics';

@Component({
  selector: 'app-area-details',
  templateUrl: './area-details.component.html',
  styleUrls: ['./area-details.component.scss'],
})
export class AreaDetailComponent implements OnInit, OnDestroy {
  @Input() areaModel: Area;
  @Input() areaIndex: number;
  @Input() subSectors: Subsector[];
  @Input() projectType: ProjectTypeCode;
  @Input() canAddNew: boolean;
  @Input() isInEditMode: boolean;

  validationForm = new FormGroup({
    subsector: new FormControl(
      { value: '', disabled: true },
      Validators.required,
    ),
    units: new FormControl('', Validators.required),
  });

  userClickedName = false;
  envForm = new FormGroup({});

  @Output() addNewTrigger: EventEmitter<boolean> = new EventEmitter();
  @Output() removeTrigger: EventEmitter<Area> = new EventEmitter();
  @Output() isValid: BehaviorSubject<boolean> = new BehaviorSubject(
    this.formStatus,
  );

  validSubscription: Subscription;
  envFactorsSubscription: Subscription;
  environmentalFactors: EnvFactor[] = [];

  get defaultAreaName() {
    return Area.getDefaultName(
      this.translate,
      this.projectType,
      this.areaIndex,
    );
  }

  get formStatus() {
    const areaForm = this.validationForm.valid;
    const envForm = this.envForm.valid;

    return !areaForm || !envForm ? false : true;
  }

  get checkIfValid() {
    return !this.validationForm.get('subsector').valid;
  }

  constructor(
    private readonly translate: TranslateService,
    private readonly api: ApiService,
  ) {}

  ngOnInit() {
    this.userClickedName = Boolean(this.areaModel.name);
    this.areaModel = this.areaModel || new Area();

    if (this.subSectors && this.subSectors.length === 1) {
      this.areaModel.subsector = this.subSectors[0];
    }

    this.api.getProjectTypeEnvFactors(this.projectType).then(factors => {
      this.environmentalFactors = factors;

      factors.forEach(factor =>
        this.envForm.addControl(
          factor.label,
          new FormControl('', Validators.required),
        ),
      );
    });

    this.validSubscription = this.validationForm.statusChanges.subscribe(
      change => this.isValid.next(this.formStatus),
    );

    this.envFactorsSubscription = this.envForm.statusChanges.subscribe(change =>
      this.isValid.next(this.formStatus),
    );

    if (!this.isInEditMode && this.subSectors.length !== 1) {
      this.validationForm.get('subsector').enable();
    }
  }

  ngOnDestroy() {
    if (this.validSubscription) {
      this.validSubscription.unsubscribe();
    }
    if (this.envFactorsSubscription) {
      this.envFactorsSubscription.unsubscribe();
    }
  }

  resetAreaName() {
    if (this.userClickedName) {
      return;
    }
    this.areaModel.name = '';
    this.userClickedName = true;
  }

  triggerNewElement() {
    this.addNewTrigger.emit(true);
  }

  removeElement(element: Area) {
    this.removeTrigger.emit(element);
  }

  assignValue(event: MatSelectChange) {
    this.areaModel.envFactors = [];
    if (this.projectType === 'exterior') {
      scrollWindow(150);
    }

    sendAnalyticsEvent(
      'Subsector Type Assigned:',
      `Subsector Type: ${event.value.name}; Project type: ${this.projectType}`,
    );
  }

  logEnvFactors(event: MatSelectChange) {
    sendAnalyticsEvent(
      'SubSector - Env Factor Assigned',
      `Env Factor: ${event.value.label}; SubSector: ${this.areaModel.subsector.name}`,
    );
  }

  compareAreaTypes(o1: Subsector, o2: Subsector) {
    if (o1 && o2) {
      return o1.guid === o2.guid;
    }
  }

  compareEnvFactor(o1: IEnvFactorOption, o2: IEnvFactorOption) {
    if (o1 && o2) {
      return o1.guid === o2.guid;
    }
  }
}

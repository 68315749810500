import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'toDutch',
})
export class DutchTranslate implements PipeTransform {
  dutch: any;

  constructor(private readonly lang: TranslateService) {
    this.getDutch().then(dutch => {
      this.dutch = dutch;
    });
  }

  transform(value: any, ...args: any[]) {
    const translated = this.dutch && this.dutch[value];
    return translated || value;
  }

  getDutch(): Promise<any> {
    return this.lang.getTranslation('nl').toPromise();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange, MatSliderChange } from '@angular/material';
import { Router } from '@angular/router';

import { AreaSurface } from '../../models/AreaSurface';
import { StoreService } from '../../services/store-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { sendAnalyticsEvent } from 'src/app/utils/analytics';

@Component({
  selector: 'app-header-overview',
  templateUrl: './header-overview.component.html',
  styleUrls: ['./header-overview.component.scss'],
})
export class HeaderOverviewComponent implements OnInit {
  @Input() projectType: string;
  @Input() projectSector: string;
  @Input() projectName: string;
  @Output() lifeCycleChange: EventEmitter<boolean> = new EventEmitter();

  projectLifeCycle: number;
  isCO2Enabled: boolean;
  labourRates: number;

  get currentLang() {
    return localStorage.getItem('lang');
  }

  get project() {
    return this.store.project;
  }
  
  logout() {
    this.auth.logoutUser();
  }

  resetPassword() {
    this.auth.sendPasswordResetMail(this.translate.currentLang);
  }

  constructor(
    private readonly router: Router,
    private readonly store: StoreService,
    private readonly auth: AuthService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit() {
    this.projectLifeCycle = this.project.lifeCycle;
    this.isCO2Enabled = this.project.isCO2Enabled;
    this.labourRates = this.project.labourCost;
  }

  goToShare() {
    sendAnalyticsEvent(
      'Project Overview accessed',
      `Project Name: ${this.project.name || 'Unnamed'};
       Project Type: ${this.project.type.code};
        Sector: ${this.project.sector.name};
        Subsectors: ${this.project.areas.map(x => x.subsector.name).join(', ')};
        Surfaces: ${this.project.areas
          .map(x =>
            x.surfaces.map(
              y =>
                `${y.model.name} (IsRmi: ${
                  y.isRmi
                }, Product 1: ${this.getSurfaceProductName(
                  y,
                  0,
                )}, Product 2: ${this.getSurfaceProductName(y, 1)},
                    Colour: ${
                      (y.colour && y.colour.code) ||
                      'Not set, will have default colour'
                    }
                )`,
            ),
          )
          .join(', ')};
        IsComparison: ${this.project.isComparing};
        IsCo2Enabled: ${this.project.isCO2Enabled};
        LifeCycle: ${this.project.lifeCycle};
    `,
    );

    this.router.navigate(['/project/details'], {
      state: { shouldGoBack: false },
    });
  }

  openMenu() {
    this.router.navigate(['menu']);
  }

  adjustLifeCycle(event: MatSliderChange) {
    this.project.lifeCycle = event.value;
    this.lifeCycleChange.emit();
  }

  toggleCO2Info(event: MatCheckboxChange) {
    this.project.isCO2Enabled = event.checked;
    sendAnalyticsEvent(
      'Project CO2 toggled',
      `Value: ${this.project.isCO2Enabled}`,
    );
  }

  getSurfaceProductName(surface: AreaSurface, productIndex: number) {
    if (surface.products[productIndex]) {
      return surface.products[productIndex].name;
    }

    return 'not set';
  }
}

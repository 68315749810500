import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CustomerDemand } from 'src/app/models/api/CustomerDemand';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-demands',
  templateUrl: './customer-demands.component.html',
  styleUrls: ['./customer-demands.component.scss'],
})
export class CustomerDemandsComponent implements OnInit {
  @ViewChild('editDialog') addDialog: TemplateRef<MatDialog>;

  demandsData: MatTableDataSource<CustomerDemand>;
  displayedColumns: string[] = [
    'name',
    'minValue',
    'maxValue',
    'step',
    'actions',
  ];

  editDemandForm: FormGroup = new FormGroup({
    minValue: new FormControl('', Validators.required),
    maxValue: new FormControl('', Validators.required),
    step: new FormControl('', Validators.required),
  });

  constructor(
    private readonly api: ApiService,
    private readonly dialog: MatDialog,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.getCustomerDemands();
  }

  getCustomerDemands() {
    this.api.clearCache();
    this.api.getCustomerDemands().then(demands => {
      this.demandsData = new MatTableDataSource(demands);
    });
  }

  editDemand(demand: CustomerDemand) {
    this.editDemandForm.reset();

    const dialogRef = this.dialog.open(this.addDialog, { data: { ...demand } });

    dialogRef.afterClosed().subscribe((value: CustomerDemand) => {
      if (value) {
        this.api.editCustomerDemands(value).then(res => {
          this.getCustomerDemands();
        });
      }
    });
  }

  compareColourRating(o1: string, o2: number) {
    if (o1 && o2) {
      return Number(o1) === o2;
    }
  }
}

import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatPaginator,
  MatTableDataSource,
  PageEvent,
} from '@angular/material';
import { Router } from '@angular/router';
import { Colour } from 'src/app/models/api/Colour';
import { ColourEditRequest } from 'src/app/models/requests/ColourRequests';
import { ApiService } from 'src/app/services/api.service';

const HEX_REGEX_NO_HASH = /^([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})?$/;
@Component({
  selector: 'app-colours',
  templateUrl: './colours.component.html',
  styleUrls: ['./colours.component.scss'],
})
export class ColoursComponent implements OnInit, AfterViewInit {
  @ViewChild('addDialog') addDialog: TemplateRef<MatDialog>;
  @ViewChild('deleteColourDialog') deleteColourDialog: TemplateRef<MatDialog>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  coloursData: MatTableDataSource<Colour>;
  displayedColumns: string[] = [
    'preview',
    'code',
    'hex',
    'starRating',
    'actions',
  ];

  dataLength: number;
  colourFilterControl = new FormControl();
  searchTimer;

  addColourForm: FormGroup = new FormGroup({
    code: new FormControl('', Validators.required),
    hex: new FormControl('', Validators.required),
    starRating: new FormControl('', Validators.required),
  });  

  colorCodeTimer: any;
  updatedColorId: string;

  constructor(
    private readonly api: ApiService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.getColours();

    this.colourFilterControl.valueChanges.subscribe(value => {
      clearTimeout(this.searchTimer);

      this.searchTimer = setTimeout(() => {
        this.paginator.pageIndex = 0;
        this.getColours();
      }, 200);
    });
  }

  getColours() {
    this.api.clearCache();
    this.api
      .getFilteredColoursForAdmin(
        this.colourFilterControl.value,
        this.paginator.pageIndex,
        this.paginator.pageSize,
      )
      .then(colours => {
        this.coloursData = new MatTableDataSource(colours.items);
        this.dataLength = colours.total;
      });
  }

  IsValidHex() {
    return HEX_REGEX_NO_HASH.exec(this.addColourForm.get('hex').value)
  }

  checkhex() {
    this.addColourForm.get('hex').updateValueAndValidity();   
    if (!this.IsValidHex())        
    {
      this.addColourForm.get('hex').setErrors({ invalid: true });
      return;
    }    
  }

  checkColor() {
    let code = this.addColourForm.get('code').value;
    let hex = this.addColourForm.get('hex').value;
    if (code === null || hex === null)
      return;
      
    clearTimeout(this.colorCodeTimer);  
    this.addColourForm.get('code').updateValueAndValidity();        
    this.colorCodeTimer = setTimeout(() => {
      const colorModel: ColourEditRequest = {
        code: code,
        hex: hex,
        starRating: 1,
      };
      this.api
        .checkColor(colorModel, this.updatedColorId)
        .then()
        .catch(err => {
          if (err.status == 409) {
            this.addColourForm.get('code').setErrors({ conflict: true });            
          }
        });
    }, 300);
  }

  addColour() {
    this.addColourForm.reset();
    this.updatedColorId = null;

    const dialogRef = this.dialog.open(this.addDialog, {
      width: '600px'
    });
    
    dialogRef.afterClosed().subscribe(value => {
      if (value) {        
        const model: ColourEditRequest = {
          code: this.addColourForm.get('code').value,
          hex: this.addColourForm.get('hex').value,
          starRating: this.addColourForm.get('starRating').value,
        };                
        this.api.addColour(model).then(res => this.getColours());
      }
    });
  }
  
  editColour(colour: Colour) {
    this.addColourForm.reset();
    this.updatedColorId = colour.guid;
    
    this.addColourForm.get('code').patchValue(colour.code);
    this.addColourForm.get('hex').patchValue(colour.hex);
    this.addColourForm.get('starRating').patchValue(colour.starRating);

    const dialogRef = this.dialog.open(this.addDialog, { data: colour });

    dialogRef.afterClosed().subscribe(value => {
      if (value instanceof Colour) {
        const model: ColourEditRequest = {
          code: this.addColourForm.get('code').value,
          hex: this.addColourForm.get('hex').value,
          starRating: this.addColourForm.get('starRating').value,
        };
        this.api.editColour(colour.guid, model).then(res => this.getColours());
      }
    });
  }

  deleteColour(colour: Colour) {
    const ref = this.dialog.open(this.deleteColourDialog, {
      data: colour.code,
    });

    ref.afterClosed().subscribe(value => {
      if (value) {
        this.api.deleteColour(colour.guid).then(x => this.getColours());
      }
    });
  }

  compareColourRating(o1: string, o2: number) {
    if (o1 && o2) {
      return Number(o1) === o2;
    }
  }

  pageChanged(ev: PageEvent) {
    this.getColours();
  }
}

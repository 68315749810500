const DEFAULT_SIZE = 100;

export function expandDataPoints(list: number[], size = DEFAULT_SIZE) {
  if (!list.length) {
    return [];
  }

  const result = [];
  const op = (list.length - 1) / size;

  for (let i = 0; i <= size; i++) {
    const index = i * op;
    const decimals = index % 1;

    if (!decimals) {
      result.push(list[index]);
      continue;
    }

    const prev = list[Math.floor(index)];
    const next = list[Math.ceil(index)];
    const value = prev + (next - prev) * decimals;
    result.push(value);
  }

  return result;
}

export function expandDataIndex(
  index: number,
  size = DEFAULT_SIZE,
  originalSize = 10,
) {
  return (index / originalSize) * size;
}

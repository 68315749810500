import { Location } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getState } from 'src/app/helpers/functions';
import { round } from 'src/app/helpers/locale-number';
import { Area } from 'src/app/models/Area';
import { AreaSurface } from 'src/app/models/AreaSurface';
import { IEditArea } from 'src/app/models/flow/flowInterfaces';
import { Project } from 'src/app/models/Project';
import { StoreService } from 'src/app/services/store-service.service';
import { sendAnalyticsEvent } from 'src/app/utils/analytics';

@Component({
  selector: 'app-add-edit-area',
  templateUrl: './add-edit-area.component.html',
  styleUrls: ['./add-edit-area.component.scss'],
})
export class AddEditAreaComponent implements OnInit, AfterContentChecked {
  project: Project;
  routerState: IEditArea;
  isInEditMode: boolean;
  area: Area;
  isAreaValid: boolean;
  surfacesValid = new Map<AreaSurface, boolean>();

  constructor(
    private readonly store: StoreService,
    private readonly translate: TranslateService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    this.routerState = getState(router) as IEditArea;
  }

  get surfacesStatus() {
    let valid = true;
    this.surfacesValid.forEach(x => {
      if (!x) {
        valid = x;
      }
    });
    // I'm sorry
    valid = this.area.surfaces.every(surface => (
      'name' in surface && surface.name.length > 0 &&
      'envFactors' in surface && surface.envFactors.length > 0 && 
      surface.envFactors.every(factor => factor !== null) && surface.envFactors.every(factor => factor !== undefined) &&
      'standardSize' in surface && surface.standardSize > 0 &&
      'totalSizeArea' in surface && surface.totalSizeArea > 0 &&
      'model' in surface
    ))
    return valid;
  }

  get areaIndex() {
    return this.routerState.index;
  }

  ngOnInit() {
    this.project = this.store.project;

    if (!this.project) {
      this.router.navigate(['/']);
      return;
    }

    if (!this.routerState) {
      this.router.navigate(['project/overview']);
      return;
    }

    this.area = this.store.area.clone();
    this.isInEditMode = !this.routerState.isNew;

    sendAnalyticsEvent(
      `${this.isInEditMode ? 'Edit' : 'Add'} Area accessed`,
      `Area: ${this.area.subsector ? this.area.subsector.name : 'New area'}`,
    );
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  cancel() {
    this.location.back();
  }

  save() {
    if (!this.area.name) {
      this.area.name = Area.getDefaultName(
        this.translate,
        this.project.type.code,
        this.areaIndex,
      );
    }

    if (this.routerState.isNew) {
      this.project.areas.push(this.area);
      sendAnalyticsEvent(
        'Area Added from Overview',
        `Area: ${this.area.subsector.name}`,
      );
    } else {
      this.area.surfaces.forEach(
        surface =>
          (surface.totalSizeArea = round(
            this.area.unitValue * surface.standardSize,
          )),
      );
      this.project.areas[this.routerState.index] = this.area;
      sendAnalyticsEvent(
        'Area Edited from Overview',
        `Area: ${this.area.subsector.name}`,
      );
    }
    this.location.back();
  }

  checkIfValid(valid) {
    this.isAreaValid = valid;

    if (valid && !this.area.surfaces.length) {
      this.area.surfaces.push(new AreaSurface());
    }
  }

  addNewSurface() {
    this.area.surfaces.push(new AreaSurface());
    sendAnalyticsEvent(
      'Surface added for New Area from Overview',
      `Area: ${this.area.subsector.name}`,
    );
  }

  removeSurface(surface: AreaSurface, index: number) {
    this.area.surfaces.splice(index, 1);
    if (!this.area.surfaces.length) {
      this.area.surfaces.push(new AreaSurface());
    }
    sendAnalyticsEvent(
      'Surface remove from Area Edit',
      `Surface: ${surface.model.name}; Area: ${this.area.subsector.name}`,
    );
  }

  checkValidation(value: boolean, surface: AreaSurface) {
    if (surface && surface.name) {
      this.surfacesValid.set(surface, value);
    }
  }
}

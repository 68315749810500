import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { sendAnalyticsEvent, sendAnalyticsEventWithoutParameter } from 'src/app/utils/analytics';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  guid: string;
  isTokenValid: boolean;
  loadingToken = true;
  passwordChanged = false;
  passwordChangeFailed = false;
  counter = 5;
  hide = true;

  newPassword: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
  ]);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly auth: AuthService,
    private readonly router: Router,
  ) {
    const guid = this.route.snapshot.queryParams.token;

    if (!guid) {
      this.isTokenValid = false;
      return;
    }

    this.guid = guid;

    this.auth
      .validateResetToken({ token: this.guid })
      .then(res => {
        this.loadingToken = false;
        this.isTokenValid = true;
      })
      .catch(err => {
        this.loadingToken = false;
        this.isTokenValid = false;
      });
  }

  ngOnInit() {
    if (!this.isTokenValid) {
      return;
    }
  }

  submitPassword() {
    this.auth
      .recoverPassword({
        token: this.guid,
        password: this.newPassword.value,
      })
      .then(res => {
        this.passwordChanged = true;
        sendAnalyticsEvent('Password Reseted', `Token: ${this.guid}`);
        const countDown = setInterval(() => {
          this.counter--;
          if (this.counter === 0) {
            clearInterval(countDown);
            this.router.navigate(['/login']);
          }
        }, 1000);
      })
      .catch(error => {
        this.passwordChanged = false;
        this.passwordChangeFailed = true;
        sendAnalyticsEventWithoutParameter(
          'Password Reset Failed'
        );
      });
  }
}

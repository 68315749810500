import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<LogoutComponent>) {}

  ngOnInit() {}
  confirm() {
    this.dialogRef.close(true);
  }
}

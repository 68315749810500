import { Component, Input, OnInit } from '@angular/core';

import { Location } from '@angular/common';

@Component({
  selector: 'app-header-back',
  templateUrl: './header-back.component.html',
  styleUrls: ['./header-back.component.scss'],
})
export class HeaderBackComponent implements OnInit {
  constructor(private readonly location: Location) {}
  @Input() customClass: string;

  ngOnInit() {}

  goBack() {
    this.location.back();
  }
}

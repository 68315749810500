import { Product } from 'src/app/models/api/Product';
import { ProductCheckRequest, ProductEditRequest } from 'src/app/models/requests/ProductRequests';
import { ApiService } from 'src/app/services/api.service';

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';

enum UploadState {
  tooLarge = 'tooLarge',
  default = 'default',
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  @ViewChild('addDialog') addDialog: TemplateRef<MatDialog>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  productsData: MatTableDataSource<Product>;
  products: Product[];
  displayedColumns: string[] = [
    'name',
    'paintCost',
    'co2e',
    'spreadingRate',
    'paintSpeed',
    'actions',
  ];

  addProductForm: FormGroup = new FormGroup({
    image: new FormControl(''),
    name: new FormControl('', Validators.required),
    paintCost: new FormControl('', Validators.required),
    paintSpeed: new FormControl('', Validators.required),
    spreadingRate: new FormControl('', Validators.required),
    co2e: new FormControl('', Validators.required),
  });

  uploadStatus: UploadState = UploadState.default;
  productTimer: any;

  get imageControl() {
    return this.addProductForm.get('image').value;
  }

  constructor(
    private readonly api: ApiService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.getProducts();
  }

  getProducts() {
    return this.api.getProducts().then(products => {
      this.products = products;
      this.productsData = new MatTableDataSource(this.products);
      this.productsData.paginator = this.paginator;
    });
  }

  getProductStatus(product: Product) {
    let complete = '';
    let isInLayers = '';

    if (!product.isComplete) {
      complete = 'Required Data Points are missing. ';
    }
    if (!product.isInLayers) {
      isInLayers = 'Product not added into a paint system yet.';
    }

    return complete + isInLayers;
  }

  addProduct() {
    const form = this.addProductForm;
    form.reset();
    const dialogRef = this.dialog.open(this.addDialog);

    dialogRef.afterClosed().subscribe(value => {
      if (value) {
        const model: ProductEditRequest = {
          name: form.get('name').value,
          co2PerLitre: form.get('co2e').value,
          image: form.get('image').value,
          paintSpeed: form.get('paintSpeed').value,
          pricePerLitre: form.get('paintCost').value,
          spreadingRatePerLitre: form.get('spreadingRate').value,
          standardRedecorationCycle: 1,
        };

        this.api.addProduct(model).then(product => {
          this.editProduct(product);
        });
      }
    });
  }

  editProduct(product: Product) {
    this.router.navigate(['/admin/product-edit', product.guid]);
  }

  uploadImage(event) {
    this.uploadStatus = UploadState.default;

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (url: any) => {
        // called once readAsDataURL is completed
        const image = new Image();
        image.src = url.target.result;

        image.onload = () => {
          if (image.width > 1024 || image.height > 768) {
            this.uploadStatus = UploadState.tooLarge;
            return;
          }
          // this.productModel.image = url.target.result;
          this.addProductForm.get('image').setValue(url.target.result);
        };
      };
    }
  }

  checkProduct() {
    clearTimeout(this.productTimer);        
    this.addProductForm.get('name').updateValueAndValidity();        
    this.productTimer = setTimeout(() => {
      const model: ProductCheckRequest = {
        name: this.addProductForm.get('name').value
      };
      this.api
        .checkProduct(model)
        .then()
        .catch(err => {
          if (err.status == 409) {
            this.addProductForm.get('name').setErrors({ conflict: true });            
          }
        });
    }, 300);
  }
}

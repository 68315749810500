import { BehaviorSubject } from 'rxjs';

import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatButtonToggleChange,
  MatMenuTrigger,
  MatTabGroup,
} from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { CalculationsProcessor } from '../../helpers/CalculationsProcessor';
import { LocaleFormatter } from '../../helpers/Formatter';
import { Area } from '../../models/Area';
import { AreaSurface } from '../../models/AreaSurface';
import { IRemoveSurface } from '../../models/flow/flowInterfaces';
import { Project } from '../../models/Project';
import { StoreService } from '../../services/store-service.service';
import { expandDataPoints } from '../../helpers/extendDataPoints';
import { ApiService } from 'src/app/services/api.service';
import { sendAnalyticsEvent } from 'src/app/utils/analytics';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
})
export class ProjectOverviewComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild('lifeCycleTabs') lifeCycleTabs: MatTabGroup;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  project: Project;
  refreshProductScroll = new EventEmitter<void>();
  comparisonEnabled = new BehaviorSubject<boolean>(false);
  reCalculate = new BehaviorSubject<boolean>(false);
  calculationChanged = new BehaviorSubject<boolean>(true);
  customerDemandApplied: boolean;

  formatter: LocaleFormatter;

  isSteppedChart = false;

  get currentLang() {
    return localStorage.getItem('lang');
  }

  get costOverLifeCycle() {
    const graphs = this.project.getCostChart();
    const data = this.isSteppedChart ? graphs.stepped : graphs.linear;
    const productTr = this.translate.instant('Product');
    const costTr = this.translate.instant('cost');
    const dataPointCount = this.project.lifeCycle * 10;

    return data.map((cost, i) => ({
      data: expandDataPoints(cost, dataPointCount),
      label: `${productTr} ${i + 1} ${costTr}`,
      steppedLine: this.isSteppedChart,
      lineTension: 0,
    }));
  }

  get co2eOverLifecycle() {
    const graphs = this.project.getCo2Chart();
    const data = this.isSteppedChart ? graphs.stepped : graphs.linear;
    const productTr = this.translate.instant('Product');

    return data.map((cost, i) => ({
      data: cost,
      label: `${productTr} ${i + 1} CO2e`,
      steppedLine: this.isSteppedChart,
      lineTension: 0,
    }));
  }

  get allProjectSurfaces() {
    const allSurfaces = [];

    this.project.areas.forEach(area => {
      area.surfaces.forEach(surface => allSurfaces.push(surface.model.name));
    });

    return allSurfaces;
  }

  get activeLifeCycleTab() {
    if (!this.project.isCO2Enabled) {
      return 0;
    }
    return this.lifeCycleTabs ? this.lifeCycleTabs.selectedIndex : 3;
  }

  constructor(
    private readonly router: Router,
    private readonly store: StoreService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly api: ApiService,
  ) {
    if (!store.project) {
      return router.navigate['/'];
    }

    this.project = store.project;
    this.project.setCalculationsProcessor(
      new CalculationsProcessor(store.project),
    );
  }

  ngOnInit() {
    this.formatter = new LocaleFormatter(this.translate);

    window.scroll(0, 0);
    document.body.classList.add('project-overview-page');
    this.calculationChanged.next(true);

    sendAnalyticsEvent(
      'Project Overview accessed',
      `Project Name: ${this.project.name || 'Unnamed'};
       Project Type: ${this.project.type.code};
        Sector: ${this.project.sector.name};
        Subsectors: ${this.project.areas.map(x => x.subsector.name).join(', ')};
        Surfaces: ${this.project.areas
          .map(x =>
            x.surfaces.map(
              y =>
                `${y.model.name} (IsRmi: ${
                  y.isRmi
                }, Product 1: ${this.getSurfaceProductName(
                  y,
                  0,
                )}, Product 2: ${this.getSurfaceProductName(y, 1)},
                    Colour: ${
                      (y.colour && y.colour.code) ||
                      'Not set, will have default colour'
                    }
                )`,
            ),
          )
          .join(', ')};
        IsComparison: ${this.project.isComparing};
        IsCo2Enabled: ${this.project.isCO2Enabled};
        LifeCycle: ${this.project.lifeCycle};
    `,
    );
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  getSurfaceProductName(surface: AreaSurface, productIndex: number) {
    if (surface.products[productIndex]) {
      return surface.products[productIndex].name;
    }
    return 'not set';
  }

  ngOnDestroy() {
    document.body.classList.remove('project-overview-page');
  }

  setDemandApplied(val: boolean) {
    this.customerDemandApplied = val;
  }

  @HostListener('window:scroll', [])
  scrollHandler() {
    this.trigger.closeMenu();
  }

  toggleComparison() {
    this.project.isComparing = !this.project.isComparing;
    sendAnalyticsEvent(
      'Project Comparison changed',
      `isComparing: ${this.project.isComparing}`,
    );
    // We need to wait for the DOM to be updated
    setTimeout(() => this.refreshProductScroll.emit(), 100);
  }

  addArea() {
    this.store.area = new Area();
    this.router.navigate(['project/add/area'], {
      state: {
        isNew: true,
        index: this.project.areas.length,
      },
    });
  }

  editArea(area: Area, index: number) {
    this.store.area = area;
    this.router.navigate(['project/edit/area'], {
      state: { index, isNew: false },
    });
  }

  deleteArea(area: AreaSurface, index: number) {
    this.project.areas.splice(index, 1);
    this.reCalculate.next(true);
    this.reInitCharts(true);
  }

  addSurface(area: Area) {
    this.store.area = area;
    this.router.navigate(['/project/add/surface'], {
      state: { isNew: true, surfaceIndex: null },
    });
  }

  removeSurface(removeModel: IRemoveSurface) {
    const area = this.project.areas.find(x => x.name === removeModel.area.name);
    area.surfaces.splice(removeModel.index, 1);
    this.reCalculate.next(true);
    sendAnalyticsEvent(
      'Surface Removed From Overview',
      `Surface: ${removeModel.area.subsector.name};`,
    );
  }

  getCalculations(areaSurface: AreaSurface, option: number) {
    const options = this.project.getSurfaceCalculations(areaSurface);
    return options && options.maintainance && options.maintainance[option];
  }

  getTotalCalculations(option: number) {
    return this.project.getTotal[option];
  }

  lifeCycleChanged() {
    this.reCalculate.next(true);
    this.calculationChanged.next(true);
    sendAnalyticsEvent('LifeCycle Changed', `Cycle: ${this.project.lifeCycle}`);
  }

  reInitCharts(value: boolean) {
    this.api
      .syncProject(this.store.project, this.store.projectGuid)
      .catch(error => console.error(error));
    this.calculationChanged.next(value);
  }

  changeTotalChartConfig(value: MatButtonToggleChange) {
    this.isSteppedChart = value.value;
    this.reInitCharts(true);
    sendAnalyticsEvent(
      'LifeCycle Chart Type Changed',
      `isStepped: ${this.isSteppedChart}`,
    );
  }

  round(value: number) {
    return Math.round(value * 100) / 100;
  }
}

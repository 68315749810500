export class EnvFactor implements IEnvFactorJson {
  static fromJson(json: IEnvFactorJson) {
    return new EnvFactor(json);
  }

  readonly guid: string;
  readonly isSurface: boolean;
  readonly label: string;
  readonly values: Array<Readonly<IEnvFactorOption>>;

  private constructor(json: IEnvFactorJson) {
    Object.assign(this, json);
  }
}

export interface IEnvFactorJson {
  guid: string;
  isSurface: boolean;
  label: string;
  values: IEnvFactorOption[];
}

export interface IEnvFactorOption {
  readonly guid: string;
  readonly image: string;
  readonly label: string;
  readonly multiplier: number;
}

import { ChartsModule } from 'ng2-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { environment } from 'src/environments/environment';

import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import loc_en from '@angular/common/locales/en-GB';
import loc_nl from '@angular/common/locales/nl';
import loc_tr from '@angular/common/locales/tr';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslatePoHttpLoader } from '@biesbjerg/ngx-translate-po-http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AreaDetailComponent } from './components/area-details/area-details.component';
import { FabButtonComponent } from './components/fab-button/fab-button.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HeaderBackComponent } from './components/header-back/header-back.component';
import { HeaderOverviewComponent } from './components/header-overview/header-overview.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoutComponent } from './components/logout/logout.component';
import { DeleteConfirmComponent } from './components/delete-confirm/delete-confirm.component';
import { SurfaceChartComponent } from './components/surface-chart/surface-chart.component';
import { SurfaceCommentComponent } from './components/surface-comment/surface-comment.component';
import { SurfaceDetailsComponent } from './components/surface-details/surface-details.component';
import { SurfaceProductComponent } from './components/surface-product/surface-product.component';
import { SurfaceResetComponent } from './components/surface-reset/surface-reset.component';
import { CurrencyFormat } from './helpers/dutch-currency-pipe';
import { GetArrayFromEnumPipe } from './helpers/get-array-from-enum.pipe';
import { GetUpperFirstLetter } from './helpers/get-upper-first-letter.pipe';
import { RequestInterceptor } from './helpers/request.interceptor';
import { singleBracketParamsHack } from './helpers/translations';
import { MaterialModule } from './material/material.module';
import { AddEditAreaComponent } from './pages/add-edit-area/add-edit-area.component';
import { AddEditSurfaceComponent } from './pages/add-edit-surface/add-edit-surface.component';
import { AdminModule } from './pages/admin/admin.module';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { LabourRatesComponent } from './pages/labour-rates/labour-rates.component';
import { LandingComponent } from './pages/landing/landing.component';
import { LoginComponent } from './pages/login/login.component';
import { MenuComponent } from './pages/menu/menu.component';
import { NewWindowProjectComponent } from './pages/new-window-project/new-window-project.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { ProjectCreateComponent } from './pages/project-create/project-create.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { ProjectOverviewComponent } from './pages/project-overview/project-overview.component';
import { ProjectPreviewComponent } from './pages/project-preview/project-preview.component';
import { ProjectShareComponent } from './pages/project-share/project-share.component';
import { ProjectSurfacesComponent } from './pages/project-surfaces/project-surfaces.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { MyProjectsComponent } from './pages/my-projects/my-projects.component';
import { LifeCycleErrorHandler } from './services/LifeCycleErrorHandler';
import { HorizontalScrollComponent } from './widgets/horizontal-scroll/horizontal-scroll.component';
import { PasswordResetConfirmationComponent } from './components/password-reset-confirmation/password-reset-confirmation.component';
import { EnvironmentRibbonComponent } from './components/environment-ribbon/environment-ribbon.component';
import { PrivacyPolicy } from './pages/privacy-policy/privacy-policy.component';
import { CookiePolicy } from './pages/cookie-policy/cookie-policy.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslatePoHttpLoader(http, 'assets/i18n', '.po');
}

registerLocaleData(loc_nl, 'nl');
registerLocaleData(loc_en, 'en');
// registerLocaleData(loc_tr, 'tr');

@NgModule({
  declarations: [
    AppComponent,
    ChangePasswordComponent,
    SurfaceChartComponent,
    AreaDetailComponent,
    EnvironmentRibbonComponent,
    FabButtonComponent,
    FooterComponent,
    GetArrayFromEnumPipe,
    GetUpperFirstLetter,
    CurrencyFormat,
    HeaderComponent,
    HeaderOverviewComponent,
    LandingComponent,
    LoginComponent,
    MenuComponent,
    NotfoundComponent,
    ProjectCreateComponent,
    ProjectDetailsComponent,
    ProjectOverviewComponent,
    ProjectShareComponent,
    ProjectSurfacesComponent,
    SurfaceDetailsComponent,
    SurfaceProductComponent,
    ProjectPreviewComponent,
    LogoutComponent,
    PasswordResetConfirmationComponent,
    DeleteConfirmComponent,
    LabourRatesComponent,
    HeaderBackComponent,
    AddEditSurfaceComponent,
    AddEditAreaComponent,
    ForgotPasswordComponent,
    SurfaceCommentComponent,
    ResetPasswordComponent,
    NewWindowProjectComponent,
    HorizontalScrollComponent,
    SurfaceResetComponent,
    MyProjectsComponent,
    PrivacyPolicy,
    CookiePolicy,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    HttpClientModule,
    FormsModule,
    ChartsModule,
    NgxMatSelectSearchModule,
    PdfViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AdminModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: LifeCycleErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    LogoutComponent,
    PasswordResetConfirmationComponent,
    DeleteConfirmComponent,
    ForgotPasswordComponent,
    SurfaceCommentComponent,
    SurfaceResetComponent,
  ],
})
export class AppModule {
  constructor(parser: TranslateParser) {
    singleBracketParamsHack(parser);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { SectorsComponent } from './pages/sectors/sectors.component';
import { MaterialModule } from 'src/app/material/material.module';
import { SurfacesComponent } from './pages/surfaces/surfaces.component';
import { EnvFactorsComponent } from './pages/env-factors/env-factors.component';
import { ProductsComponent } from './pages/products/products.component';
import { ColoursComponent } from './pages/colours/colours.component';
import { PaintSystemsComponent } from './pages/paint-systems/paint-systems.component';
import { CustomerDemandsComponent } from './pages/customer-demands/customer-demands.component';
import { ParametersComponent } from './pages/parameters/parameters.component';
import { PaintSystemDetailsComponent } from './pages/paint-system-details/paint-system-details.component';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';
import { SectorDetailsComponent } from './pages/sector-details/sector-details.component';
import { AdminSurfaceDetailsComponent } from './pages/surface-details/surface-details.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { DutchTranslate } from 'src/app/helpers/dutch-translate.pipe';
import { UsersComponent } from './pages/users/users.component';
import { AdminFooterComponent } from './pages/footer/footer.component';

@NgModule({
  declarations: [
    AdminComponent,
    HeaderComponent,
    SectorsComponent,
    DutchTranslate,
    SectorDetailsComponent,
    SurfacesComponent,
    AdminSurfaceDetailsComponent,
    EnvFactorsComponent,
    ProductsComponent,
    ProductDetailsComponent,
    ColoursComponent,
    PaintSystemsComponent,
    PaintSystemDetailsComponent,
    CustomerDemandsComponent,
    ParametersComponent,
    UsersComponent,
    AdminFooterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    MaterialModule,
  ],
  bootstrap: [AdminComponent],
})
export class AdminModule {}

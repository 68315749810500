import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { sendAnalyticsEventWithoutParameter } from 'src/app/utils/analytics';

enum ResetStatus {
  default = 'default',
  failed = 'failed',
  success = 'success',
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  resetStatus: ResetStatus = ResetStatus.default;

  passwordHide=true;
  passwordHideRepeat=true;

  public pwdForm = new FormGroup({
    pwd1: new FormControl('', [Validators.required, Validators.minLength(6)]),
    pwd2: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });

  constructor(
    private readonly router: Router,
    private readonly auth: AuthService,
  ) {}

  ngOnInit() {}

  checkPasswordMatch() {
    const pwd1 = this.pwdForm.controls['pwd1'];
    const pwd2 = this.pwdForm.controls['pwd2'];

    return pwd1.value !== pwd2.value
      ? pwd2.setErrors({ noMatch: true })
      : pwd2.clearValidators();
  }

  submit() {
    this.resetStatus = ResetStatus.default;
    const pwd1 = this.pwdForm.controls['pwd1'];
    const pwd2 = this.pwdForm.controls['pwd2'];

    pwd1.value !== pwd2.value
      ? pwd2.setErrors({ noMatch: true })
      : this.auth.resetPassword(pwd1.value).then(response => {
          response.status !== 200
            ? (this.resetStatus = ResetStatus.failed)
            : (this.resetStatus = ResetStatus.success);

            sendAnalyticsEventWithoutParameter(
            'Password Changed'
          );
        });
  }

  exitLogIn() {
    this.router.navigate(['']);
  }
}

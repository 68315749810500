import { Injectable } from '@angular/core';

import { Project } from '../models/Project';
import { ApiService } from './api.service';
import { Area } from '../models/Area';

const sessionProjectToken = 'userProject';
const sessionProjectGuid = 'userProjectGuid';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private userProjectGuid: string;
  private userProject: Project;

  private editableArea: Area;
  private projectsCreated = 0;

  constructor(private readonly api: ApiService) { }

  get project() {
    if (!this.userProject) {
      const stored = sessionStorage.getItem(sessionProjectToken);
      const json = JSON.parse(stored);

      if (json) {
        this.userProject = Project.fromJson(this.api, json);
      }
    }

    return this.userProject;
  }

  set project(model: Project) {
    sessionStorage.setItem(sessionProjectToken, JSON.stringify(model));
    this.userProject = model;
  }

  set projectGuid(projectGuid: string) {
    sessionStorage.setItem(sessionProjectGuid, projectGuid.toString());
    this.userProjectGuid = projectGuid;
  }

  get projectGuid(): string {
    return this.userProjectGuid || sessionStorage.getItem(sessionProjectGuid);
  }

  get area() {
    return this.editableArea;
  }

  set area(area: Area) {
    this.editableArea = area;
  }

  get projectCount() {
    return this.projectsCreated;
  }

  addProjectCount() {
    this.projectsCreated++;
  }

  clearStorage() {
    this.userProject = null;
    sessionStorage.removeItem(sessionProjectToken);
    sessionStorage.removeItem(sessionProjectGuid);
    this.api.clearCache();
  }
}

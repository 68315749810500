import { ISubsectorJson, Subsector } from './Subsector';

export class Sector implements ISector {
  static fromJson(json: ISectorJson) {
    return new Sector({
      ...json,
      subsectors: json.subsectors && json.subsectors.map(Subsector.fromJson),
    });
  }

  readonly guid: string;
  readonly name: string;
  readonly image: string;
  readonly subsectors: Subsector[];

  private constructor(json?: ISector) {
    if (json) {
      Object.assign(this, json);
    }
  }
}

interface ISector extends ISectorJson {
  readonly subsectors: Subsector[];
}

export interface ISectorJson {
  guid: string;
  name: string;
  image: string;
  subsectors: ISubsectorJson[];
}

import { ApiService } from '../../services/api.service';
import { IProductJson, Product } from './Product';

export class PaintLayer implements IPaintLayer {
  static fromJson(api: ApiService, json: IPaintLayerJson) {
    return new PaintLayer({
      ...json,
      product: Product.fromJson(api, json.product),
    });
  }

  readonly guid: string;
  readonly name: string;
  readonly numberOfCoats: number;
  readonly product: Product;

  private constructor(json: IPaintLayer) {
    Object.assign(this, json);
  }

  getLabourCostFor(area: number, labourCost: number): number {
    return (area / this.product.paintSpeed) * labourCost * this.numberOfCoats;
  }

  getCostFor(area: number) {
    return this.product.getCostFor(area) * this.numberOfCoats;
  }

  getCo2For(area: number) {
    return this.product.getCo2For(area) * this.numberOfCoats;
  }
}

interface IPaintLayer extends IPaintLayerJson {
  readonly product: Product;
}

export interface IPaintLayerJson {
  guid: string;
  name: string;
  numberOfCoats: number;
  product: IProductJson;
}

import { Component, OnDestroy, OnInit, EventEmitter } from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ShareReportRequest } from 'src/app/models/requests/ShareReport';
import { StoreService } from 'src/app/services/store-service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-project-share',
  templateUrl: './project-share.component.html',
  styleUrls: ['./project-share.component.scss'],
})
export class ProjectShareComponent implements OnInit, OnDestroy {
  downloadTrigger: BehaviorSubject<boolean> = new BehaviorSubject(false);
  sendReportTrigger: ReplaySubject<ShareReportRequest> = new ReplaySubject();
  isLoading: boolean;

  emailForm: FormGroup = new FormGroup({
    emails: new FormControl('', [Validators.required, Validators.email]),
    details: new FormControl(''),
  });

  constructor(
    private readonly router: Router,
    private readonly store: StoreService,
    private readonly lang: TranslateService
  ) { }

  ngOnInit() {
    document.body.classList.add('u-overflow-hidden');
  }

  ngOnDestroy() {
    document.body.classList.remove('u-overflow-hidden');
  }

  isInvalid() {
    return this.emailForm.invalid;
  }

  downloadPdf() {
    if (this.isLoading) {
      return;
    }
    this.downloadTrigger.next(true);
  }

  exitLogIn() {
    this.router.navigate(['']);
  }

  isPdfGenerated(value: boolean) {
    if (value) {
      return (this.isLoading = true);
    }

    this.isLoading = false;
  }

  sendReport() {
    const model: ShareReportRequest = {
      message: this.emailForm.get('details').value,
      projectName: this.store.project.name,
      toEmails: this.emailForm.get('emails').value.split(','),
      language: this.lang.currentLang,
    };

    this.sendReportTrigger.next(model);
  }
}

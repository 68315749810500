import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store-service.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  get project() {
    return this.store.project;
  }

  constructor(
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly store: StoreService,
  ) {}

  exitLogIn() {
    this.router.navigate(['']);
  }

  logout() {
    this.auth.logoutUser();
  }

  getStarted() {
    window.open('project/create-new', '_blank');
  }
}

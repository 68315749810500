import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewChecked,
} from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { MatSelectChange } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  public href = '';

  constructor(
    private readonly translate: TranslateService,
    private readonly authService: AuthService,
    private readonly cdRef: ChangeDetectorRef,
  ) { }

  setLanguage(lang: MatSelectChange) {
    this.translate.use(lang.value);
    localStorage.setItem('lang', lang.value);
  }

  get currentLanguage() {
    return localStorage.getItem('lang') || this.translate.currentLang;
  }

  get auth() {
    return this.authService;
  }

  ngOnInit() { }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  resetPassword() {
    this.auth.sendPasswordResetMail(this.translate.currentLang);
  }

  logout() {
    this.auth.logoutUser();
  }
}

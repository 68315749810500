import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AreaSurface } from 'src/app/models/AreaSurface';
import { sendAnalyticsEvent } from 'src/app/utils/analytics';

@Component({
  selector: 'app-surface-comment',
  templateUrl: './surface-comment.component.html',
  styleUrls: ['./surface-comment.component.scss'],
})
export class SurfaceCommentComponent implements OnInit {
  surface: AreaSurface;

  constructor(
    public dialogRef: MatDialogRef<SurfaceCommentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { surface: AreaSurface; surfaceIndex: string },
  ) {}

  ngOnInit() {
    this.surface = this.data.surface.clone();
  }

  saveComment() {
    this.data.surface.comment = this.surface.comment;
    sendAnalyticsEvent(
      'Comment added',
      `Surface: ${this.data.surface.model.name}`,
    );
    this.dialogRef.close();
  }
}

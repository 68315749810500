import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private readonly auth: AuthService,
    private readonly router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const cloneRequest = req.clone({
      withCredentials: true,
    });

    return next.handle(cloneRequest).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((x) => this.handleAuthError(x)),
    );
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      this.auth.clearSession();      
      this.router.navigate(['login'], {
        queryParams: { redirect: this.router.url },
      });
      return of(err.message);
    }
    return throwError(err);
  }
}

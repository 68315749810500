import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { DeleteConfirmComponent } from 'src/app/components/delete-confirm/delete-confirm.component';
import { StoreService } from 'src/app/services/store-service.service';
import { Project, IProjectJson } from 'src/app/models/Project';
import { ApiService } from 'src/app/services/api.service';
import { GetProjectsResponseProjectItem } from 'src/app/models/api/Project';

interface Sublist {
  title: string;
  items: GetProjectsResponseProjectItem[];
}

@Component({
  selector: 'app-my-projects',
  templateUrl: './my-projects.component.html',
  styleUrls: ['./my-projects.component.scss'],
})
export class MyProjectsComponent implements OnInit {
  searchForm: FormGroup = new FormGroup({
    input: new FormControl(''),
  });

  numberOfProjects: number = 0;
  sortBy: 'projectName' | 'dateCreated' | 'dateUpdated' = 'dateCreated';
  sortDirection: 'ASC' | 'DESC' = 'DESC';

  splitList: Sublist[] = [];

  page = 1;
  pageSize = 20;

  isLoading = false;
  currentHeaderTitle: string = 'Today';
  isScrolled: boolean = false;
  rippleDisabled: boolean = false;
  _hoverItemId: [number, number] = [-1, -1];

  constructor(
    private readonly dialog: MatDialog,
    private readonly translate: TranslateService,
    private readonly auth: AuthService,
    private readonly store: StoreService,
    private readonly router: Router,
    private readonly api: ApiService,
  ) {}

  ngOnInit() {
    document.body.classList.remove('landing-page');
    this.getProjects(true);
  }

  // Design-related
  dateString(date: string) {
    return formatDate(
      new Date(date),
      'MMM dd, yyyy',
      this.translate.currentLang,
    );
  }

  pdfDateString(date: string) {
    return formatDate(new Date(date), 'yyyMMdd', this.translate.currentLang);
  }

  get currentLang() {
    return localStorage.getItem('lang');
  }

  checkHoverItemId(
    itemIndex: number,
    sublistIndex: number,
    subitemsLength: number,
  ) {
    const isSameSublist = this._hoverItemId[1] === sublistIndex;
    const isSameItem =
      this._hoverItemId[0] === itemIndex + 1 ||
      (this._hoverItemId[0] === itemIndex && subitemsLength < 1);
    return !(isSameItem && isSameSublist);
  }

  setHoverItemId(itemIndex: number, sublistIndex: number) {
    this._hoverItemId = [itemIndex, sublistIndex];
  }

  setHeaderTitle() {
    const elements = Array.from(document.querySelectorAll('.section_title'));
    for (let element of elements) {
      if (element.getBoundingClientRect().top < 150)
        this.currentHeaderTitle = element.firstChild.textContent;
    }
  }

  onWindowScroll() {
    this.setHeaderTitle();
    this.isScrolled = window.pageYOffset > 0;

    if (
      !this.isLoading &&
      window.innerHeight + window.pageYOffset + 100 >=
        document.body.offsetHeight
    ) {
      this.isLoading = true;
      this.page += 1;
      this.getProjects(false);
    }
  }

  handleMenu(event) {
    event.stopPropagation();
  }

  navigateToCreateProject() {
    sessionStorage.removeItem('userProject');
    sessionStorage.removeItem('userProjectGuid');
    this.router.navigate(['/project/create']);
  }

  // Project list
  addItemToSplitList(
    title: string,
    project: GetProjectsResponseProjectItem,
  ): void {
    const ref = this.splitList.find(v => v.title === title);
    if (ref) ref.items.push(project);
  }

  getDayDifference(startDate: Date): number {
    const now = new Date();
    return Math.round(
      Math.abs((now.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)),
    );
  }

  createMissingSection(title: string): string {
    const ref = this.splitList.find(v => v.title === title);
    if (ref === undefined) this.splitList.push({ title: title, items: [] });
    this.translate.get(title);
    return title;
  }

  checkSections(project: GetProjectsResponseProjectItem): string {
    if (this.sortBy === 'dateUpdated' || this.sortBy === 'dateCreated') {
      const differenceInDays =
        this.sortBy === 'dateCreated'
          ? this.getDayDifference(new Date(project.dateCreated))
          : this.getDayDifference(new Date(project.dateUpdated));

      if (differenceInDays < 2) return this.createMissingSection('Today');
      else if (differenceInDays < 8)
        return this.createMissingSection('7 Days ago');
      else if (differenceInDays < 31)
        return this.createMissingSection('30 Days ago');
      else return this.createMissingSection('Earlier');
    } else if (this.sortBy === 'projectName') {
      return this.createMissingSection(project.projectName[0].toUpperCase());
    }
  }

  splitAndPlace(projectItemsArray: GetProjectsResponseProjectItem[]) {
    for (let index in projectItemsArray) {
      const title = this.checkSections(projectItemsArray[index]);
      this.addItemToSplitList(title, projectItemsArray[index]);
    }

    if (this.sortBy === 'projectName' && this.sortDirection === 'ASC')
      this.splitList.sort((a, b) => (a.title > b.title ? 1 : -1));
    else if (this.sortBy === 'projectName' && this.sortDirection === 'DESC')
      this.splitList.sort((a, b) => (a.title < b.title ? 1 : -1));
    else {
      const sortingArray = ['Today', '7 Days ago', '30 Days ago', 'Earlier'];
      this.splitList.sort(
        (a, b) => sortingArray.indexOf(a.title) - sortingArray.indexOf(b.title),
      );
    }

    this.currentHeaderTitle = this.splitList[0].title;
    this.isLoading = false;
  }

  // Search
  isSearchValid() {
    const value = this.searchForm.controls['input'].value || '';
    return this.searchForm.valid && value.trim() !== '';
  }

  clearSearch() {
    this.searchForm.reset();
    this.getProjects(true);
  }

  handleSearch(event) {
    if (event.key === 'Enter') {
      this.getProjects(true);
    }
  }

  // Handlers
  logout() {
    this.auth.logoutUser();
  }

  resetPassword() {
    this.auth.sendPasswordResetMail(this.translate.currentLang);
  }

  changeSort(
    type: 'projectName' | 'dateCreated' | 'dateUpdated',
    direction: 'ASC' | 'DESC',
    shouldGetProjects: boolean,
  ) {
    this.sortBy = type;
    this.sortDirection = direction;
    if (shouldGetProjects) this.getProjects(true);
  }

  getProject(projectGuid: string, projectName: string) {
    this.store.clearStorage();
    this.api
      .getProject(projectGuid)
      .then(response => {
        this.store.projectGuid = projectGuid;
        const tempProject = JSON.parse(response.projectData);
        if (tempProject.name !== projectName) tempProject.name = projectName;
        this.store.project = Project.fromJson(this.api, tempProject);
        this.router.navigate(['project/overview']);
      })
      .catch(error => console.error(error));
  }

  duplicateProject(projectGuid: string) {
    this.api
      .duplicateProject(projectGuid)
      .then(() => {
        this.changeSort('dateCreated', 'DESC', false);
        this.getProjects(true);
      })
      .catch(error => console.error(error));
  }

  deleteProject(projectGuid: string) {
    const dialog = this.dialog.open(DeleteConfirmComponent, {
      panelClass: 'cdk-overlay-pane--medium',
      autoFocus: false,
    });

    dialog.afterClosed().subscribe(value => {
      if (value) {
        this.api
          .deleteProject(projectGuid)
          .then(() => {
            this.changeSort('dateCreated', 'DESC', false);
            this.getProjects(true);
          })
          .catch(error => console.error(error));
        this.store.clearStorage();
      }
    });
  }

  getProjects(cleanCurrentList: boolean) {
    if (cleanCurrentList) {
      this.splitList = [];
      this.numberOfProjects = 0;
      this.page = 1;
    }

    this.api
      .getProjects(
        this.isSearchValid() ? this.searchForm.controls['input'].value : null,
        this.sortBy,
        this.sortDirection,
        this.page,
        this.pageSize,
      )
      .then(response => {
        this.numberOfProjects = response.totalCount;
        this.splitAndPlace(response.projects);
      })
      .catch(error => console.error(error));
  }

  downloadPdf(pdfUrl: string) {
    window.open(pdfUrl, '_blank');
  }
}

import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { sendAnalyticsEvent } from '../utils/analytics';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  constructor(
    private readonly auth: AuthService,
  ) {}

  handleGenericError(error: Error) {
    this.report(error, 'App Error');
  }

  private report(error: Error, label: string) {
    console.error(error);
    sendAnalyticsEvent(
      label,
      JSON.stringify({
        location: window.location.toString(),
        message: error.message,
        stack: error.stack,
        date: new Date().toUTCString(),
      }),
      true
    );
  }
}

import { ApiService } from '../../services/api.service';
import { IPaintLayerJson, PaintLayer } from './PaintLayer';
import { Surface } from './Surface';

const FINISHING = 'Finishing System'.toLowerCase();

export class PaintSystem implements IPaintSystem {
  static fromJson(api: ApiService, json: IPaintSystemJson) {
    return new PaintSystem({
      ...json,
      layers: sortLayers(json.layers.map(x => PaintLayer.fromJson(api, x))),
      surface: json.surface && Surface.fromJson(json.surface),
    });
  }

  readonly guid: string;
  readonly reference: string;
  readonly name: string;
  readonly layers: PaintLayer[];
  readonly surface: Surface;

  private constructor(json: IPaintSystem) {
    Object.assign(this, json);
  }

  getLabourCostFor(area: number, labourCost: number, isRmi: boolean) {
    return this.add(isRmi, layer => layer.getLabourCostFor(area, labourCost));
  }

  getCostFor(area: number, isRmi: boolean) {
    return this.add(isRmi, layer => layer.getCostFor(area));
  }

  getCo2For(area: number, isRmi: boolean) {
    return this.add(isRmi, layer => layer.getCo2For(area));
  }

  private add(isRmi: boolean, getter: (layer: PaintLayer) => number) {
    return this.getCalcLayers(isRmi).reduce((sum, x) => sum + getter(x), 0);
  }

  private getCalcLayers(isRmi: boolean) {
    return isRmi ? this.layers.slice(1) : this.layers;
  }
}

interface IPaintSystem extends IPaintSystemJson {
  readonly layers: PaintLayer[];
  readonly surface: Surface;
}

export interface IPaintSystemJson {
  guid: string;
  reference: string;
  name: string;
  layers: IPaintLayerJson[];
  surface: Surface;
}

function sortLayers(layers: PaintLayer[]) {
  const primer = layers.filter(x => x.name.toLowerCase() !== FINISHING);
  const finishing = layers.filter(x => x.name.toLowerCase() === FINISHING);
  return [...primer, ...finishing];
}

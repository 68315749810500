import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthAdminGuard } from './helpers/auth-admin.guard';
import { AuthClientGuardChild, AuthClientGuard } from './helpers/auth-client.guard';
import { AddEditAreaComponent } from './pages/add-edit-area/add-edit-area.component';
import { AddEditSurfaceComponent } from './pages/add-edit-surface/add-edit-surface.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ColoursComponent } from './pages/admin/pages/colours/colours.component';
import { CustomerDemandsComponent } from './pages/admin/pages/customer-demands/customer-demands.component';
import { EnvFactorsComponent } from './pages/admin/pages/env-factors/env-factors.component';
import { PaintSystemDetailsComponent } from './pages/admin/pages/paint-system-details/paint-system-details.component';
import { PaintSystemsComponent } from './pages/admin/pages/paint-systems/paint-systems.component';
import { ParametersComponent } from './pages/admin/pages/parameters/parameters.component';
import { ProductDetailsComponent } from './pages/admin/pages/product-details/product-details.component';
import { ProductsComponent } from './pages/admin/pages/products/products.component';
import { SectorDetailsComponent } from './pages/admin/pages/sector-details/sector-details.component';
import { SectorsComponent } from './pages/admin/pages/sectors/sectors.component';
import { AdminSurfaceDetailsComponent } from './pages/admin/pages/surface-details/surface-details.component';
import { SurfacesComponent } from './pages/admin/pages/surfaces/surfaces.component';
import { UsersComponent } from './pages/admin/pages/users/users.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { LabourRatesComponent } from './pages/labour-rates/labour-rates.component';
import { LandingComponent } from './pages/landing/landing.component';
import { LoginComponent } from './pages/login/login.component';
import { MenuComponent } from './pages/menu/menu.component';
import { NewWindowProjectComponent } from './pages/new-window-project/new-window-project.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { ProjectCreateComponent } from './pages/project-create/project-create.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { ProjectOverviewComponent } from './pages/project-overview/project-overview.component';
import { ProjectPreviewComponent } from './pages/project-preview/project-preview.component';
import { ProjectShareComponent } from './pages/project-share/project-share.component';
import { ProjectSurfacesComponent } from './pages/project-surfaces/project-surfaces.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { MyProjectsComponent } from './pages/my-projects/my-projects.component';
import { PrivacyPolicy } from './pages/privacy-policy/privacy-policy.component';
import { CookiePolicy } from './pages/cookie-policy/cookie-policy.component';

const adminRoutes = {
  path: 'admin',
  canActivate: [AuthAdminGuard],
  canActivateChild: [AuthAdminGuard],
  component: AdminComponent,
  children: [
    {
      path: '',
      redirectTo: 'sectors',
      pathMatch: 'full',
    },
    { path: 'sectors', component: SectorsComponent },
    { path: 'sector-edit', component: SectorDetailsComponent },
    { path: 'surfaces', component: SurfacesComponent },
    { path: 'surface-edit', component: AdminSurfaceDetailsComponent },
    { path: 'env-factors', component: EnvFactorsComponent },
    { path: 'products', component: ProductsComponent },
    { path: 'product-edit/:id', component: ProductDetailsComponent },
    { path: 'colours', component: ColoursComponent },
    { path: 'paint-systems', component: PaintSystemsComponent },
    { path: 'paint-system-edit', component: PaintSystemDetailsComponent },
    { path: 'demands', component: CustomerDemandsComponent },
    { path: 'parameters', component: ParametersComponent },
    { path: 'users', component: UsersComponent },
  ],
};

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'privacy-policy', component: PrivacyPolicy },
  { path: 'cookie-policy', component: CookiePolicy },
  { path: 'login', component: LoginComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'reset', component: ResetPasswordComponent },
  { path: 'my-projects', canActivate: [AuthClientGuard], component: MyProjectsComponent },
  {
    path: 'project',
    canActivateChild: [AuthClientGuardChild],
    children: [
      { path: 'create', component: ProjectCreateComponent, data: { step: 1 } },
      { path: 'create-new', component: NewWindowProjectComponent },
      {
        path: 'surfaces',
        component: ProjectSurfacesComponent,
        data: { step: 2 },
      },
      { path: 'overview', component: ProjectOverviewComponent },
      { path: 'share', component: ProjectShareComponent },
      { path: 'details', component: ProjectDetailsComponent },
      { path: 'pdf/preview', component: ProjectPreviewComponent },
      { path: 'labour', component: LabourRatesComponent },
      { path: 'add/surface', component: AddEditSurfaceComponent },
      { path: 'edit/surface', component: AddEditSurfaceComponent },
      { path: 'add/area', component: AddEditAreaComponent },
      { path: 'edit/area', component: AddEditAreaComponent },
    ],
  },
  adminRoutes,
  { path: 'menu', component: MenuComponent },
  { path: '**', component: NotfoundComponent },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 150],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

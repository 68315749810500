import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-environment-ribbon',
  templateUrl: './environment-ribbon.component.html',
  styleUrls: ['./environment-ribbon.component.scss'],
})
export class EnvironmentRibbonComponent implements OnInit {
  showRibbon: boolean;

  ngOnInit() {
    if (environment.env === 'staging') this.showRibbon = true;
    else this.showRibbon = false;
  }
}

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthClientGuardChild implements CanActivateChild {
  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
  ) {}
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    if (!this.auth.isAuthenticated) {
      return this.navigateToLogin(state);
    }

    return this.auth.checkSession().then(() => {
      if (this.auth.isAuthenticated) {                
        return true;
      } else {
        this.auth.clearSession();
        return this.navigateToLogin(state);
      }
    }).catch(() => {
      this.auth.clearSession();
      return this.navigateToLogin(state);
    });        
  }

  navigateToLogin(state: RouterStateSnapshot) {
    return this.router.navigate(['login'], {
      queryParams: { redirect: state.url },
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthClientGuard implements CanActivate {
  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.auth.isAuthenticated) {
      return true;
    }

    return this.router.navigate(['login'], {
      queryParams: { redirect: state.url },
    });
  }
}

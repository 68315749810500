import { Component, OnInit, ViewChild } from '@angular/core';
import { Surface } from 'src/app/models/api/Surface';
import {
  MatTableDataSource,
  MatTabChangeEvent,
  MatTabGroup,
} from '@angular/material';
import { ProjectTypeCode } from 'src/app/models/api/ProjectType';
import { ApiService } from 'src/app/services/api.service';
import { AreaSurface } from 'src/app/models/AreaSurface';
import { CustomerDemand } from 'src/app/models/api/CustomerDemand';
import { Router } from '@angular/router';

@Component({
  selector: 'app-surfaces',
  templateUrl: './surfaces.component.html',
  styleUrls: ['./surfaces.component.scss'],
})
export class SurfacesComponent implements OnInit {
  @ViewChild('surfaceTabs') surfaceTabs: MatTabGroup;

  projectType: ProjectTypeCode;
  surfacesData: MatTableDataSource<Surface>;
  displayedColumns: string[] = ['image', 'name', 'customerDemands', 'actions'];

  constructor(
    private readonly api: ApiService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.getSurfaces('interior');
  }

  changeProjectType(tab: MatTabChangeEvent) {
    this.getSurfaces(tab.tab.ariaLabel as ProjectTypeCode);
  }

  getSurfaces(type: ProjectTypeCode) {
    const data: Surface[] = [];
    this.api.getProjectTypeSurfaces(type).then(surfaces => {
      this.surfacesData = new MatTableDataSource(surfaces);
    });
  }

  editSurface(surface: Surface) {
    this.router.navigate(['/admin/surface-edit'], { state: surface });
  }
}

import { ChartLegendLabelItem, ChartOptions } from 'chart.js';
import * as merge from 'deepmerge';
import { Colors } from 'ng2-charts';

import { LocaleFormatter } from './Formatter';
import { round } from './locale-number';

export type Theme = 'dark' | 'light';

const DEFAULT_COLOURS = {
  backgroundColor: 'transparent',
  pointBackgroundColor: 'transparent',
  borderWidth: 2,
  pointBorderWidth: 0,
  pointHitRadius: 10,
  pointRadius: 0,
};

export function getDashing() {
  return [4, 2];
}

export function getChartColour(colour: string): Colors {
  return Object.assign({ borderColor: colour }, DEFAULT_COLOURS);
}

const DEFAULT_CHART_CONFIG: ChartOptions = {
  responsive: true,
  animation: null,
  maintainAspectRatio: false,
  legend: {
    position: 'bottom',
    labels: {
      usePointStyle: true,
      boxWidth: 2,

      generateLabels(chart) {
        const { datasets } = chart.data;

        if (!Array.isArray(datasets)) {
          return [];
        }

        // Copied from chart.js source code to change lineDash
        return datasets.map(
          (dataset, i) =>
            ({
              text: dataset.label,
              fillStyle: !Array.isArray(dataset.backgroundColor)
                ? dataset.backgroundColor
                : dataset.backgroundColor[0],
              lineCap: dataset.borderCapStyle,
              lineDash: [],
              lineDashOffset: dataset.borderDashOffset,
              lineJoin: dataset.borderJoinStyle,
              lineWidth: dataset.borderWidth,
              strokeStyle: dataset.borderColor,
              pointStyle: dataset.pointStyle,
              datasetIndex: i,
            } as ChartLegendLabelItem),
        );
      },
    },
  },
  tooltips: {
    callbacks: {
      title: () => null,
      label(tooltipItem, data) {
        const name =
          data.datasets[tooltipItem.datasetIndex].label || 'Selected';
        const value = round(tooltipItem.yLabel as number).toLocaleString();
        return `${name}: ${value}`;
      },
      labelColor(tooltipItem, chart) {
        const dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
        return {
          backgroundColor: dataset.borderColor as string,
          borderColor: 'transparent',
        };
      },
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          drawOnChartArea: false,
          tickMarkLength: 5,
        },
        ticks: {
          beginAtZero: true,
          padding: 3,
          suggestedMin: 0,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
          tickMarkLength: 5,
        },
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 6,
          padding: 5,
          suggestedMin: 0,
        },
      },
    ],
  },
};

const DARK_AXIS = {
  gridLines: {
    color: 'rgba(255, 255, 255, 0.2)',
    zeroLineColor: 'rgba(255, 255, 255, 0.2)',
  },
};

const LIGHT_AXIS = {
  gridLines: {
    color: 'rgba(0, 0, 0, 0.2)',
    zeroLineColor: 'rgba(0, 0, 0, 0.2)',
  },
};

const config: Themable<ChartOptions> = {
  dark: {
    scales: {
      xAxes: [DARK_AXIS],
      yAxes: [DARK_AXIS],
    },
    legend: {
      labels: { fontColor: 'rgba(255,255,255,0.7)', fontSize: 15 },
    },
  },
  light: {
    scales: {
      xAxes: [LIGHT_AXIS],
      yAxes: [LIGHT_AXIS],
    },
  },
};

export function getChartConfig(
  theme: Theme,
  yMax: number,
  fontSize = 12,
  formatter: LocaleFormatter = null,
  co2Cost: boolean,
  extra: object,
) {
  return merge.all(
    [
      DEFAULT_CHART_CONFIG,
      config[theme],
      {
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMax: yMax,
                fontSize,
                callback: value => {
                  if (formatter && !co2Cost) {
                    return formatter.formatter(value);
                  }

                  if (formatter && co2Cost) {
                    return formatter.formatter(value, true);
                  }
                  return value;
                },
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontSize,
                autoSkip: false,
                callback: x => x,
              },
            },
          ],
        },
        legend: { labels: { fontSize } },
      } as ChartOptions,
      extra,
    ],
    { arrayMerge },
  );
}

function arrayMerge<T>(dest: T[], source: T[], options): T[] {
  const result = [];
  const len = Math.max(dest.length, source.length);

  for (let i = 0; i < len; i++) {
    result[i] = merge(dest[i], source[i], options);
  }

  return result;
}

interface Themable<T> {
  dark: T;
  light: T;
}

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss'],
})
export class ParametersComponent implements OnInit {
  @ViewChild('editLabourRateDialog') editLabourRateDialog: TemplateRef<
    MatDialog
  >;
  parametersData: MatTableDataSource<any>;
  displayedColumns: string[] = ['name', 'value', 'actions'];

  editLabourRateControl = new FormControl('', Validators.required);

  constructor(
    private readonly api: ApiService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getLaboutRate();
  }
  getLaboutRate() {
    this.api.clearCache();
    this.api.getLabourRate().then(value => {
      const data = [
        { name: 'Standard labour rate', value, type: 'labourRate' },
      ];
      this.parametersData = new MatTableDataSource(data);
    });
  }

  editParameter(parameter: IParameter) {
    if (parameter.type === 'labourRate') {
      return this.editLabourRate(parameter);
    }
  }

  editLabourRate(rate: IParameter) {
    this.editLabourRateControl.setValue(rate.value);
    const ref = this.dialog.open(this.editLabourRateDialog, {
      data: { ...rate },
    });

    ref.afterClosed().subscribe(value => {
      if (value) {
        this.api
          .setLabourRate(this.editLabourRateControl.value)
          .then(x => this.getLaboutRate());
      }
    });
  }
}

interface IParameter {
  name: string;
  value: any;
  type: string;
}

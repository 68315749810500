import { Location } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getState } from 'src/app/helpers/functions';
import { Area } from 'src/app/models/Area';
import { AreaSurface } from 'src/app/models/AreaSurface';
import { IAddEditSurface } from 'src/app/models/flow/flowInterfaces';
import { Project } from 'src/app/models/Project';
import { StoreService } from 'src/app/services/store-service.service';
import { sendAnalyticsEvent } from 'src/app/utils/analytics';

@Component({
  selector: 'app-add-edit-surface',
  templateUrl: './add-edit-surface.component.html',
  styleUrls: ['./add-edit-surface.component.scss'],
})
export class AddEditSurfaceComponent implements OnInit, AfterViewChecked {
  project: Project;
  area: Area;
  surface: AreaSurface;
  routerState: IAddEditSurface;
  isInEditMode: boolean;

  isSurfaceValid: boolean;
  areaIndex: number;
  surfaceIndex: number;

  constructor(
    private readonly router: Router,
    private readonly store: StoreService,
    private readonly translate: TranslateService,
    private readonly location: Location,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    this.project = store.project;
    this.routerState = getState(router) as IAddEditSurface;

    if (!this.routerState) {
      if (!this.project) {
        this.router.navigate(['/']);
        return;
      }
      this.router.navigate(['project/overview']);
      return;
    }
  }

  get surfacePosition() {
    return `${this.areaIndex + 1}.${this.surfaceIndex + 1}`;
  }

  ngOnInit() {
    const area = this.store.area;
    const { surfaceIndex, isNew } = this.routerState;
    this.area = this.project.areas.find(x => x.name === area.name);
    this.isInEditMode = !isNew;

    this.surfaceIndex = isNew ? this.area.surfaces.length : surfaceIndex;
    this.areaIndex = getIndex(
      this.project.areas,
      x => x.name === this.area.name,
    );

    sendAnalyticsEvent(
      `${this.isInEditMode ? 'Edit' : 'Add'} Surface accessed`,
      `Surface: ${
        this.area.surfaces[surfaceIndex]
          ? this.area.surfaces[surfaceIndex].model.name
          : 'New Surface'
      };
      Area: ${area.subsector.name}
      `,
    );

    if (!this.isInEditMode) {
      this.surface = new AreaSurface();
      return;
    }

    const model = this.area.surfaces[surfaceIndex];
    this.surface = model.clone();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  cancel() {
    this.location.back();
  }

  save() {
    if (!this.surface.name) {
      this.surface.name = AreaSurface.getDefaultName(
        this.translate,
        this.surfaceIndex,
      );
    }

    if (this.routerState.isNew) {
      this.area.surfaces.push(this.surface);
      sendAnalyticsEvent(
        'Surface Added from Overview',
        `Surface: ${this.surface.model.name}; Area: ${this.area.subsector.name}`,
      );
    } else {
      this.area.surfaces[this.routerState.surfaceIndex] = this.surface;
      sendAnalyticsEvent(
        'Surface Edited from Overview',
        `Surface: ${this.surface.model.name}; Area: ${this.area.subsector.name}`,
      );
    }

    this.store.project = this.project;
    this.location.back();
  }

  checkIfValid(valid) {
    this.isSurfaceValid = valid;

    if (valid && !this.area.surfaces.length) {
      this.area.surfaces.push(new AreaSurface());
    }
  }
}

function getIndex<T>(list: T[], predicate: (value: T) => boolean) {
  const index = list.findIndex(predicate);
  return index === -1 ? list.length : index;
}
